import Block from 'components/dumb/Block'
import Tab from 'components/dumb/StakingPageTerminalTab'
import { useStore } from 'effector-react'
import { $staking, $stakingNetworkIsOk } from 'models/staking'
import React, { useState } from 'react'
import {
  getBlockchainNameByChainId,
  getChainId,
  getIcon,
  getName,
  getShortName,
} from 'utils/blockchain'
import t from 'utils/t'
import ClaimTerminal from './ClaimTerminal'
import StakeTerminal from './StakeTerminal'
import UnstakeTerminal from './UnstakeTerminal'
import {
  $selectedChainId,
  $wallet,
  openWallet,
  setUserSelectedChainId,
  switchNetworkFx,
} from 'models/wallet'
import { ReactComponent as ShareLinkIcon } from 'resources/images/icons/external-link.svg'
import { getAddressExplorerUrl, openUrl } from 'utils'
import StakingTerminalSkeleton from 'components/dumb/skeleton/StakingTerminalSkeleton'
import Dropdown from 'components/dumb/Dropdown'
import { $session } from 'models/sessions'
import TerminalWrongNetwork from 'components/collections/TerminalWrongNetwork'
import { ProviderId } from 'models/wallet/wallets'

type TabButtonType = 'stake' | 'claim' | 'unstake'

export default function SmartStakingTerminal() {
  const [currentTab, setCurrentTab] = useState<TabButtonType>('stake')
  const staking = useStore($staking)
  const connected = useStore($session.map(Boolean))
  const wallet = useStore($wallet)
  const pending = staking.pending
  const selectedChainId = useStore($selectedChainId)
  const blockchain = getBlockchainNameByChainId(selectedChainId)
  const stakingNetworkIsOk = useStore($stakingNetworkIsOk)

  const handleTabClick = (tab: TabButtonType) => {
    setCurrentTab(tab)
  }

  const content = React.useMemo(() => {
    switch (currentTab) {
      case 'stake':
        return <StakeTerminal />
      case 'unstake':
        return <UnstakeTerminal />
      case 'claim':
        return <ClaimTerminal />
    }
  }, [currentTab])

  if (
    connected &&
    !stakingNetworkIsOk &&
    wallet?.id !== ProviderId.WalletConnect
  ) {
    return (
      <TerminalWrongNetwork
        blockchain={'ethereum'}
        terminalTitle={t('staking_terminal')}
        heading={`Staking is available on ${getName('ethereum')} and ${getName(
          'bsc'
        )} only.`}
        wrapperClassName="dxs:-mt-[35px]"
        headingClassName="mb-0"
      />
    )
  }

  const handleLinkClick = () => {
    const url = getAddressExplorerUrl(staking.address, selectedChainId)
    openUrl(url)
  }

  const onNetworkClick = (name: 'ethereum' | 'bsc') => {
    const chainId = getChainId(name)
    if (connected) {
      switchNetworkFx(chainId)
    } else {
      setUserSelectedChainId(chainId)
      openWallet()
    }
  }

  const networks = [
    {
      text: getShortName('ethereum'),
      onClick: () => {
        onNetworkClick('ethereum')
      },
    },
    {
      text: getShortName('bsc'),
      onClick: () => {
        onNetworkClick('bsc')
      },
    },
  ]
  const icon = getIcon(blockchain)
  const blockchainLabel = getShortName(blockchain)

  return (
    <Block
      wrapperClassName="dxs:-mt-[35px] "
      containerClassName="!px-5 h-full "
    >
      {pending ? (
        <div className="relative h-full w-full">
          <StakingTerminalSkeleton />
        </div>
      ) : null}
      {!pending && (
        <div className="flex h-full grow flex-col justify-between">
          <div>
            <div className="mb-2 flex flex-col justify-between ml:flex-row">
              <div className="flex items-center space-x-1">
                <h2 className="mb-2 text-2xl font-light leading-[1.3] text-codGray ml:mb-0">
                  {t('staking_terminal')}
                </h2>
                {staking.address ? (
                  <div
                    className="relative cursor-pointer"
                    onClick={handleLinkClick}
                  >
                    <ShareLinkIcon className="relative  -top-2 h-3 w-3 fill-current text-gray/35 hover:text-gray/80" />
                  </div>
                ) : null}
              </div>
              <div className="flex items-center space-x-2">
                <Tab
                  onClick={() => handleTabClick('stake')}
                  current={currentTab === 'stake'}
                >
                  {t('stake')}
                </Tab>
                <Tab
                  onClick={() => handleTabClick('unstake')}
                  current={currentTab === 'unstake'}
                >
                  {t('unstake')}
                </Tab>
                <Tab
                  onClick={() => handleTabClick('claim')}
                  current={currentTab === 'claim'}
                >
                  {t('claim')}
                </Tab>
                <Dropdown
                  items={networks}
                  baseClassName="capitalize text-[12px] py-[4px]"
                >
                  <div className="hidden mm:block dxs:hidden dl:block">
                    {blockchainLabel}
                  </div>
                  <div className="block h-5 w-5 mm:hidden dxs:block dl:hidden">
                    {icon}
                  </div>
                </Dropdown>
              </div>
            </div>
            {currentTab !== 'claim' ? (
              <div className="w-[80%] text-base font-light leading-[150%] text-gray dxs:mb-[2.2rem]">
                {t('staking_terminal_text', {
                  stakingSymbol: staking.stakingSymbol,
                  rewardSymbol: staking.rewardSymbol,
                  unstakingFeeRatio: staking.unstakingFeeRatio,
                })}
              </div>
            ) : null}
          </div>
          {content}
        </div>
      )}
    </Block>
  )
}
