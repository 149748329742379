import classNames from 'classnames'
import { ReactNode } from 'react'
import { noop } from 'utils'

interface Props {
  className?: string
  children?: ReactNode
  onClick?(e: React.MouseEvent): void
  disabled?: boolean
  type?: 'primary' | 'secondary' | 'tertiary' | 'switch' | 'gradientBorder'
}

export default function Button({
  children,
  className,
  onClick = noop,
  type = 'primary',
  disabled = false,
}: Props) {
  const base =
    'py-2 px-4 whitespace-nowrap text-base leading-[150%] rounded-[5px] font-light'

  const primary =
    'border border-transparent uppercase text-porcelain contour-shadow bg-primary hover:bg-primaryHover active:bg-primaryActive disabled:bg-athensGray2 disabled:text-boulder disabled:border-iron'

  //@TODO:
  const secondary =
    'border uppercase border-geyser/40 text-brightGray bg-athensGray2 hover:border-opacity-40 active:bg-blackSecondary active:bg-opacity-40'

  const tertiary =
    'border text-brightGray border-secondary text-brightGray bg-porcelain2 hover:bg-porcelain active:bg-quillGray'

  const gradientBorder =
    'button-gradient-border text-brightGray !leading-[140%] t:px-[80px] !px-[60px]'

  return (
    <button
      className={classNames(base, className, {
        [primary]: type === 'primary',
        [secondary]: type === 'secondary',
        [tertiary]: type === 'tertiary',
        [gradientBorder]: type === 'gradientBorder',
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}
