export default function UpcomingSkeleton() {
  return (
    <div className="blockShadow rounded-[10px] border border-white  bg-athensGray2">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 300 407"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="block ml:hidden"
        preserveAspectRatio="none"
      >
        <path
          d="M0 10C0 4.477 4.477 0 10 0h280c5.523 0 10 4.477 10 10v200H0V10Z"
          fill="#DDE4EE"
        />
        <rect x="105" y="174" width="185" height="26" rx="6" fill="#ECF1F8" />
        <rect x="15" y="227" width="127" height="22" rx="6" fill="#DDE4EE" />
        <rect x="15" y="266" width="258" height="14" rx="3" fill="#DDE4EE" />
        <rect x="15" y="289" width="71" height="14" rx="3" fill="#DDE4EE" />
        <rect x="15" y="320" width="47" height="14" rx="3" fill="#DDE4EE" />
        <rect x="75" y="320" width="47" height="14" rx="3" fill="#DDE4EE" />
        <rect x="135" y="320" width="116" height="14" rx="3" fill="#DDE4EE" />
        <rect x="15" y="362" width="30" height="30" rx="5" fill="#DDE4EE" />
        <rect x="57" y="362" width="30" height="30" rx="5" fill="#DDE4EE" />
        <rect x="99" y="362" width="30" height="30" rx="5" fill="#DDE4EE" />
        <rect x="141" y="362" width="30" height="30" rx="5" fill="#DDE4EE" />
        <rect x="183" y="362" width="30" height="30" rx="5" fill="#DDE4EE" />
        <rect x="225" y="362" width="30" height="30" rx="5" fill="#DDE4EE" />
      </svg>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 583 513"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        className="hidden ml:block"
      >
        <path
          d="M0 10C0 4.477 4.477 0 10 0h563c5.523 0 10 4.477 10 10v320H0V10Z"
          fill="#DDE4EE"
        />
        <rect x="378" y="284" width="185" height="26" rx="6" fill="#ECF1F8" />
        <rect x="25" y="350" width="127" height="24" rx="6" fill="#DDE4EE" />
        <rect x="25" y="391" width="329" height="14" rx="3" fill="#DDE4EE" />
        <rect x="25" y="421" width="47" height="14" rx="3" fill="#DDE4EE" />
        <rect x="86" y="421" width="47" height="14" rx="3" fill="#DDE4EE" />
        <rect x="147" y="421" width="114" height="14" rx="3" fill="#DDE4EE" />
        <rect x="25" y="463" width="30" height="30" rx="5" fill="#DDE4EE" />
        <rect x="67" y="463" width="30" height="30" rx="5" fill="#DDE4EE" />
        <rect x="109" y="463" width="30" height="30" rx="5" fill="#DDE4EE" />
        <rect x="151" y="463" width="30" height="30" rx="5" fill="#DDE4EE" />
        <rect x="193" y="463" width="30" height="30" rx="5" fill="#DDE4EE" />
        <rect x="235" y="463" width="30" height="30" rx="5" fill="#DDE4EE" />
      </svg>
    </div>
  )
}
