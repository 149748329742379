import { noop } from 'utils'

function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

interface Props {
  value: string
  placeholder?: string
  onChange?(v: string): void
  className?: string
  disabled?: boolean
  decimals?: number
}

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`)
const inputRegexWithDecimals = (d: number) =>
  RegExp(`^\\d*(?:\\\\[.])?\\d{0,${d}}$`)

export default function TextInput({
  value,
  placeholder,
  decimals,
  className = '',
  disabled = false,
  onChange = noop,
}: Props) {
  const reg =
    typeof decimals === 'number' ? inputRegexWithDecimals(decimals) : inputRegex

  const enforcer = (nextUserInput: string) => {
    if (nextUserInput === '' || reg.test(escapeRegExp(nextUserInput))) {
      onChange(nextUserInput)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    enforcer(event.target.value.replace(/,/g, '.'))
  }

  return (
    <input
      className={className}
      type="text"
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      inputMode="decimal"
      placeholder={placeholder || ''}
      onChange={handleChange}
      value={value}
      pattern="^[0-9]*[.,]?[0-9]*$"
      minLength={1}
      maxLength={79}
      spellCheck="false"
      disabled={disabled}
    />
  )
}
