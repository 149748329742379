import classNames from 'classnames'
import { noop, tw } from 'utils'

interface TabProps {
  children?: React.ReactNode
  current?: boolean
  disabled?: boolean
  onClick?(): void
}

export default function StakingPageTerminalTab({
  children,
  onClick = noop,
  current = false,
  disabled = false,
}: TabProps) {
  const base = `
border border-transparent leading-[150%] outline-none px-[12px] py-[4px] font-light font-pressStart2P text-[12px] text-gray
rounded-[4px]
hover:!border-transparent hover:bg-primary/10 hover:text-brightGray
disabled:text-gray/40 disabled:border-none disabled:bg-transparent
`
  const active = tw`!border-iron text-brightGray bg-primary/5`

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={classNames(base, {
        [active]: current,
      })}
    >
      {children}
    </button>
  )
}
