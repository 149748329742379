import ModalHeader from 'components/dumb/ModalHeader'
import { closeWallet, connectProvider } from 'models/wallet'
import { wallets } from 'models/wallet/wallets'
import t from 'utils/t'

export default function WalletList() {
  return (
    <div>
      <ModalHeader label="Wallet" onRightClick={closeWallet} />
      <div className="w-full">
        <div className="space-y-[0.5rem]">
          {wallets.map(({ id, name, icon }) => (
            <div
              className="flex flex-1 rounded-md border border-quillGray hover:bg-geyser/40"
              key={name}
            >
              <button
                className="flex flex-1 items-center justify-between p-3"
                onClick={() => connectProvider(id)}
              >
                <p className="text-sm font-medium text-codGray">{name}</p>
                <div className="relative h-8 w-8 border border-quillGray">
                  <img
                    className="absolute left-1/2 top-1/2 h-5 w-5 -translate-x-1/2 -translate-y-1/2"
                    src={icon}
                    alt={name}
                  />
                </div>
              </button>
            </div>
          ))}
        </div>
        <p className="pt-4 text-sm text-friarGray">
          {t('terms_of_use_text', {
            termsOfUse: (
              <a
                className="underline hover:no-underline"
                href="https://drive.google.com/file/d/1DQNG_h2wxeW0G_kZJaXr5kAj6HPahRkf/view"
                target="__blank"
              >
                {t('terms_of_use')}
              </a>
            ),
          })}
        </p>
      </div>
    </div>
  )
}
