import { KycInfoQuery, KycRejectType, KycStatus } from 'gql'

type KycInfo = KycInfoQuery['kycInfo'] | null

export class Kyc {
  status: ReturnType<typeof getStatus>
  lastSyncTime?: string
  generalLink: string
  supportLink: string

  constructor(info: KycInfo) {
    this.status = getStatus(info)
    this.lastSyncTime = info?.account?.lastSyncTime ?? undefined
    this.generalLink = info?.generalLink ?? ''
    this.supportLink = info?.supportLink ?? ''
  }
}

function getStatus(info?: KycInfo) {
  switch (info?.account?.status) {
    case KycStatus.Approved:
      return 'verified'
    case KycStatus.Rejected:
      switch (info?.account?.rejectType) {
        case KycRejectType.Final:
          return 'rejectedFinal'
        case KycRejectType.Retry:
        default:
          return 'rejectedRetry'
      }
    case KycStatus.Empty:
    default:
      return 'notVerified'
  }
}
