import { sample } from 'effector'
import { claimCedNew, claimCedOld } from 'models/rewards'
import { addLMFx, approveLMFx, getRewardLMFx, removeLMFx } from 'models/lm'
import { buyFx, claimFx, refundFx } from 'models/pools'
import {
  addStakeFx,
  approveStakeFx,
  fetchStakingTokenWalletBalanceFx,
  getRewardFx,
  removeStakeFx,
} from 'models/staking'
import { fetchTargetTokenBalanceFx } from 'models/wallet'
import { $showTransaction, $tx, closeTransaction, emitTxToastFx, Tx } from '.'

sample({
  clock: closeTransaction,
  fn: () => false,
  target: $showTransaction,
})

const txFxs = [
  addStakeFx,
  removeStakeFx,
  getRewardFx,
  approveStakeFx,
  buyFx,
  claimFx,
  refundFx,
  addLMFx,
  removeLMFx,
  getRewardLMFx,
  approveLMFx,
  claimCedOld,
  claimCedNew,
]

sample({
  clock: txFxs,
  fn: () => true,
  target: $showTransaction,
})

// Пытаемся отправить транзакцию через кошелёк
sample({
  clock: txFxs,
  fn: (): Tx => ({ hash: '', status: 'pending' }),
  target: $tx,
})

txFxs.forEach((fx) => {
  // Когда транзакция успешно отправлена, сохраняем хеш
  sample({
    clock: fx.doneData,
    fn: (hash): Tx => ({ hash, status: 'submitted' }),
    target: [$tx, fetchStakingTokenWalletBalanceFx, fetchTargetTokenBalanceFx],
  })

  // Иначе отправка провалилась
  sample({
    clock: fx.failData,
    source: $tx,
    fn: (tx): Tx => ({ ...tx, status: 'failed' }),
    target: $tx,
  })

  sample({
    clock: fx.doneData,
    fn: (hash): Tx => ({ hash, status: 'submitted' }),
    target: emitTxToastFx,
  })
})
