import TerminalWrongNetwork from 'components/collections/TerminalWrongNetwork'
import Block from 'components/dumb/Block'
import Button from 'components/dumb/Button'
import ButtonAddToWallet from 'components/dumb/ButtonAddToWallet'
import Field from 'components/dumb/Field'
import ChakraDescSkeleton from 'components/dumb/skeleton/ChakraDescSkeleton'
import ChakraTerminalSkeleton from 'components/dumb/skeleton/ChakraTerminalSkeleton'
import TextLink from 'components/dumb/TextLink'
import { useStore } from 'effector-react'
import {
  $chakraAPY,
  $chakraInfo,
  $chakraNetworkIsOK,
  claimPendingChakraFx,
} from 'models/rewards'
import { $session } from 'models/sessions'
import { openWallet } from 'models/wallet'
import { ReactComponent as ArrowsIcon } from 'resources/images/chakra/arrows.svg'
import { STAKING_GUIDE } from 'utils/links'
import { formatNumeral, printNumberThresholds } from 'utils/numbers'
import t from 'utils/t'

export default function SmartChakraTerminal({ pending }: { pending: boolean }) {
  const chakraInfo = useStore($chakraInfo)
  const connected = useStore($session.map(Boolean))

  const chakraSymbol = chakraInfo.chakraSymbol
  const { chakraApy, chakraApyTooltip, balance, perYear } = useStore($chakraAPY)
  const chakraTotal = formatNumeral(chakraInfo?.chakraEarned)
  const chakraAvail = printNumberThresholds(chakraInfo?.chakraAvailable, 5)
  const chakraPending = formatNumeral(chakraInfo?.chakraPending)

  const networkIsOk = useStore($chakraNetworkIsOK)
  const zeroTerminal = !connected || !chakraInfo?.hasTier

  const tooltip = (
    <div className="text-sm">
      <div className="mb-[10px]">
        ${chakraSymbol} returns are based on your ${chakraInfo?.stakingSymbol}{' '}
        stake
      </div>
      <div className="flex space-x-2">
        <div className="flex flex-col">
          <div className="whitespace-nowrap text-sm text-white/50">
            {zeroTerminal ? 'Tier V Stake' : 'Your Total Stake'}
          </div>
          <div className="whitespace-nowrap text-lg text-white">{balance}</div>
        </div>
        <div className="text-sm text-white/50">=</div>
        <div className="flex flex-col">
          <div className="whitespace-nowrap text-sm text-white/50">
            ${chakraSymbol} per year
          </div>
          <div className="self-center text-lg text-white">{perYear}</div>
        </div>
        <div className="text-sm text-white/50">=</div>
        <div className="flex flex-col">
          <div className="self-center whitespace-nowrap text-sm text-white/50">
            APY
          </div>
          <div className="self-center text-lg text-white">
            {chakraApyTooltip}
          </div>
        </div>
      </div>
      <div className="mt-2 opacity-50">
        Review{' '}
        <TextLink
          href={STAKING_GUIDE}
          className="text-white hover:text-white/70 hover:decoration-white/70"
        >
          Staking guide
        </TextLink>{' '}
        for more details
      </div>
    </div>
  )
  const buttonHandler = connected
    ? () => claimPendingChakraFx()
    : () => openWallet()
  const buttonText = connected ? 'Claim' : 'Connect wallet'

  return (
    <div className="relative z-20 grid grid-cols-1 grid-rows-[272px,334px] gap-10 ml:grid-rows-[168px,254px] t:grid-rows-[167px,250px] dxs:grid-cols-2 dxs:grid-rows-[200px] dl:grid-rows-[215px]">
      <div className="relative">
        <div className="absolute z-0 h-full w-full rounded-block bg-mandalaChakra bg-no-repeat" />
        <div className="grid grid-cols-1 gap-3 ml:grid-cols-2">
          <Field
            title={`$${chakraSymbol} earned`}
            value={chakraTotal}
            pending={pending}
            skeletType="chakra"
          />
          <Field
            title={`$${chakraSymbol} APY`}
            value={chakraApy}
            tooltip={tooltip}
            pending={pending}
            skeletType="chakra"
          />
        </div>
        {pending ? (
          <ChakraDescSkeleton />
        ) : (
          <div className="mt-6 text-base text-friarGray">
            Pending rewards are added to claimable balance every Monday. You can
            let the balance build up or claim at any time. Changing your stake
            now will not affect pending or claimable amounts.
          </div>
        )}
      </div>

      {connected && !networkIsOk ? (
        <TerminalWrongNetwork
          blockchain="bsc"
          terminalTitle="Reward terminal"
          heading="Claiming is available on BNB Smart Chain mainnet only."
          wrapperClassName="dxs:-mt-[35px]"
          hideIcon
        />
      ) : (
        <Block
          wrapperClassName="dxs:-mt-[35px] "
          containerClassName="!px-5 h-full "
        >
          {pending ? (
            <div className="relative h-full w-full">
              <ChakraTerminalSkeleton />
            </div>
          ) : null}
          {!pending ? (
            <>
              <div className="flex h-full grow flex-col">
                <h2 className="mb-2 text-2xl font-light leading-[1.3] text-codGray ml:mb-0">
                  Reward terminal
                </h2>
                <div
                  className="
                    mt-3 flex flex-col items-start space-x-0 space-y-2 rounded-[8px] bg-patternChakra bg-center bg-no-repeat px-3 py-4
                    ml:flex-row ml:items-center ml:space-x-5 ml:space-y-0"
                >
                  {zeroTerminal ? (
                    <Field
                      titleClassName="!text-base"
                      title={`$${chakraSymbol} Earned`}
                      value={0}
                    />
                  ) : (
                    <Field
                      titleClassName="!text-base"
                      title="Pending"
                      value={chakraPending}
                    />
                  )}
                  <div className="flex items-center space-x-5">
                    <ArrowsIcon />
                    {zeroTerminal ? (
                      <Field
                        titleClassName="!text-base"
                        title={t('available_for_claiming')}
                        value={0}
                        forceZero
                      />
                    ) : (
                      <Field
                        titleClassName="!text-base"
                        title={t('available_for_claiming')}
                        value={chakraAvail}
                        symbol={chakraSymbol}
                        forceZero
                      />
                    )}
                  </div>
                </div>

                <div className="mt-7 flex space-x-4">
                  <Button className="h-[41px] w-full" onClick={buttonHandler}>
                    {buttonText}
                  </Button>
                  {chakraInfo?.chakraToken ? (
                    <ButtonAddToWallet
                      tokenAddress={chakraInfo.chakraToken.address}
                      tokenSymbol={chakraInfo.chakraToken.symbol}
                      tokenDecimals={chakraInfo.chakraToken.decimals}
                    />
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
        </Block>
      )}
    </div>
  )
}
