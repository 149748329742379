import classNames from 'classnames'
import Countdown, { zeroPad, CountdownRendererFn } from 'react-countdown'

interface Props {
  textClassName?: string
  countdownClassName?: string
  containerClassName?: string
  smallText?: boolean
  text?: string | null
  endTime?: string | Date | null
}

const renderer: (smallText: boolean) => CountdownRendererFn =
  (smallText: boolean) =>
  ({ days, hours, minutes, seconds }) => {
    const isLessThanOneMin = days === 0 && hours === 0 && minutes < 1
    const _days = days === 0 ? '' : `${zeroPad(days)}:`
    return (
      <>
        {isLessThanOneMin
          ? '< 1 min'
          : `${_days}${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`}
      </>
    )
  }

export default function Timer({
  text,
  endTime,
  smallText = false,
  countdownClassName = '',
  textClassName = '',
  containerClassName = '',
}: Props) {
  return (
    <div className={classNames('flex items-end space-x-3', containerClassName)}>
      {text && (
        <div
          className={classNames(
            'text-lg font-light text-friarGray',
            textClassName
          )}
        >
          {text}
        </div>
      )}
      <div
        className={classNames(
          'font-digital7 text-[30px] leading-none text-primary',
          countdownClassName
        )}
      >
        {endTime ? (
          <Countdown
            zeroPadTime={2}
            date={endTime}
            renderer={renderer(smallText)}
          />
        ) : null}
      </div>
    </div>
  )
}
