import { ReactComponent as EthereumIcon } from 'resources/images/icons/blockchain/ethereum.svg'
import { ReactComponent as EthereumIcon2 } from 'resources/images/icons/blockchain/ethereum2.svg'
import { ReactComponent as MilkomedaIcon } from 'resources/images/icons/blockchain/milkomeda.svg'
import { ReactComponent as MilkomedaIcon2 } from 'resources/images/icons/blockchain/milkomeda2.svg'
import { ReactComponent as BinanceIcon } from 'resources/images/icons/blockchain/binance.svg'
import { ReactComponent as EvmosIcon } from 'resources/images/icons/blockchain/evmos.svg'

import * as constants from './constants'

export const getName = (blockchain: string) => {
  switch (blockchain) {
    case 'ethereum':
      return 'Ethereum mainnet'
    case 'bsc':
      return 'Binance Smart Chain'
    case 'milkomeda':
      return 'Milkomeda C1 mainnet'
    case 'evmos':
      return 'EVMOS'
    default:
      return 'Unknown blockchain'
  }
}

export const getShortName = (blockchain: string) => {
  switch (blockchain) {
    case 'ethereum':
      return 'Ethereum'
    case 'bsc':
      return 'BSC'
    default:
      return 'Unknown'
  }
}

export const getIcon = (blockchain: string) => {
  switch (blockchain) {
    case 'ethereum':
      return <EthereumIcon className="h-full w-full" />
    case 'bsc':
      return <BinanceIcon className="h-full w-full" />
    case 'milkomeda':
      return <MilkomedaIcon className="h-full w-full" />
    case 'evmos':
      return <EvmosIcon className="h-full w-full fill-black" />
    default:
      return null
  }
}

export const getTerminalIcon = (blockchain: string) => {
  switch (blockchain) {
    case 'ethereum':
      return <EthereumIcon2 className="h-full w-20 opacity-80" />
    case 'bsc':
      return (
        <BinanceIcon className="h-full w-[120px] text-cadetBlue opacity-80" />
      )
    case 'milkomeda':
      return <MilkomedaIcon2 className="h-full w-[182px] opacity-60" />
    case 'evmos':
      return (
        <EvmosIcon className="h-full w-[120px] fill-cadetBlue opacity-60" />
      )
    default:
      return null
  }
}

export const getChainId = (blockchain: string) => {
  if (constants.desiredNetwork === 'testnet') {
    switch (blockchain) {
      case 'ethereum':
        return constants.ETHEREUM_TESTNET_CHAIN_ID
      case 'bsc':
        return constants.BSC_TESTNET_CHAIN_ID
      case 'evmos':
        return constants.EVMOS_TESTNET_CHAIN_ID
      default:
        return ''
    }
  }
  switch (blockchain) {
    case 'ethereum':
      return constants.ETHEREUM_MAINNET_CHAIN_ID
    case 'bsc':
      return constants.BSC_MAINNET_CHAIN_ID
    case 'evmos':
      return constants.EVMOS_MAINNET_CHAIN_ID
    default:
      return ''
  }
}

export const getBlockchainNameByChainId = (chainId: string) => {
  switch (chainId) {
    case constants.ETHEREUM_MAINNET_CHAIN_ID:
    case constants.ETHEREUM_TESTNET_CHAIN_ID:
      return 'ethereum'
    case constants.BSC_MAINNET_CHAIN_ID:
    case constants.BSC_TESTNET_CHAIN_ID:
      return 'bsc'
    default:
      return 'ethereum'
  }
}
