import Button from 'components/dumb/Button'
import Modal from 'components/dumb/Modal'
import ModalHeader from 'components/dumb/ModalHeader'
import { useStore } from 'effector-react'
import {
  $registerCampaignModalStatus,
  registerInTelegramFx,
  setRegisterCampaignModalStatus,
} from 'models/pools'
import { ReactNode } from 'react'
import { ReactComponent as SuccessIcon } from 'resources/images/icons/modal-success.svg'
import { ReactComponent as FailedIcon } from 'resources/images/icons/tx-failed.svg'
import { ReactComponent as PendingIcon } from 'resources/images/icons/tx-pending.svg'
import t from 'utils/t'

export default function CampaignRegister() {
  const registerModalStatus = useStore($registerCampaignModalStatus)

  const closeHandler = () => {
    setRegisterCampaignModalStatus('')
  }

  const registerHandler = () => {
    return registerInTelegramFx()
  }

  let content: ReactNode = null
  let icon: ReactNode = null
  let label = ''
  let text = ''
  let button: ReactNode = null

  const closeBtn = (
    <Button className="mt-5 w-full" onClick={closeHandler}>
      Close
    </Button>
  )

  switch (registerModalStatus) {
    case 'open':
      label = t('Registration')
      text = t('register_tg_text')
      button = (
        <Button className="mt-5 w-full" onClick={registerHandler}>
          {t('confirm_tg_registration')}
        </Button>
      )
      break
    case 'pending':
      label = t('waiting_for_registration')
      icon = <PendingIcon className="h-20 w-20 animate-spin" />

      break
    case 'failed':
      label = t('error')
      icon = <FailedIcon className="h-20 w-20" />
      text = t('error_registration_text')
      button = closeBtn
      break
    case 'registered':
      label = t('registration_complete')
      icon = <SuccessIcon className="h-20 w-20" />
      text = t('registration_complete_text')
      button = closeBtn
      break
    default:
      content = ''
  }

  return (
    <Modal show={registerModalStatus !== ''} onClose={closeHandler}>
      <ModalHeader label={label} onRightClick={closeHandler} />
      {icon && <div className="flex justify-center py-5">{icon}</div>}
      {content}
      <p className="font-manrope text-sm font-light text-grayTertiary">
        {text}
      </p>
      {button}
    </Modal>
  )
}
