import classNames from 'classnames'
import { Tooltip } from 'react-tooltip'
import { ReactNode } from 'react'
import { ReactComponent as DollarIcon } from 'resources/images/icons/dollar.svg'
import { ReactComponent as MineIcon } from 'resources/images/icons/mine.svg'
import { ReactComponent as TargetIcon } from 'resources/images/icons/target.svg'
import { ReactComponent as TokensToSellFieldIcon } from 'resources/images/icons/tokens-to-sell-field.svg'
import { ReactComponent as UniqueStackersIcon } from 'resources/images/icons/unique-stackers.svg'
import { ReactComponent as UserParticipatedIcon } from 'resources/images/icons/user-participated.svg'
import { ReactComponent as UserRegisteredIcon } from 'resources/images/icons/user-registered.svg'
import { ReactComponent as UserIcon } from 'resources/images/icons/user.svg'
import { ReactComponent as VestingIcon } from 'resources/images/icons/vesting.svg'
import { useStore } from 'effector-react'
import { $windowWidth } from 'models/app'
import FieldSkeleton, { SkeletType } from './skeleton/FieldSkeleton'

export interface FieldProps {
  title: string | null
  value?: ReactNode
  icon?:
    | 'user'
    | 'mine'
    | 'vesting'
    | 'target'
    | 'staked'
    | 'unique-stackers'
    | 'token-to-sell'
    | 'user-participated'
    | 'user-registred'
    | 'dollar'
    | null
  symbol?: string
  name?: string
  sub?: string | null
  className?: string
  valueClassName?: string
  titleClassName?: string
  symbolClassName?: string
  type?: 'primary' | 'secondary' | 'card'
  tooltip?: React.ReactNode
  forceZero?: boolean
  pending?: boolean
  skeletType?: SkeletType
  onClick?(): void
}

export default function Field({
  icon,
  title,
  value,
  skeletType,
  type = 'primary',
  symbol = undefined,
  sub = undefined,
  className,
  tooltip,
  onClick,
  valueClassName = '',
  symbolClassName = '',
  titleClassName = '',
  forceZero = false,
  pending = false,
}: FieldProps) {
  const withIcon = typeof icon === 'string'
  const primary = type === 'primary' && !withIcon
  const secondary = type === 'secondary' && !withIcon
  const card = type === 'card'
  const hasTooltip = !!tooltip
  const dotted = hasTooltip || Boolean(onClick)

  const windowWidth = useStore($windowWidth)
  const position = windowWidth < 410 ? 'bottom' : 'right'

  const handleClick = () => {
    onClick?.()
  }

  return (
    <div className={classNames(className, 'relative flex items-start')}>
      {pending ? (
        <div className="absolute top-0 h-full w-full">
          <FieldSkeleton skeletType={skeletType} withSub={Boolean(sub)} />
        </div>
      ) : null}
      {pending && withIcon ? (
        <div className="mr-4 flex h-[46px] w-[46px] shrink-0" />
      ) : (
        <FieldIcon icon={icon} />
      )}
      <div
        data-tooltip-id={title ?? ''}
        className={classNames({
          'events-none': pending,
        })}
      >
        <div
          className={classNames(
            'whitespace-nowrap',
            {
              'text-2xl leading-[140%] text-brightGray dxs:text-xl dm:text-2xl':
                primary,
              'text-base leading-[140%] text-boulder': secondary,
              'text-base leading-[150%] text-boulder dm:text-base': withIcon,
              'text-base leading-[150%] text-boulder': card,
            },
            titleClassName
          )}
        >
          {title && !pending ? title : <>&nbsp;</>}
        </div>
        <div
          className={classNames(
            'inline-flex items-baseline space-x-[0.2rem] text-grayLight',
            {
              'dotted-decoration cursor-pointer text-primary':
                dotted && symbol && !pending,
            }
          )}
        >
          <span
            data-tooltip-id={title ?? ''}
            onClick={handleClick}
            className={classNames(valueClassName, {
              'relative text-[2.43rem] leading-[130%] text-primary dxs:text-[1.75rem] dm:text-[1.9rem]':
                primary,
              'relative bottom-1 text-[22px] leading-[150%] text-brightGray':
                secondary,
              'relative -top-[3px] text-[22px] leading-[150%] text-brightGray dm:-top-[3px] dm:text-[22px]':
                withIcon,
              'text-[22px] leading-none text-brightGray': card,
              'dotted-decoration cursor-pointer text-primary':
                dotted && !symbol && !pending,
            })}
          >
            {pending ? (
              <>&nbsp;</>
            ) : value === '0' && !forceZero ? (
              'TBA'
            ) : (
              value
            )}
          </span>
          {symbol && (
            <span
              className={classNames('uppercase', symbolClassName, {
                'text-2xl leading-[130%] text-primary dxs:text-[1.12rem] dm:text-2xl':
                  primary,
                'relative bottom-1 text-sm leading-[150%] text-codGray ds:text-xs dl:text-sm':
                  secondary,
                'relative -top-[3px] text-sm leading-[150%] text-brightGray dm:-top-[3px] dm:text-sm':
                  withIcon,
                'text-[14px] leading-[120%] text-brightGray': card,
              })}
            >
              {pending ? <>&nbsp;</> : symbol}
            </span>
          )}
        </div>
        {sub && (
          <div className="relative -top-[2px] text-base font-light leading-[150%] text-boulder">
            {pending ? <>&nbsp;</> : sub}
          </div>
        )}
      </div>
      {hasTooltip && title ? (
        <Tooltip
          id={title}
          place={position}
          className="blue tooltip-bottom"
          render={() => <div>{tooltip}</div>}
          clickable
        ></Tooltip>
      ) : null}
    </div>
  )
}

function FieldIcon({ icon }: Pick<FieldProps, 'icon'>) {
  const className = 'shrink-0 mr-4 flex h-[46px] w-[46px]'
  switch (icon) {
    case 'target':
      return <TargetIcon className={className} />
    case 'user':
      return <UserIcon className={className} />
    case 'mine':
      return <MineIcon className={className} />
    case 'unique-stackers':
      return <UniqueStackersIcon className={className} />
    case 'vesting':
      return <VestingIcon className={className} />
    case 'token-to-sell':
      return <TokensToSellFieldIcon className={className} />
    case 'user-participated':
      return <UserParticipatedIcon className={className} />
    case 'user-registred':
      return <UserRegisteredIcon className={className} />
    case 'dollar':
      return <DollarIcon className={className} />
    default:
      return null
  }
}
