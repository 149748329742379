import Block from 'components/dumb/Block'
import Button from 'components/dumb/Button'
import { switchNetworkFx } from 'models/wallet'
import { ReactComponent as WarningIconSvg } from 'resources/images/icons/warning.svg'
import { twMerge } from 'tailwind-merge'
import { getChainId, getTerminalIcon } from 'utils/blockchain'
import t from 'utils/t'

interface TerminalWrongNetworkProps {
  blockchain: string
  terminalTitle: string
  heading: string
  hideIcon?: boolean
  wrapperClassName?: string
  headingClassName?: string
}

export default function TerminalWrongNetwork({
  blockchain,
  terminalTitle,
  heading,
  hideIcon = false,
  wrapperClassName = '',
  headingClassName = '',
}: TerminalWrongNetworkProps) {
  const chainId = getChainId(blockchain)
  return (
    <Block
      containerClassName="!px-5 flex flex-col h-full justify-between"
      wrapperClassName={wrapperClassName}
    >
      <div>
        <div className="mb-2 text-2xl font-light leading-8">
          {terminalTitle}
        </div>
        <div className={twMerge('mb-4 text-friarGray', headingClassName)}>
          {heading}
        </div>
      </div>
      {!hideIcon ? (
        <div className="relative flex justify-center">
          {getTerminalIcon(blockchain)}
        </div>
      ) : null}
      <div className={hideIcon ? 'mt-12' : 'mt-4'}>
        <div className="mb-4 flex">
          <WarningIcon />
          <div className="text-sm text-friarGray">
            {t('switch_network_terminal')}
          </div>
        </div>

        <Button
          className="w-full uppercase"
          onClick={() => switchNetworkFx(chainId)}
        >
          {t('switch_network')}
        </Button>
      </div>
    </Block>
  )
}

function WarningIcon() {
  return <WarningIconSvg className="relative top-[0.1rem] mr-2 h-4 w-4" />
}
