import { attach, createEvent, restore } from 'effector'
import { persist } from 'effector-storage/local'
import { KycInfo } from 'gql'
import { graphqlSdk } from 'gql/client'
import { $session } from 'models/sessions'
import { Kyc } from './kyc'

export const setKycInfo = createEvent<KycInfo>()
export const fetchKYCInfo = createEvent()

export const $kycInfo = restore(setKycInfo, null)
export const $kyc = $kycInfo.map((k) => new Kyc(k))

export const fetchKYCInfoFx = attach({
  source: $session,
  async effect(session) {
    return await graphqlSdk.KYCInfo({
      session: Boolean(session) ? session : undefined,
    })
  },
})

export const setResidenceConfirmed = createEvent<boolean>()
export const $residenceConfirmed = restore(setResidenceConfirmed, false)

persist({ store: $residenceConfirmed, key: 'residenceConfirmed' })
