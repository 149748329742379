import { combine, sample } from 'effector'
import { persist } from 'effector-storage/local'
import produce from 'immer'
import { $wallet, connectProvider, logout, setAddress } from 'models/wallet'
import { ProviderId, wallets } from 'models/wallet/wallets'
import { $sessions, isSessionExpiredFx } from '.'

persist({ store: $sessions, key: 'sessions' })

$sessions.reset(logout)

setInterval(isSessionExpiredFx, 1000)

sample({
  source: isSessionExpiredFx.doneData,
  filter: Boolean,
  target: logout,
})

wallets.forEach(({ authFx }) => {
  sample({
    clock: authFx.doneData,
    source: $sessions,
    fn: (sessions, { address, session }) =>
      produce(sessions, (draft) => {
        draft[address] = session
      }),
    target: $sessions,
  })
})

sample({
  clock: setAddress,
  source: combine($wallet, $sessions),
  filter: ([wallet, sessions], address) => !!(wallet && !sessions[address]),
  fn: ([wallet]) => wallet?.id ?? ProviderId.MetaMask,
  target: connectProvider,
})
