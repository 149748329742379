import Block from 'components/dumb/Block'
import Button from 'components/dumb/Button'
import { useStore, useStoreMap } from 'effector-react'
import { $lmsPools } from 'models/lm'
import { $staking } from 'models/staking'
import numeral from 'numeral'
import { useNavigate } from 'react-router-dom'
import { openUrl } from 'utils'
import { LM_GUIDE, STAKING_GUIDE } from 'utils/links'
import { toDecimal } from 'utils/numbers'

import t from 'utils/t'

export default function SmartHomeStats() {
  const staking = useStore($staking)

  const occPoolAddress = process.env.REACT_APP_LM_ADDRESS ?? '0'

  const occPool = useStoreMap({
    store: $lmsPools,
    keys: [occPoolAddress],
    fn: (lmPools, [lmAddress]) =>
      lmPools.find(({ address }) => address === lmAddress),
  })

  const navigate = useNavigate()

  const tier5 = staking?.tiers[0]
  const yourApy = numeral(toDecimal(tier5?.apy).add(staking.apyDec)).format(
    '0.[00]%'
  )
  return (
    <>
      <Block
        bgImage="bg-mandala1"
        containerClassName="flex h-full flex-col justify-between"
      >
        <div>
          <div className="text-[1.5rem] font-light leading-none text-brightGray">
            <span className="font-medium">Tier V</span> staking APY
          </div>

          <div className="mb-[0.3rem] mt-[0.4rem] text-[3rem] leading-none text-primary">
            {yourApy}
          </div>
          <div className="text-sm leading-none">
            <span className="mr-[0.3rem]">{`${staking.rewardSymbol} ${t(
              'left_to_be_mined'
            )}`}</span>
            <span className="font-medium">{staking.leftToBeMined}</span>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <Button
            className="normal-case"
            onClick={() => {
              navigate('/staking')
            }}
          >
            {t('stake')}{' '}
            <span className="font-medium">{staking.stakingSymbol}</span>
          </Button>
          <Button onClick={() => openUrl(STAKING_GUIDE)} type="tertiary">
            {t('staking_guide')}
          </Button>
        </div>
      </Block>
      <Block
        bgImage="bg-mandala3"
        containerClassName="flex h-full flex-col justify-between"
      >
        <div>
          <div className="text-[1.5rem] font-light leading-none text-brightGray">
            <span className="font-medium">{'OCC/ETH'}</span> LM APY
          </div>
          <div className="mt-[0.4rem] text-[4.7rem] font-light leading-none text-primary dxs:text-[5rem]">
            {occPool?.apy}
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <Button
            className="normal-case"
            onClick={() => {
              navigate(`/liquidity-mining/${process.env.REACT_APP_LM_ADDRESS}`)
            }}
          >
            {t('stake')} <span className="font-medium">LTs</span>
          </Button>
          <Button onClick={() => openUrl(LM_GUIDE)} type="tertiary">
            {t('lm_guide')}
          </Button>
        </div>
      </Block>
    </>
  )
}
