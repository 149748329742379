import { GraphQLClient } from 'graphql-request'
import * as Dom from 'graphql-request/dist/types.dom'
import gql from 'graphql-tag'

export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Address: string
  Blockchain: string
  Decimal: string
  Hash: string
  PoolID: string
  Session: string
  Time: string
  Uint: string
}

export type Apy = {
  __typename?: 'APY'
  accountAPY?: Maybe<Scalars['Decimal']>
  apyPerTier: Array<ApyPerTier>
  tokensEarningPerYear?: Maybe<Scalars['Decimal']>
}

export type ApyPerTier = {
  __typename?: 'APYPerTier'
  apy: Scalars['Decimal']
  tokensEarningPerYear: Scalars['Decimal']
}

export enum AccessType {
  Private = 'Private',
  Public = 'Public',
}

export type AccountInfoMultichain = {
  __typename?: 'AccountInfoMultichain'
  account: Scalars['Address']
  apy: Scalars['Decimal']
  stakingBalance: Scalars['Decimal']
  tier?: Maybe<StakingTier>
}

export type AccountRewards = {
  __typename?: 'AccountRewards'
  account: Scalars['Address']
  rewardAvailable: Scalars['Decimal']
  rewardAvailableUSD: Scalars['Decimal']
  rewardClaimed: Scalars['Decimal']
  rewardPending: Scalars['Decimal']
  rewardPendingUSD: Scalars['Decimal']
  rewardTotal: Scalars['Decimal']
  rewardTotalUSD: Scalars['Decimal']
}

export type AccountVesting = {
  __typename?: 'AccountVesting'
  available: Array<Scalars['Decimal']>
  claimed: Array<Scalars['Decimal']>
  totalAvailable: Scalars['Decimal']
  totalClaimed: Scalars['Decimal']
  unlocks: Array<Scalars['Decimal']>
}

export type AddAirdropTargetInput = {
  poolId: Scalars['PoolID']
  session: Scalars['Session']
  target: Scalars['String']
}

export enum AirdropDestination {
  SameBlockchain = 'SameBlockchain',
  SolanaBlockchain = 'SolanaBlockchain',
}

export type Asset = {
  __typename?: 'Asset'
  amount: Scalars['Decimal']
  logoLink: Scalars['String']
  priceUSD: Scalars['Decimal']
  projectName: Scalars['String']
  symbol: Scalars['String']
  valueUSD: Scalars['Decimal']
  websiteLink: Scalars['String']
}

export type AuthInput = {
  authMessage: Scalars['String']
  blockchain: Scalars['Blockchain']
  sign: Scalars['String']
}

export type AuthResult = {
  __typename?: 'AuthResult'
  address: Scalars['Address']
  session: Scalars['Session']
}

export type Balance = {
  __typename?: 'Balance'
  amount: Scalars['Decimal']
  token: Token
}

export enum CedContractType {
  New = 'new',
  Old = 'old',
}

export type CreatePoolClaimTxInput = {
  blockchain: Scalars['Blockchain']
  poolAddress: Scalars['Address']
  session: Scalars['Session']
}

export type CreatePoolRefundTxInput = {
  blockchain: Scalars['Blockchain']
  poolAddress: Scalars['Address']
  session: Scalars['Session']
}

export type CreatePoolReserveTxInput = {
  amount: Scalars['Decimal']
  blockchain: Scalars['Blockchain']
  poolAddress: Scalars['Address']
  session: Scalars['Session']
}

export type CreateStakingApproveTxInput = {
  address: Scalars['Address']
  amount: Scalars['Decimal']
  blockchain: Scalars['Blockchain']
}

export type CreateStakingClaimRewardTxInput = {
  address: Scalars['Address']
  blockchain: Scalars['Blockchain']
}

export type CreateStakingStakeTxInput = {
  address: Scalars['Address']
  amount: Scalars['Decimal']
  blockchain: Scalars['Blockchain']
}

export type CreateStakingUnstakeTxInput = {
  address: Scalars['Address']
  amount: Scalars['Decimal']
  blockchain: Scalars['Blockchain']
  maximumFee: Scalars['Decimal']
}

export type DashboardInfo = {
  __typename?: 'DashboardInfo'
  CirculatingSupplyOfRT: Balance
  PTInitialPrice: Balance
  PTPerDayInCurrentEpoch: Balance
  RTConditionedYield: Balance
  RequiredTTStakedForCalculation: Balance
  TTStaked: Balance
  TotalSupplyOfRT: Balance
}

export type DistributionCed = {
  __typename?: 'DistributionCED'
  amount: Scalars['Decimal']
  img?: Maybe<Scalars['String']>
  tokenAddress: Scalars['Address']
  tokenDecimals: Scalars['Uint']
  tokenName: Scalars['String']
  tokenSymbol: Scalars['String']
}

export type Epoch = {
  __typename?: 'Epoch'
  rewardsPerSecond: Scalars['Decimal']
  startTime: Scalars['Time']
}

export type EthereumTx = Tx & {
  __typename?: 'EthereumTx'
  blockchain: Scalars['Blockchain']
  chainId: Scalars['String']
  data: Scalars['String']
  from: Scalars['Address']
  gas: Scalars['String']
  gasFeeCap: Scalars['String']
  gasPrice: Scalars['String']
  gasTipCap: Scalars['String']
  nonce: Scalars['String']
  to: Scalars['Address']
  value: Scalars['String']
}

export type FixedPricePool = Pool & {
  __typename?: 'FixedPricePool'
  accessType: AccessType
  account?: Maybe<PoolAccount>
  address: Scalars['Address']
  blockchain: Scalars['Blockchain']
  currentTime: Scalars['Time']
  id: Scalars['PoolID']
  idoToken: Token
  meta: PoolMeta
  minOrderSizeTargetToken: Scalars['Decimal']
  pipeline: PoolPipeline
  poolStatus: PoolStatus
  poolType: PoolType
  price?: Maybe<Scalars['Decimal']>
  priceReverse?: Maybe<Scalars['Decimal']>
  progress: PoolProgress
  projectAdmin?: Maybe<Scalars['Address']>
  stakingAddress: Scalars['Address']
  statistics: PoolStatistics
  targetToken?: Maybe<Token>
  tiers: Array<Tier>
  verifiedStatus: VerifiedStatus
  vesting?: Maybe<Vesting>
  waves?: Maybe<PoolWaves>
  whitelistAddress: Scalars['Address']
}

export type GetTelegramCampaignResponse = {
  __typename?: 'GetTelegramCampaignResponse'
  account?: Maybe<Scalars['String']>
  registeredCount: Scalars['Int']
}

export type GetTelegramCampaignResponseAccountArgs = {
  session: Scalars['Session']
}

export type IndexTokenInfo = {
  __typename?: 'IndexTokenInfo'
  assets: Array<Maybe<Asset>>
  indexTokenFairPriceUSD: Scalars['Decimal']
  totalValueUSD: Scalars['Decimal']
}

export type InfoMultichain = {
  __typename?: 'InfoMultichain'
  account?: Maybe<AccountInfoMultichain>
  apy: Scalars['Decimal']
  priceStakingTokenUSD: Scalars['Decimal']
  sessionError?: Maybe<Scalars['String']>
  stakingInfo: Array<StakingInfo>
  tiers: Array<StakingTier>
  totalStake: Scalars['Decimal']
  uniqueStakers: Scalars['Int']
}

export type KycAccountInfo = {
  __typename?: 'KYCAccountInfo'
  lastSyncTime: Scalars['Time']
  rejectType?: Maybe<KycRejectType>
  resubmitLink: Scalars['String']
  status: KycStatus
}

export type KycInfo = {
  __typename?: 'KycInfo'
  account?: Maybe<KycAccountInfo>
  generalLink: Scalars['String']
  supportLink: Scalars['String']
  usInvestorsLink: Scalars['String']
}

export enum KycRejectType {
  Final = 'Final',
  Retry = 'Retry',
}

export enum KycStatus {
  Approved = 'Approved',
  Empty = 'Empty',
  Rejected = 'Rejected',
}

export type LiquidityMiningAccount = {
  __typename?: 'LiquidityMiningAccount'
  accountData: LiquidityMiningAccountData
  address: Scalars['Address']
  blockchain: Scalars['Blockchain']
}

export type LiquidityMiningAccountData = {
  __typename?: 'LiquidityMiningAccountData'
  rewardTokenBalance: Scalars['Decimal']
  rewardsAvailable: Scalars['Decimal']
  rewardsClaimed: Scalars['Decimal']
  rewardsToken: Token
  rewardsTotal: Scalars['Decimal']
  stakingBalance: Scalars['Decimal']
  stakingTokenAllowance: Scalars['Decimal']
  walletBalance: Scalars['Decimal']
}

export type LiquidityMiningCommonData = {
  __typename?: 'LiquidityMiningCommonData'
  address: Scalars['Address']
  apy?: Maybe<Scalars['Decimal']>
  blockchain: Scalars['Blockchain']
  currentEpoch?: Maybe<Epoch>
  liquidityToken: Token
  liquidityTokenPrettyName: Scalars['String']
  name: Scalars['String']
  poolAddress: Scalars['Address']
  poolInfoLink: Scalars['String']
  poolTvlUSD: Scalars['Decimal']
  priceLiquidityTokenUSD: Scalars['Decimal']
  rewardsToken: Token
  totalStakedLT: Scalars['Decimal']
  totalStakedUSD: Scalars['Decimal']
  unstakeFeeRatio?: Maybe<Scalars['Decimal']>
}

export type LiquidityMiningList = {
  __typename?: 'LiquidityMiningList'
  list: Array<LiquidityMiningListElem>
}

export type LiquidityMiningListElem = {
  __typename?: 'LiquidityMiningListElem'
  accountData?: Maybe<LiquidityMiningAccountData>
  commonData: LiquidityMiningCommonData
}

export type Member = {
  __typename?: 'Member'
  avatar: Scalars['String']
  linkedIn: Scalars['String']
  name: Scalars['String']
  position: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  addParticipantAirdropTarget: Scalars['Boolean']
  auth: AuthResult
  createPoolClaimTx: Tx
  createPoolRefundTx: Tx
  createPoolReserveTx: Tx
  createStakingApproveTx: Tx
  createStakingClaimRewardTx: Tx
  createStakingStakeTx: Tx
  createStakingUnstakeTx: Tx
  generateTelegramCampaignCode: Scalars['String']
  getAuthMessage: Scalars['String']
  registerPoolParticipation: PoolParticipationResponse
}

export type MutationAddParticipantAirdropTargetArgs = {
  input: AddAirdropTargetInput
}

export type MutationAuthArgs = {
  input: AuthInput
}

export type MutationCreatePoolClaimTxArgs = {
  input: CreatePoolClaimTxInput
}

export type MutationCreatePoolRefundTxArgs = {
  input: CreatePoolRefundTxInput
}

export type MutationCreatePoolReserveTxArgs = {
  input: CreatePoolReserveTxInput
}

export type MutationCreateStakingApproveTxArgs = {
  input: CreateStakingApproveTxInput
  session: Scalars['Session']
}

export type MutationCreateStakingClaimRewardTxArgs = {
  input: CreateStakingClaimRewardTxInput
  session: Scalars['Session']
}

export type MutationCreateStakingStakeTxArgs = {
  input: CreateStakingStakeTxInput
  session: Scalars['Session']
}

export type MutationCreateStakingUnstakeTxArgs = {
  input: CreateStakingUnstakeTxInput
  session: Scalars['Session']
}

export type MutationGenerateTelegramCampaignCodeArgs = {
  session: Scalars['Session']
}

export type MutationGetAuthMessageArgs = {
  address: Scalars['Address']
  blockchain: Scalars['Blockchain']
}

export type MutationRegisterPoolParticipationArgs = {
  input: RegisterPoolParticipationInput
}

export type Pool = {
  /** @deprecated use meta.private */
  accessType: AccessType
  account?: Maybe<PoolAccount>
  address: Scalars['Address']
  blockchain: Scalars['Blockchain']
  currentTime: Scalars['Time']
  id: Scalars['PoolID']
  idoToken: Token
  meta: PoolMeta
  minOrderSizeTargetToken: Scalars['Decimal']
  pipeline: PoolPipeline
  poolStatus: PoolStatus
  poolType: PoolType
  price?: Maybe<Scalars['Decimal']>
  /** @deprecated Field no longer supported */
  priceReverse?: Maybe<Scalars['Decimal']>
  progress: PoolProgress
  projectAdmin?: Maybe<Scalars['Address']>
  /** @deprecated Field no longer supported */
  stakingAddress: Scalars['Address']
  statistics: PoolStatistics
  targetToken?: Maybe<Token>
  tiers: Array<Tier>
  /** @deprecated use meta.isIncubated */
  verifiedStatus: VerifiedStatus
  vesting?: Maybe<Vesting>
  waves?: Maybe<PoolWaves>
  whitelistAddress: Scalars['Address']
}

export type PoolAccount = {
  __typename?: 'PoolAccount'
  account: Scalars['String']
  airdropTarget?: Maybe<Scalars['String']>
  amountPaid: Scalars['Decimal']
  /** @deprecated use vesting.totalClaimed */
  claimAmount: Scalars['Decimal']
  idoTokenBalance: Scalars['Decimal']
  isRegistered: Scalars['Boolean']
  isWhitelisted?: Maybe<Scalars['Boolean']>
  refundAmount: Scalars['Decimal']
  targetTokenBalance: Scalars['Decimal']
  tier?: Maybe<Tier>
  userToReserve: Scalars['Decimal']
  vesting?: Maybe<AccountVesting>
  /** @deprecated use account.idoTokenBalance instead */
  walletBalance: Scalars['Decimal']
  wave?: Maybe<Scalars['Int']>
  waveStartTime?: Maybe<Scalars['Time']>
  withdrawnAmount: Scalars['Decimal']
}

export type PoolMeta = {
  __typename?: 'PoolMeta'
  ROIAllTimeHigh?: Maybe<Scalars['Decimal']>
  auditReport?: Maybe<Scalars['String']>
  brandBackground?: Maybe<Scalars['String']>
  brandLogo?: Maybe<Scalars['String']>
  coingecko?: Maybe<Scalars['String']>
  coinmarketcap?: Maybe<Scalars['String']>
  constantUSDTokenPrice?: Maybe<Scalars['Decimal']>
  constantUSDTotalTarget?: Maybe<Scalars['Decimal']>
  coolOffExplainedLink: Scalars['String']
  description: Scalars['String']
  discord?: Maybe<Scalars['String']>
  dueDiligenceReport?: Maybe<Scalars['String']>
  /** @deprecated use constantUSDTokenPrice */
  idoTokenPriceUSD?: Maybe<Scalars['Decimal']>
  initialCirculationAmount?: Maybe<Scalars['Decimal']>
  initialMarketCap?: Maybe<Scalars['Decimal']>
  initialMarketCapUSD?: Maybe<Scalars['Decimal']>
  isIncubated?: Maybe<Scalars['Boolean']>
  isKYCRequired: Scalars['Boolean']
  markdown?: Maybe<Scalars['String']>
  medium?: Maybe<Scalars['String']>
  name: Scalars['String']
  participationExplainedLink: Scalars['String']
  position?: Maybe<Scalars['Int']>
  prelaunchStart?: Maybe<Scalars['Time']>
  priceAllTimeHigh?: Maybe<Scalars['Decimal']>
  private: Scalars['Boolean']
  registrationStart?: Maybe<Scalars['Time']>
  roughTokenPriceUSD?: Maybe<Scalars['Decimal']>
  secondaryMarkets: Array<SecondaryMarket>
  sourceCode?: Maybe<Scalars['String']>
  subtitle?: Maybe<Scalars['String']>
  tags: Array<Scalars['String']>
  team: Array<Member>
  telegram?: Maybe<Scalars['String']>
  tiersExplainedLink: Scalars['String']
  twitter?: Maybe<Scalars['String']>
  vestingDescription?: Maybe<Scalars['String']>
  video?: Maybe<Scalars['String']>
  visibility: Scalars['Boolean']
  website?: Maybe<Scalars['String']>
  whitelistingStart?: Maybe<Scalars['Time']>
  whitepaper?: Maybe<Scalars['String']>
}

export type PoolParticipationResponse = {
  __typename?: 'PoolParticipationResponse'
  reason?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type PoolPipeline = {
  __typename?: 'PoolPipeline'
  /** @deprecated use vesting.unlockCheckpoints[0] */
  claimTime?: Maybe<Scalars['Time']>
  finishTime?: Maybe<Scalars['Time']>
  prelaunchTime?: Maybe<Scalars['Time']>
  registrationTime?: Maybe<Scalars['Time']>
  startTime?: Maybe<Scalars['Time']>
  whitelistingTime?: Maybe<Scalars['Time']>
}

export type PoolProgress = {
  __typename?: 'PoolProgress'
  softCap?: Maybe<Scalars['Decimal']>
  softCapPercent?: Maybe<Scalars['Decimal']>
  tokensSoftCap?: Maybe<Scalars['Decimal']>
  tokensSold?: Maybe<Scalars['Decimal']>
  tokensToSell?: Maybe<Scalars['Decimal']>
  totalRaised?: Maybe<Scalars['Decimal']>
  totalTarget?: Maybe<Scalars['Decimal']>
  totalTargetUSD?: Maybe<Scalars['Decimal']>
}

export type PoolStatistics = {
  __typename?: 'PoolStatistics'
  /** @deprecated Field no longer supported */
  marketcapEstimate?: Maybe<Scalars['Decimal']>
  participantsCount: Scalars['Int']
  registeredCount: Scalars['Int']
}

export enum PoolStatus {
  ComingSoon = 'ComingSoon',
  Failed = 'Failed',
  InProgress = 'InProgress',
  Paused = 'Paused',
  Prelaunch = 'Prelaunch',
  Registration = 'Registration',
  SoldOut = 'SoldOut',
  SuccessfullyFinished = 'SuccessfullyFinished',
  Whitelisting = 'Whitelisting',
}

export enum PoolType {
  FixedPricePool = 'FixedPricePool',
}

export type PoolWavePipeline = {
  __typename?: 'PoolWavePipeline'
  number: Scalars['Int']
  start: Scalars['Time']
}

export type PoolWaves = {
  __typename?: 'PoolWaves'
  currentWave?: Maybe<Scalars['Int']>
  pipeline: Array<PoolWavePipeline>
}

export type Query = {
  __typename?: 'Query'
  apy: Apy
  cedClaimAllTokens: TxEth
  cedRewards: Array<Maybe<DistributionCed>>
  claimRewards: TxEth
  dashboard: DashboardInfo
  getLiquidityMiningAccountData: LiquidityMiningAccount
  getLiquidityMiningList: LiquidityMiningList
  getTelegramCampaign: GetTelegramCampaignResponse
  getTransaction: TxState
  indexTokenInfo: IndexTokenInfo
  kycInfo: KycInfo
  pool?: Maybe<Pool>
  pools: Array<Pool>
  stakingInfo: StakingInfo
  stakingInfoCHAKRA: StakingInfoChakra
  stakingInfoMultichain: InfoMultichain
  stakingInfoWithoutSession: StakingInfo
  votingPower: Array<Scalars['Decimal']>
}

export type QueryApyArgs = {
  address?: InputMaybe<Scalars['Address']>
  blockchain?: InputMaybe<Scalars['Blockchain']>
  session?: InputMaybe<Scalars['Session']>
}

export type QueryCedClaimAllTokensArgs = {
  cedContractType: CedContractType
  session: Scalars['Session']
}

export type QueryCedRewardsArgs = {
  cedContractType: CedContractType
  session: Scalars['Session']
}

export type QueryClaimRewardsArgs = {
  session: Scalars['Session']
}

export type QueryGetLiquidityMiningAccountDataArgs = {
  account: Scalars['Address']
  address: Scalars['Address']
  blockchain: Scalars['Blockchain']
}

export type QueryGetLiquidityMiningListArgs = {
  account?: InputMaybe<Scalars['Address']>
}

export type QueryGetTransactionArgs = {
  blockchain: Scalars['Blockchain']
  session: Scalars['Session']
  txHash: Scalars['Hash']
}

export type QueryKycInfoArgs = {
  session?: InputMaybe<Scalars['Session']>
}

export type QueryPoolArgs = {
  id: Scalars['PoolID']
  session?: InputMaybe<Scalars['Session']>
}

export type QueryPoolsArgs = {
  session?: InputMaybe<Scalars['Session']>
}

export type QueryStakingInfoArgs = {
  address?: InputMaybe<Scalars['Address']>
  blockchain?: InputMaybe<Scalars['Blockchain']>
  session?: InputMaybe<Scalars['Session']>
}

export type QueryStakingInfoChakraArgs = {
  session?: InputMaybe<Scalars['Session']>
}

export type QueryStakingInfoMultichainArgs = {
  session?: InputMaybe<Scalars['Session']>
}

export type QueryStakingInfoWithoutSessionArgs = {
  account?: InputMaybe<Scalars['Address']>
  address?: InputMaybe<Scalars['Address']>
  blockchain?: InputMaybe<Scalars['Blockchain']>
}

export type QueryVotingPowerArgs = {
  address?: InputMaybe<Scalars['Address']>
  blockchain?: InputMaybe<Scalars['Blockchain']>
}

export type QueryStaking = {
  __typename?: 'QueryStaking'
  stakingInfo: StakingInfo
  stakingInfoMultichain: InfoMultichain
  stakingInfoWithoutSession: StakingInfo
}

export type QueryStakingStakingInfoArgs = {
  address?: InputMaybe<Scalars['Address']>
  blockchain?: InputMaybe<Scalars['Blockchain']>
  session?: InputMaybe<Scalars['Session']>
}

export type QueryStakingStakingInfoMultichainArgs = {
  session?: InputMaybe<Scalars['Session']>
}

export type QueryStakingStakingInfoWithoutSessionArgs = {
  account?: InputMaybe<Scalars['Address']>
  address?: InputMaybe<Scalars['Address']>
  blockchain?: InputMaybe<Scalars['Blockchain']>
}

export type RegisterPoolParticipationInput = {
  blockchain: Scalars['Blockchain']
  poolAddress: Scalars['Address']
  session: Scalars['Session']
}

export type SecondaryMarket = {
  __typename?: 'SecondaryMarket'
  name: Scalars['String']
  sellStart?: Maybe<Scalars['Time']>
  url: Scalars['String']
}

export type StakeBooster = {
  __typename?: 'StakeBooster'
  boosterUnlockTime: Scalars['Time']
  stakeBoosterBalance: Scalars['Decimal']
  stakeBoosterThreshold: Scalars['Decimal']
}

export type StakingAccount = {
  __typename?: 'StakingAccount'
  account: Scalars['Address']
  allowance: Scalars['Decimal']
  rewardAvailable: Scalars['Decimal']
  rewardClaimed: Scalars['Decimal']
  rewardPending: Scalars['Decimal']
  rewardTotal: Scalars['Decimal']
  rewardTotalUSD: Scalars['Decimal']
  stakeBooster?: Maybe<StakeBooster>
  stakingBalance: Scalars['Decimal']
  tier?: Maybe<StakingTier>
  walletBalance: Scalars['Decimal']
}

export type StakingInfo = {
  __typename?: 'StakingInfo'
  account?: Maybe<StakingAccount>
  address: Scalars['Address']
  /** @deprecated use InfoMultichain.apy instead */
  apy: Scalars['Decimal']
  blockchain: Scalars['Blockchain']
  contract: Scalars['String']
  currentEpoch?: Maybe<Epoch>
  id: Scalars['ID']
  nextEpoch?: Maybe<Epoch>
  priceRewardTokenUSD: Scalars['Decimal']
  priceStakingTokenUSD: Scalars['Decimal']
  rewardToken: Token
  /** @deprecated use InfoMultichain.sessionError instead */
  sessionError?: Maybe<Scalars['String']>
  stakingToken: Token
  /** @deprecated use InfoMultichain.tiers instead */
  tiers: Array<StakingTier>
  totalEmittedRewards: Scalars['Decimal']
  totalRewardToDistribute?: Maybe<Scalars['Decimal']>
  totalStaked: Scalars['Decimal']
  uniqueStakers: Scalars['Int']
  unstakingFeeRatio: Scalars['Decimal']
}

export type StakingInfoChakra = {
  __typename?: 'StakingInfoCHAKRA'
  account?: Maybe<AccountRewards>
  apy: Scalars['Decimal']
  currentEpoch?: Maybe<Epoch>
  nextEpoch?: Maybe<Epoch>
  priceRewardTokenUSD: Scalars['Decimal']
  priceStakingTokenUSD: Scalars['Decimal']
  rewardToken: Token
  sessionError?: Maybe<Scalars['String']>
  stakingToken: Token
  totalEmittedRewards: Scalars['Decimal']
  totalStaked: Scalars['Decimal']
}

export type StakingTier = {
  __typename?: 'StakingTier'
  allocationMultiple: Scalars['Decimal']
  coolOff?: Maybe<Scalars['Int']>
  index: Scalars['Int']
  isSpecial?: Maybe<Scalars['Boolean']>
  level: Scalars['Int']
  minStake: Scalars['Decimal']
  name: Scalars['String']
  participationTickets: Scalars['Decimal']
  stakeDelta?: Maybe<Scalars['Decimal']>
  ticketDelta?: Maybe<Scalars['Decimal']>
}

export type Tier = {
  __typename?: 'Tier'
  allocationMultiple: Scalars['Decimal']
  coolOff: Scalars['Int']
  index: Scalars['Int']
  isSpecial?: Maybe<Scalars['Boolean']>
  level: Scalars['Int']
  maxAllocation?: Maybe<Scalars['Decimal']>
  minStake?: Maybe<Scalars['Decimal']>
  name: Scalars['String']
  /** @deprecated use participationTickets */
  participationChanceFrom: Scalars['Int']
  /** @deprecated use participationTickets */
  participationChanceTo: Scalars['Int']
  participationTickets: Scalars['Decimal']
}

export type Token = {
  __typename?: 'Token'
  address: Scalars['Address']
  blockchain: Scalars['Blockchain']
  decimals: Scalars['Int']
  id: Scalars['ID']
  name: Scalars['String']
  symbol: Scalars['String']
  totalSupply: Scalars['Decimal']
}

export type Tx = {
  blockchain: Scalars['Blockchain']
  from: Scalars['Address']
  to: Scalars['Address']
}

export type TxEth = {
  __typename?: 'TxEth'
  chainId: Scalars['String']
  data: Scalars['String']
  from: Scalars['Address']
  gas: Scalars['String']
  gasPrice: Scalars['String']
  nonce: Scalars['String']
  to: Scalars['Address']
  value: Scalars['String']
}

export type TxEvent = {
  __typename?: 'TxEvent'
  contract: Scalars['String']
  data: Scalars['String']
  name: Scalars['String']
}

export type TxState = {
  __typename?: 'TxState'
  blockHash?: Maybe<Scalars['Hash']>
  blockNumber?: Maybe<Scalars['Int']>
  events?: Maybe<Array<TxEvent>>
  status: TxStatus
  txHash: Scalars['Hash']
}

export enum TxStatus {
  Empty = 'Empty',
  Failed = 'Failed',
  Pending = 'Pending',
  Success = 'Success',
}

export enum VerifiedStatus {
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED',
}

export type Vesting = {
  __typename?: 'Vesting'
  airdropDestination?: Maybe<VestingAirdropDestination>
  currentPeriodIndex?: Maybe<Scalars['Int']>
  unlockCheckpoints: Array<Scalars['Time']>
  unlockPercentages: Array<Scalars['Decimal']>
  unlockPercentagesByCheckpoint: Array<Scalars['Decimal']>
}

export type VestingAirdropDestination = {
  __typename?: 'VestingAirdropDestination'
  name: Scalars['String']
  type: AirdropDestination
}

export type AddParticipantAidrdopTargetMutationVariables = Exact<{
  input: AddAirdropTargetInput
}>

export type AddParticipantAidrdopTargetMutation = {
  __typename?: 'Mutation'
  addParticipantAirdropTarget: boolean
}

export type AuthMutationVariables = Exact<{
  input: AuthInput
}>

export type AuthMutation = {
  __typename?: 'Mutation'
  auth: { __typename?: 'AuthResult'; session: string; address: string }
}

export type GetAuthMessageMutationVariables = Exact<{
  blockchain: Scalars['Blockchain']
  address: Scalars['Address']
}>

export type GetAuthMessageMutation = {
  __typename?: 'Mutation'
  getAuthMessage: string
}

export type RegisterPoolParticipationMutationVariables = Exact<{
  input: RegisterPoolParticipationInput
}>

export type RegisterPoolParticipationMutation = {
  __typename?: 'Mutation'
  registerPoolParticipation: {
    __typename?: 'PoolParticipationResponse'
    reason?: string | null
    success: boolean
  }
}

export type ApyPerTierFieldsFragment = {
  __typename?: 'APY'
  accountAPY?: string | null
  tokensEarningPerYear?: string | null
  apyPerTier: Array<{
    __typename?: 'APYPerTier'
    apy: string
    tokensEarningPerYear: string
  }>
}

export type CedClaimAllTokensQueryVariables = Exact<{
  session: Scalars['Session']
  cedContractType: CedContractType
}>

export type CedClaimAllTokensQuery = {
  __typename?: 'Query'
  cedClaimAllTokens: {
    __typename?: 'TxEth'
    from: string
    to: string
    chainId: string
    gas: string
    gasPrice: string
    data: string
    nonce: string
    value: string
  }
}

export type CedRewardsQueryVariables = Exact<{
  session: Scalars['Session']
  cedContractType: CedContractType
}>

export type CedRewardsQuery = {
  __typename?: 'Query'
  cedRewards: Array<{
    __typename?: 'DistributionCED'
    tokenAddress: string
    tokenName: string
    tokenSymbol: string
    tokenDecimals: string
    img?: string | null
    amount: string
  } | null>
}

export type ChakraInfoQueryVariables = Exact<{
  session?: InputMaybe<Scalars['Session']>
}>

export type ChakraInfoQuery = {
  __typename?: 'Query'
  indexTokenInfo: {
    __typename?: 'IndexTokenInfo'
    indexTokenFairPriceUSD: string
    totalValueUSD: string
    assets: Array<{
      __typename?: 'Asset'
      symbol: string
      projectName: string
      amount: string
      priceUSD: string
      valueUSD: string
      logoLink: string
      websiteLink: string
    } | null>
  }
  stakingInfoCHAKRA: {
    __typename?: 'StakingInfoCHAKRA'
    priceStakingTokenUSD: string
    priceRewardTokenUSD: string
    apy: string
    totalStaked: string
    totalEmittedRewards: string
    stakingToken: { __typename?: 'Token'; symbol: string }
    rewardToken: {
      __typename?: 'Token'
      name: string
      symbol: string
      id: string
      blockchain: string
      address: string
      decimals: number
      totalSupply: string
    }
    account?: {
      __typename?: 'AccountRewards'
      account: string
      rewardAvailable: string
      rewardAvailableUSD: string
      rewardClaimed: string
      rewardTotal: string
      rewardTotalUSD: string
      rewardPending: string
      rewardPendingUSD: string
    } | null
  }
  stakingInfoMultichain: {
    __typename?: 'InfoMultichain'
    account?: {
      __typename?: 'AccountInfoMultichain'
      stakingBalance: string
      tier?: { __typename?: 'StakingTier'; level: number } | null
    } | null
    tiers: Array<{
      __typename?: 'StakingTier'
      index: number
      name: string
      minStake: string
    }>
  }
  apy: {
    __typename?: 'APY'
    accountAPY?: string | null
    tokensEarningPerYear?: string | null
    apyPerTier: Array<{
      __typename?: 'APYPerTier'
      apy: string
      tokensEarningPerYear: string
    }>
  }
}

export type ClaimRewardsQueryVariables = Exact<{
  session: Scalars['Session']
}>

export type ClaimRewardsQuery = {
  __typename?: 'Query'
  claimRewards: {
    __typename?: 'TxEth'
    from: string
    to: string
    chainId: string
    gas: string
    gasPrice: string
    data: string
    nonce: string
    value: string
  }
}

export type GenerateTelegramCampaignCodeMutationVariables = Exact<{
  session: Scalars['Session']
}>

export type GenerateTelegramCampaignCodeMutation = {
  __typename?: 'Mutation'
  generateTelegramCampaignCode: string
}

export type GetLiquidityMiningListQueryVariables = Exact<{
  account?: InputMaybe<Scalars['Address']>
}>

export type GetLiquidityMiningListQuery = {
  __typename?: 'Query'
  getLiquidityMiningList: {
    __typename?: 'LiquidityMiningList'
    list: Array<{
      __typename?: 'LiquidityMiningListElem'
      commonData: {
        __typename?: 'LiquidityMiningCommonData'
        blockchain: string
        address: string
        name: string
        poolAddress: string
        apy?: string | null
        totalStakedUSD: string
        totalStakedLT: string
        priceLiquidityTokenUSD: string
        liquidityTokenPrettyName: string
        poolInfoLink: string
        poolTvlUSD: string
        unstakeFeeRatio?: string | null
        rewardsToken: {
          __typename?: 'Token'
          id: string
          address: string
          totalSupply: string
          name: string
          symbol: string
          decimals: number
        }
        liquidityToken: {
          __typename?: 'Token'
          id: string
          address: string
          totalSupply: string
          name: string
          symbol: string
          decimals: number
        }
        currentEpoch?: {
          __typename?: 'Epoch'
          startTime: string
          rewardsPerSecond: string
        } | null
      }
      accountData?: {
        __typename?: 'LiquidityMiningAccountData'
        walletBalance: string
        stakingBalance: string
        stakingTokenAllowance: string
        rewardsTotal: string
        rewardsClaimed: string
        rewardsAvailable: string
        rewardTokenBalance: string
      } | null
    }>
  }
}

export type GetTelegramCampaignQueryVariables = Exact<{ [key: string]: never }>

export type GetTelegramCampaignQuery = {
  __typename?: 'Query'
  getTelegramCampaign: {
    __typename?: 'GetTelegramCampaignResponse'
    registeredCount: number
  }
}

export type GetTelegramCampaignWithSessionQueryVariables = Exact<{
  session: Scalars['Session']
}>

export type GetTelegramCampaignWithSessionQuery = {
  __typename?: 'Query'
  getTelegramCampaign: {
    __typename?: 'GetTelegramCampaignResponse'
    account?: string | null
    registeredCount: number
  }
}

export type KycInfoQueryVariables = Exact<{
  session?: InputMaybe<Scalars['Session']>
}>

export type KycInfoQuery = {
  __typename?: 'Query'
  kycInfo: {
    __typename?: 'KycInfo'
    generalLink: string
    supportLink: string
    usInvestorsLink: string
    account?: {
      __typename?: 'KYCAccountInfo'
      lastSyncTime: string
      status: KycStatus
      rejectType?: KycRejectType | null
      resubmitLink: string
    } | null
  }
}

export type PoolQueryVariables = Exact<{
  id: Scalars['PoolID']
  session?: InputMaybe<Scalars['Session']>
}>

export type PoolQuery = {
  __typename?: 'Query'
  pool?: {
    __typename?: 'FixedPricePool'
    id: string
    blockchain: string
    address: string
    currentTime: string
    whitelistAddress: string
    projectAdmin?: string | null
    price?: string | null
    poolStatus: PoolStatus
    poolType: PoolType
    minOrderSizeTargetToken: string
    account?: {
      __typename?: 'PoolAccount'
      airdropTarget?: string | null
      account: string
      amountPaid: string
      idoTokenBalance: string
      isRegistered: boolean
      isWhitelisted?: boolean | null
      refundAmount: string
      targetTokenBalance: string
      userToReserve: string
      wave?: number | null
      waveStartTime?: string | null
      withdrawnAmount: string
      tier?: {
        __typename?: 'Tier'
        allocationMultiple: string
        coolOff: number
        index: number
        isSpecial?: boolean | null
        level: number
        maxAllocation?: string | null
        minStake?: string | null
        name: string
        participationTickets: string
      } | null
      vesting?: {
        __typename?: 'AccountVesting'
        available: Array<string>
        claimed: Array<string>
        totalAvailable: string
        totalClaimed: string
        unlocks: Array<string>
      } | null
    } | null
    meta: {
      __typename?: 'PoolMeta'
      ROIAllTimeHigh?: string | null
      auditReport?: string | null
      brandBackground?: string | null
      brandLogo?: string | null
      coingecko?: string | null
      coinmarketcap?: string | null
      constantUSDTotalTarget?: string | null
      description: string
      discord?: string | null
      dueDiligenceReport?: string | null
      initialCirculationAmount?: string | null
      initialMarketCap?: string | null
      initialMarketCapUSD?: string | null
      isIncubated?: boolean | null
      isKYCRequired: boolean
      markdown?: string | null
      medium?: string | null
      name: string
      position?: number | null
      prelaunchStart?: string | null
      priceAllTimeHigh?: string | null
      private: boolean
      registrationStart?: string | null
      sourceCode?: string | null
      subtitle?: string | null
      tags: Array<string>
      telegram?: string | null
      twitter?: string | null
      vestingDescription?: string | null
      video?: string | null
      visibility: boolean
      website?: string | null
      whitelistingStart?: string | null
      whitepaper?: string | null
      coolOffExplainedLink: string
      tiersExplainedLink: string
      roughTokenPriceUSD?: string | null
      participationExplainedLink: string
      constantUSDTokenPrice?: string | null
      secondaryMarkets: Array<{
        __typename?: 'SecondaryMarket'
        name: string
        sellStart?: string | null
        url: string
      }>
      team: Array<{
        __typename?: 'Member'
        avatar: string
        linkedIn: string
        name: string
        position: string
      }>
    }
    idoToken: {
      __typename?: 'Token'
      address: string
      blockchain: string
      decimals: number
      id: string
      name: string
      symbol: string
      totalSupply: string
    }
    waves?: {
      __typename?: 'PoolWaves'
      currentWave?: number | null
      pipeline: Array<{
        __typename?: 'PoolWavePipeline'
        number: number
        start: string
      }>
    } | null
    vesting?: {
      __typename?: 'Vesting'
      currentPeriodIndex?: number | null
      unlockCheckpoints: Array<string>
      unlockPercentages: Array<string>
      unlockPercentagesByCheckpoint: Array<string>
      airdropDestination?: {
        __typename?: 'VestingAirdropDestination'
        name: string
        type: AirdropDestination
      } | null
    } | null
    tiers: Array<{
      __typename?: 'Tier'
      allocationMultiple: string
      coolOff: number
      index: number
      isSpecial?: boolean | null
      level: number
      maxAllocation?: string | null
      minStake?: string | null
      name: string
      participationTickets: string
    }>
    targetToken?: {
      __typename?: 'Token'
      address: string
      blockchain: string
      decimals: number
      id: string
      name: string
      symbol: string
      totalSupply: string
    } | null
    statistics: {
      __typename?: 'PoolStatistics'
      participantsCount: number
      registeredCount: number
    }
    progress: {
      __typename?: 'PoolProgress'
      softCap?: string | null
      softCapPercent?: string | null
      tokensSoftCap?: string | null
      tokensSold?: string | null
      tokensToSell?: string | null
      totalRaised?: string | null
      totalTarget?: string | null
      totalTargetUSD?: string | null
    }
    pipeline: {
      __typename?: 'PoolPipeline'
      finishTime?: string | null
      prelaunchTime?: string | null
      registrationTime?: string | null
      startTime?: string | null
      whitelistingTime?: string | null
    }
  } | null
}

export type PoolFieldsFragment = {
  __typename?: 'FixedPricePool'
  id: string
  blockchain: string
  address: string
  currentTime: string
  whitelistAddress: string
  projectAdmin?: string | null
  price?: string | null
  poolStatus: PoolStatus
  poolType: PoolType
  minOrderSizeTargetToken: string
  account?: {
    __typename?: 'PoolAccount'
    airdropTarget?: string | null
    account: string
    amountPaid: string
    idoTokenBalance: string
    isRegistered: boolean
    isWhitelisted?: boolean | null
    refundAmount: string
    targetTokenBalance: string
    userToReserve: string
    wave?: number | null
    waveStartTime?: string | null
    withdrawnAmount: string
    tier?: {
      __typename?: 'Tier'
      allocationMultiple: string
      coolOff: number
      index: number
      isSpecial?: boolean | null
      level: number
      maxAllocation?: string | null
      minStake?: string | null
      name: string
      participationTickets: string
    } | null
    vesting?: {
      __typename?: 'AccountVesting'
      available: Array<string>
      claimed: Array<string>
      totalAvailable: string
      totalClaimed: string
      unlocks: Array<string>
    } | null
  } | null
  meta: {
    __typename?: 'PoolMeta'
    ROIAllTimeHigh?: string | null
    auditReport?: string | null
    brandBackground?: string | null
    brandLogo?: string | null
    coingecko?: string | null
    coinmarketcap?: string | null
    constantUSDTotalTarget?: string | null
    description: string
    discord?: string | null
    dueDiligenceReport?: string | null
    initialCirculationAmount?: string | null
    initialMarketCap?: string | null
    initialMarketCapUSD?: string | null
    isIncubated?: boolean | null
    isKYCRequired: boolean
    markdown?: string | null
    medium?: string | null
    name: string
    position?: number | null
    prelaunchStart?: string | null
    priceAllTimeHigh?: string | null
    private: boolean
    registrationStart?: string | null
    sourceCode?: string | null
    subtitle?: string | null
    tags: Array<string>
    telegram?: string | null
    twitter?: string | null
    vestingDescription?: string | null
    video?: string | null
    visibility: boolean
    website?: string | null
    whitelistingStart?: string | null
    whitepaper?: string | null
    coolOffExplainedLink: string
    tiersExplainedLink: string
    roughTokenPriceUSD?: string | null
    participationExplainedLink: string
    constantUSDTokenPrice?: string | null
    secondaryMarkets: Array<{
      __typename?: 'SecondaryMarket'
      name: string
      sellStart?: string | null
      url: string
    }>
    team: Array<{
      __typename?: 'Member'
      avatar: string
      linkedIn: string
      name: string
      position: string
    }>
  }
  idoToken: {
    __typename?: 'Token'
    address: string
    blockchain: string
    decimals: number
    id: string
    name: string
    symbol: string
    totalSupply: string
  }
  waves?: {
    __typename?: 'PoolWaves'
    currentWave?: number | null
    pipeline: Array<{
      __typename?: 'PoolWavePipeline'
      number: number
      start: string
    }>
  } | null
  vesting?: {
    __typename?: 'Vesting'
    currentPeriodIndex?: number | null
    unlockCheckpoints: Array<string>
    unlockPercentages: Array<string>
    unlockPercentagesByCheckpoint: Array<string>
    airdropDestination?: {
      __typename?: 'VestingAirdropDestination'
      name: string
      type: AirdropDestination
    } | null
  } | null
  tiers: Array<{
    __typename?: 'Tier'
    allocationMultiple: string
    coolOff: number
    index: number
    isSpecial?: boolean | null
    level: number
    maxAllocation?: string | null
    minStake?: string | null
    name: string
    participationTickets: string
  }>
  targetToken?: {
    __typename?: 'Token'
    address: string
    blockchain: string
    decimals: number
    id: string
    name: string
    symbol: string
    totalSupply: string
  } | null
  statistics: {
    __typename?: 'PoolStatistics'
    participantsCount: number
    registeredCount: number
  }
  progress: {
    __typename?: 'PoolProgress'
    softCap?: string | null
    softCapPercent?: string | null
    tokensSoftCap?: string | null
    tokensSold?: string | null
    tokensToSell?: string | null
    totalRaised?: string | null
    totalTarget?: string | null
    totalTargetUSD?: string | null
  }
  pipeline: {
    __typename?: 'PoolPipeline'
    finishTime?: string | null
    prelaunchTime?: string | null
    registrationTime?: string | null
    startTime?: string | null
    whitelistingTime?: string | null
  }
}

export type PoolsQueryVariables = Exact<{ [key: string]: never }>

export type PoolsQuery = {
  __typename?: 'Query'
  pools: Array<{
    __typename?: 'FixedPricePool'
    id: string
    blockchain: string
    address: string
    currentTime: string
    whitelistAddress: string
    projectAdmin?: string | null
    price?: string | null
    poolStatus: PoolStatus
    poolType: PoolType
    minOrderSizeTargetToken: string
    account?: {
      __typename?: 'PoolAccount'
      airdropTarget?: string | null
      account: string
      amountPaid: string
      idoTokenBalance: string
      isRegistered: boolean
      isWhitelisted?: boolean | null
      refundAmount: string
      targetTokenBalance: string
      userToReserve: string
      wave?: number | null
      waveStartTime?: string | null
      withdrawnAmount: string
      tier?: {
        __typename?: 'Tier'
        allocationMultiple: string
        coolOff: number
        index: number
        isSpecial?: boolean | null
        level: number
        maxAllocation?: string | null
        minStake?: string | null
        name: string
        participationTickets: string
      } | null
      vesting?: {
        __typename?: 'AccountVesting'
        available: Array<string>
        claimed: Array<string>
        totalAvailable: string
        totalClaimed: string
        unlocks: Array<string>
      } | null
    } | null
    meta: {
      __typename?: 'PoolMeta'
      ROIAllTimeHigh?: string | null
      auditReport?: string | null
      brandBackground?: string | null
      brandLogo?: string | null
      coingecko?: string | null
      coinmarketcap?: string | null
      constantUSDTotalTarget?: string | null
      description: string
      discord?: string | null
      dueDiligenceReport?: string | null
      initialCirculationAmount?: string | null
      initialMarketCap?: string | null
      initialMarketCapUSD?: string | null
      isIncubated?: boolean | null
      isKYCRequired: boolean
      markdown?: string | null
      medium?: string | null
      name: string
      position?: number | null
      prelaunchStart?: string | null
      priceAllTimeHigh?: string | null
      private: boolean
      registrationStart?: string | null
      sourceCode?: string | null
      subtitle?: string | null
      tags: Array<string>
      telegram?: string | null
      twitter?: string | null
      vestingDescription?: string | null
      video?: string | null
      visibility: boolean
      website?: string | null
      whitelistingStart?: string | null
      whitepaper?: string | null
      coolOffExplainedLink: string
      tiersExplainedLink: string
      roughTokenPriceUSD?: string | null
      participationExplainedLink: string
      constantUSDTokenPrice?: string | null
      secondaryMarkets: Array<{
        __typename?: 'SecondaryMarket'
        name: string
        sellStart?: string | null
        url: string
      }>
      team: Array<{
        __typename?: 'Member'
        avatar: string
        linkedIn: string
        name: string
        position: string
      }>
    }
    idoToken: {
      __typename?: 'Token'
      address: string
      blockchain: string
      decimals: number
      id: string
      name: string
      symbol: string
      totalSupply: string
    }
    waves?: {
      __typename?: 'PoolWaves'
      currentWave?: number | null
      pipeline: Array<{
        __typename?: 'PoolWavePipeline'
        number: number
        start: string
      }>
    } | null
    vesting?: {
      __typename?: 'Vesting'
      currentPeriodIndex?: number | null
      unlockCheckpoints: Array<string>
      unlockPercentages: Array<string>
      unlockPercentagesByCheckpoint: Array<string>
      airdropDestination?: {
        __typename?: 'VestingAirdropDestination'
        name: string
        type: AirdropDestination
      } | null
    } | null
    tiers: Array<{
      __typename?: 'Tier'
      allocationMultiple: string
      coolOff: number
      index: number
      isSpecial?: boolean | null
      level: number
      maxAllocation?: string | null
      minStake?: string | null
      name: string
      participationTickets: string
    }>
    targetToken?: {
      __typename?: 'Token'
      address: string
      blockchain: string
      decimals: number
      id: string
      name: string
      symbol: string
      totalSupply: string
    } | null
    statistics: {
      __typename?: 'PoolStatistics'
      participantsCount: number
      registeredCount: number
    }
    progress: {
      __typename?: 'PoolProgress'
      softCap?: string | null
      softCapPercent?: string | null
      tokensSoftCap?: string | null
      tokensSold?: string | null
      tokensToSell?: string | null
      totalRaised?: string | null
      totalTarget?: string | null
      totalTargetUSD?: string | null
    }
    pipeline: {
      __typename?: 'PoolPipeline'
      finishTime?: string | null
      prelaunchTime?: string | null
      registrationTime?: string | null
      startTime?: string | null
      whitelistingTime?: string | null
    }
  }>
}

export type PoolsWithSessionQueryVariables = Exact<{
  session: Scalars['Session']
}>

export type PoolsWithSessionQuery = {
  __typename?: 'Query'
  pools: Array<{
    __typename?: 'FixedPricePool'
    id: string
    blockchain: string
    address: string
    currentTime: string
    whitelistAddress: string
    projectAdmin?: string | null
    price?: string | null
    poolStatus: PoolStatus
    poolType: PoolType
    minOrderSizeTargetToken: string
    account?: {
      __typename?: 'PoolAccount'
      airdropTarget?: string | null
      account: string
      amountPaid: string
      idoTokenBalance: string
      isRegistered: boolean
      isWhitelisted?: boolean | null
      refundAmount: string
      targetTokenBalance: string
      userToReserve: string
      wave?: number | null
      waveStartTime?: string | null
      withdrawnAmount: string
      tier?: {
        __typename?: 'Tier'
        allocationMultiple: string
        coolOff: number
        index: number
        isSpecial?: boolean | null
        level: number
        maxAllocation?: string | null
        minStake?: string | null
        name: string
        participationTickets: string
      } | null
      vesting?: {
        __typename?: 'AccountVesting'
        available: Array<string>
        claimed: Array<string>
        totalAvailable: string
        totalClaimed: string
        unlocks: Array<string>
      } | null
    } | null
    meta: {
      __typename?: 'PoolMeta'
      ROIAllTimeHigh?: string | null
      auditReport?: string | null
      brandBackground?: string | null
      brandLogo?: string | null
      coingecko?: string | null
      coinmarketcap?: string | null
      constantUSDTotalTarget?: string | null
      description: string
      discord?: string | null
      dueDiligenceReport?: string | null
      initialCirculationAmount?: string | null
      initialMarketCap?: string | null
      initialMarketCapUSD?: string | null
      isIncubated?: boolean | null
      isKYCRequired: boolean
      markdown?: string | null
      medium?: string | null
      name: string
      position?: number | null
      prelaunchStart?: string | null
      priceAllTimeHigh?: string | null
      private: boolean
      registrationStart?: string | null
      sourceCode?: string | null
      subtitle?: string | null
      tags: Array<string>
      telegram?: string | null
      twitter?: string | null
      vestingDescription?: string | null
      video?: string | null
      visibility: boolean
      website?: string | null
      whitelistingStart?: string | null
      whitepaper?: string | null
      coolOffExplainedLink: string
      tiersExplainedLink: string
      roughTokenPriceUSD?: string | null
      participationExplainedLink: string
      constantUSDTokenPrice?: string | null
      secondaryMarkets: Array<{
        __typename?: 'SecondaryMarket'
        name: string
        sellStart?: string | null
        url: string
      }>
      team: Array<{
        __typename?: 'Member'
        avatar: string
        linkedIn: string
        name: string
        position: string
      }>
    }
    idoToken: {
      __typename?: 'Token'
      address: string
      blockchain: string
      decimals: number
      id: string
      name: string
      symbol: string
      totalSupply: string
    }
    waves?: {
      __typename?: 'PoolWaves'
      currentWave?: number | null
      pipeline: Array<{
        __typename?: 'PoolWavePipeline'
        number: number
        start: string
      }>
    } | null
    vesting?: {
      __typename?: 'Vesting'
      currentPeriodIndex?: number | null
      unlockCheckpoints: Array<string>
      unlockPercentages: Array<string>
      unlockPercentagesByCheckpoint: Array<string>
      airdropDestination?: {
        __typename?: 'VestingAirdropDestination'
        name: string
        type: AirdropDestination
      } | null
    } | null
    tiers: Array<{
      __typename?: 'Tier'
      allocationMultiple: string
      coolOff: number
      index: number
      isSpecial?: boolean | null
      level: number
      maxAllocation?: string | null
      minStake?: string | null
      name: string
      participationTickets: string
    }>
    targetToken?: {
      __typename?: 'Token'
      address: string
      blockchain: string
      decimals: number
      id: string
      name: string
      symbol: string
      totalSupply: string
    } | null
    statistics: {
      __typename?: 'PoolStatistics'
      participantsCount: number
      registeredCount: number
    }
    progress: {
      __typename?: 'PoolProgress'
      softCap?: string | null
      softCapPercent?: string | null
      tokensSoftCap?: string | null
      tokensSold?: string | null
      tokensToSell?: string | null
      totalRaised?: string | null
      totalTarget?: string | null
      totalTargetUSD?: string | null
    }
    pipeline: {
      __typename?: 'PoolPipeline'
      finishTime?: string | null
      prelaunchTime?: string | null
      registrationTime?: string | null
      startTime?: string | null
      whitelistingTime?: string | null
    }
  }>
}

export type StakingFieldsFragment = {
  __typename?: 'StakingInfo'
  id: string
  blockchain: string
  address: string
  contract: string
  apy: string
  uniqueStakers: number
  totalEmittedRewards: string
  totalRewardToDistribute?: string | null
  totalStaked: string
  unstakingFeeRatio: string
  priceRewardTokenUSD: string
  priceStakingTokenUSD: string
  account?: {
    __typename?: 'StakingAccount'
    account: string
    allowance: string
    rewardAvailable: string
    rewardClaimed: string
    rewardTotal: string
    rewardTotalUSD: string
    rewardPending: string
    stakingBalance: string
    walletBalance: string
    tier?: {
      __typename?: 'StakingTier'
      allocationMultiple: string
      coolOff?: number | null
      index: number
      isSpecial?: boolean | null
      level: number
      minStake: string
      name: string
      participationTickets: string
      stakeDelta?: string | null
      ticketDelta?: string | null
    } | null
  } | null
  currentEpoch?: {
    __typename?: 'Epoch'
    rewardsPerSecond: string
    startTime: string
  } | null
  nextEpoch?: {
    __typename?: 'Epoch'
    rewardsPerSecond: string
    startTime: string
  } | null
  stakingToken: {
    __typename?: 'Token'
    address: string
    blockchain: string
    decimals: number
    id: string
    name: string
    symbol: string
    totalSupply: string
  }
}

export type StakingInfoQueryVariables = Exact<{
  session?: InputMaybe<Scalars['Session']>
}>

export type StakingInfoQuery = {
  __typename?: 'Query'
  votingPower: Array<string>
  stakingInfo: {
    __typename?: 'StakingInfo'
    id: string
    blockchain: string
    address: string
    contract: string
    apy: string
    uniqueStakers: number
    totalEmittedRewards: string
    totalRewardToDistribute?: string | null
    totalStaked: string
    unstakingFeeRatio: string
    priceRewardTokenUSD: string
    priceStakingTokenUSD: string
    account?: {
      __typename?: 'StakingAccount'
      account: string
      allowance: string
      rewardAvailable: string
      rewardClaimed: string
      rewardTotal: string
      rewardTotalUSD: string
      rewardPending: string
      stakingBalance: string
      walletBalance: string
      tier?: {
        __typename?: 'StakingTier'
        allocationMultiple: string
        coolOff?: number | null
        index: number
        isSpecial?: boolean | null
        level: number
        minStake: string
        name: string
        participationTickets: string
        stakeDelta?: string | null
        ticketDelta?: string | null
      } | null
    } | null
    currentEpoch?: {
      __typename?: 'Epoch'
      rewardsPerSecond: string
      startTime: string
    } | null
    nextEpoch?: {
      __typename?: 'Epoch'
      rewardsPerSecond: string
      startTime: string
    } | null
    stakingToken: {
      __typename?: 'Token'
      address: string
      blockchain: string
      decimals: number
      id: string
      name: string
      symbol: string
      totalSupply: string
    }
  }
  apy: {
    __typename?: 'APY'
    accountAPY?: string | null
    tokensEarningPerYear?: string | null
    apyPerTier: Array<{
      __typename?: 'APYPerTier'
      apy: string
      tokensEarningPerYear: string
    }>
  }
  stakingInfoCHAKRA: {
    __typename?: 'StakingInfoCHAKRA'
    rewardToken: { __typename?: 'Token'; symbol: string }
  }
}

export type StakingInfoMultichainQueryVariables = Exact<{
  session?: InputMaybe<Scalars['Session']>
}>

export type StakingInfoMultichainQuery = {
  __typename?: 'Query'
  votingPower: Array<string>
  stakingInfoMultichain: {
    __typename?: 'InfoMultichain'
    totalStake: string
    uniqueStakers: number
    apy: string
    priceStakingTokenUSD: string
    tiers: Array<{
      __typename?: 'StakingTier'
      level: number
      index: number
      name: string
      minStake: string
      allocationMultiple: string
      participationTickets: string
      coolOff?: number | null
      isSpecial?: boolean | null
      stakeDelta?: string | null
      ticketDelta?: string | null
    }>
    account?: {
      __typename?: 'AccountInfoMultichain'
      account: string
      stakingBalance: string
      apy: string
      tier?: {
        __typename?: 'StakingTier'
        name: string
        level: number
        minStake: string
      } | null
    } | null
    stakingInfo: Array<{
      __typename?: 'StakingInfo'
      id: string
      blockchain: string
      address: string
      contract: string
      apy: string
      uniqueStakers: number
      totalEmittedRewards: string
      totalRewardToDistribute?: string | null
      totalStaked: string
      unstakingFeeRatio: string
      priceRewardTokenUSD: string
      priceStakingTokenUSD: string
      account?: {
        __typename?: 'StakingAccount'
        account: string
        allowance: string
        rewardAvailable: string
        rewardClaimed: string
        rewardTotal: string
        rewardTotalUSD: string
        rewardPending: string
        stakingBalance: string
        walletBalance: string
        tier?: {
          __typename?: 'StakingTier'
          allocationMultiple: string
          coolOff?: number | null
          index: number
          isSpecial?: boolean | null
          level: number
          minStake: string
          name: string
          participationTickets: string
          stakeDelta?: string | null
          ticketDelta?: string | null
        } | null
      } | null
      currentEpoch?: {
        __typename?: 'Epoch'
        rewardsPerSecond: string
        startTime: string
      } | null
      nextEpoch?: {
        __typename?: 'Epoch'
        rewardsPerSecond: string
        startTime: string
      } | null
      stakingToken: {
        __typename?: 'Token'
        address: string
        blockchain: string
        decimals: number
        id: string
        name: string
        symbol: string
        totalSupply: string
      }
    }>
  }
  apy: {
    __typename?: 'APY'
    accountAPY?: string | null
    tokensEarningPerYear?: string | null
    apyPerTier: Array<{
      __typename?: 'APYPerTier'
      apy: string
      tokensEarningPerYear: string
    }>
  }
  stakingInfoCHAKRA: {
    __typename?: 'StakingInfoCHAKRA'
    rewardToken: { __typename?: 'Token'; symbol: string }
  }
}

export type TelegramCapaignFragment = {
  __typename?: 'GetTelegramCampaignResponse'
  registeredCount: number
}

export const ApyPerTierFieldsFragmentDoc = gql`
  fragment ApyPerTierFields on APY {
    accountAPY
    tokensEarningPerYear
    apyPerTier {
      apy
      tokensEarningPerYear
    }
  }
`
export const PoolFieldsFragmentDoc = gql`
  fragment PoolFields on Pool {
    id
    blockchain
    address
    account {
      airdropTarget
    }
    meta {
      ROIAllTimeHigh
      auditReport
      brandBackground
      brandLogo
      coingecko
      coinmarketcap
      constantUSDTotalTarget
      description
      discord
      dueDiligenceReport
      initialCirculationAmount
      initialMarketCap
      initialMarketCapUSD
      isIncubated
      isKYCRequired
      markdown
      medium
      name
      position
      prelaunchStart
      priceAllTimeHigh
      private
      registrationStart
      secondaryMarkets {
        name
        sellStart
        url
      }
      sourceCode
      subtitle
      tags
      team {
        avatar
        linkedIn
        name
        position
      }
      telegram
      twitter
      vestingDescription
      video
      visibility
      website
      whitelistingStart
      whitepaper
      coolOffExplainedLink
      tiersExplainedLink
      roughTokenPriceUSD
      participationExplainedLink
      constantUSDTokenPrice
    }
    account {
      account
      amountPaid
      idoTokenBalance
      isRegistered
      isWhitelisted
      refundAmount
      targetTokenBalance
      tier {
        allocationMultiple
        coolOff
        index
        isSpecial
        level
        maxAllocation
        minStake
        name
        participationTickets
      }
      userToReserve
      vesting {
        available
        claimed
        totalAvailable
        totalClaimed
        unlocks
      }
      wave
      waveStartTime
      withdrawnAmount
    }
    currentTime
    idoToken {
      address
      blockchain
      decimals
      id
      name
      symbol
      totalSupply
    }
    whitelistAddress
    waves {
      currentWave
      pipeline {
        number
        start
      }
    }
    vesting {
      airdropDestination {
        name
        type
      }
      currentPeriodIndex
      unlockCheckpoints
      unlockPercentages
      unlockPercentagesByCheckpoint
    }
    tiers {
      allocationMultiple
      coolOff
      index
      isSpecial
      level
      maxAllocation
      minStake
      name
      participationTickets
    }
    targetToken {
      address
      blockchain
      decimals
      id
      name
      symbol
      totalSupply
    }
    statistics {
      participantsCount
      registeredCount
    }
    progress {
      softCap
      softCapPercent
      tokensSoftCap
      tokensSold
      tokensToSell
      totalRaised
      totalTarget
      totalTargetUSD
    }
    projectAdmin
    price
    poolStatus
    poolType
    pipeline {
      finishTime
      prelaunchTime
      registrationTime
      startTime
      whitelistingTime
    }
    minOrderSizeTargetToken
  }
`
export const StakingFieldsFragmentDoc = gql`
  fragment StakingFields on StakingInfo {
    id
    blockchain
    address
    contract
    apy
    uniqueStakers
    account {
      account
      allowance
      rewardAvailable
      rewardClaimed
      rewardTotal
      rewardTotalUSD
      rewardPending
      stakingBalance
      tier {
        allocationMultiple
        coolOff
        index
        isSpecial
        level
        minStake
        name
        participationTickets
        stakeDelta
        ticketDelta
      }
      walletBalance
    }
    currentEpoch {
      rewardsPerSecond
      startTime
    }
    nextEpoch {
      rewardsPerSecond
      startTime
    }
    stakingToken {
      address
      blockchain
      decimals
      id
      name
      symbol
      totalSupply
    }
    totalEmittedRewards
    totalRewardToDistribute
    totalStaked
    unstakingFeeRatio
    priceRewardTokenUSD
    priceStakingTokenUSD
  }
`
export const TelegramCapaignFragmentDoc = gql`
  fragment TelegramCapaign on GetTelegramCampaignResponse {
    registeredCount
  }
`
export const AddParticipantAidrdopTargetDocument = gql`
  mutation AddParticipantAidrdopTarget($input: AddAirdropTargetInput!) {
    addParticipantAirdropTarget(input: $input)
  }
`
export const AuthDocument = gql`
  mutation Auth($input: AuthInput!) {
    auth(input: $input) {
      session
      address
    }
  }
`
export const GetAuthMessageDocument = gql`
  mutation GetAuthMessage($blockchain: Blockchain!, $address: Address!) {
    getAuthMessage(blockchain: $blockchain, address: $address)
  }
`
export const RegisterPoolParticipationDocument = gql`
  mutation RegisterPoolParticipation($input: RegisterPoolParticipationInput!) {
    registerPoolParticipation(input: $input) {
      reason
      success
    }
  }
`
export const CedClaimAllTokensDocument = gql`
  query CedClaimAllTokens(
    $session: Session!
    $cedContractType: CEDContractType!
  ) {
    cedClaimAllTokens(session: $session, cedContractType: $cedContractType) {
      from
      to
      chainId
      gas
      gasPrice
      data
      nonce
      value
    }
  }
`
export const CedRewardsDocument = gql`
  query CedRewards($session: Session!, $cedContractType: CEDContractType!) {
    cedRewards(session: $session, cedContractType: $cedContractType) {
      tokenAddress
      tokenName
      tokenSymbol
      tokenDecimals
      img
      amount
    }
  }
`
export const ChakraInfoDocument = gql`
  query ChakraInfo($session: Session) {
    indexTokenInfo {
      assets {
        symbol
        projectName
        amount
        priceUSD
        valueUSD
        logoLink
        websiteLink
      }
      indexTokenFairPriceUSD
      totalValueUSD
    }
    stakingInfoCHAKRA(session: $session) {
      priceStakingTokenUSD
      priceRewardTokenUSD
      apy
      totalStaked
      totalEmittedRewards
      stakingToken {
        symbol
      }
      rewardToken {
        name
        symbol
        id
        blockchain
        address
        decimals
        totalSupply
      }
      account {
        account
        rewardAvailable
        rewardAvailableUSD
        rewardClaimed
        rewardTotal
        rewardTotalUSD
        rewardPending
        rewardPendingUSD
      }
    }
    stakingInfoMultichain(session: $session) {
      account {
        tier {
          level
        }
        stakingBalance
      }
      tiers {
        index
        name
        minStake
      }
    }
    apy(session: $session) {
      ...ApyPerTierFields
    }
  }
  ${ApyPerTierFieldsFragmentDoc}
`
export const ClaimRewardsDocument = gql`
  query ClaimRewards($session: Session!) {
    claimRewards(session: $session) {
      from
      to
      chainId
      gas
      gasPrice
      data
      nonce
      value
    }
  }
`
export const GenerateTelegramCampaignCodeDocument = gql`
  mutation GenerateTelegramCampaignCode($session: Session!) {
    generateTelegramCampaignCode(session: $session)
  }
`
export const GetLiquidityMiningListDocument = gql`
  query GetLiquidityMiningList($account: Address) {
    getLiquidityMiningList(account: $account) {
      list {
        commonData {
          blockchain
          address
          name
          rewardsToken {
            id
            address
            totalSupply
            name
            symbol
            decimals
          }
          liquidityToken {
            id
            address
            totalSupply
            name
            symbol
            decimals
          }
          poolAddress
          apy
          currentEpoch {
            startTime
            rewardsPerSecond
          }
          totalStakedUSD
          totalStakedLT
          priceLiquidityTokenUSD
          liquidityTokenPrettyName
          poolInfoLink
          poolTvlUSD
          unstakeFeeRatio
        }
        accountData {
          walletBalance
          stakingBalance
          stakingTokenAllowance
          rewardsTotal
          rewardsClaimed
          rewardsAvailable
          rewardTokenBalance
        }
      }
    }
  }
`
export const GetTelegramCampaignDocument = gql`
  query GetTelegramCampaign {
    getTelegramCampaign {
      ...TelegramCapaign
    }
  }
  ${TelegramCapaignFragmentDoc}
`
export const GetTelegramCampaignWithSessionDocument = gql`
  query GetTelegramCampaignWithSession($session: Session!) {
    getTelegramCampaign {
      account(session: $session)
      ...TelegramCapaign
    }
  }
  ${TelegramCapaignFragmentDoc}
`
export const KycInfoDocument = gql`
  query KYCInfo($session: Session) {
    kycInfo(session: $session) {
      generalLink
      supportLink
      usInvestorsLink
      account {
        lastSyncTime
        status
        rejectType
        resubmitLink
      }
    }
  }
`
export const PoolDocument = gql`
  query Pool($id: PoolID!, $session: Session) {
    pool(id: $id, session: $session) {
      ...PoolFields
    }
  }
  ${PoolFieldsFragmentDoc}
`
export const PoolsDocument = gql`
  query Pools {
    pools {
      ...PoolFields
    }
  }
  ${PoolFieldsFragmentDoc}
`
export const PoolsWithSessionDocument = gql`
  query PoolsWithSession($session: Session!) {
    pools(session: $session) {
      ...PoolFields
    }
  }
  ${PoolFieldsFragmentDoc}
`
export const StakingInfoDocument = gql`
  query StakingInfo($session: Session) {
    stakingInfo(session: $session) {
      ...StakingFields
    }
    apy(session: $session) {
      ...ApyPerTierFields
    }
    votingPower
    stakingInfoCHAKRA(session: $session) {
      rewardToken {
        symbol
      }
    }
  }
  ${StakingFieldsFragmentDoc}
  ${ApyPerTierFieldsFragmentDoc}
`
export const StakingInfoMultichainDocument = gql`
  query StakingInfoMultichain($session: Session) {
    stakingInfoMultichain(session: $session) {
      totalStake
      uniqueStakers
      apy
      priceStakingTokenUSD
      tiers {
        level
        index
        name
        minStake
        allocationMultiple
        participationTickets
        coolOff
        isSpecial
        stakeDelta
        ticketDelta
      }
      account {
        account
        stakingBalance
        apy
        tier {
          name
          level
          minStake
        }
      }
      stakingInfo {
        ...StakingFields
      }
    }
    apy(session: $session) {
      ...ApyPerTierFields
    }
    votingPower
    stakingInfoCHAKRA(session: $session) {
      rewardToken {
        symbol
      }
    }
  }
  ${StakingFieldsFragmentDoc}
  ${ApyPerTierFieldsFragmentDoc}
`

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string
) => Promise<T>

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType
) => action()

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    AddParticipantAidrdopTarget(
      variables: AddParticipantAidrdopTargetMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<AddParticipantAidrdopTargetMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddParticipantAidrdopTargetMutation>(
            AddParticipantAidrdopTargetDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'AddParticipantAidrdopTarget',
        'mutation'
      )
    },
    Auth(
      variables: AuthMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<AuthMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AuthMutation>(AuthDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Auth',
        'mutation'
      )
    },
    GetAuthMessage(
      variables: GetAuthMessageMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetAuthMessageMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAuthMessageMutation>(
            GetAuthMessageDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetAuthMessage',
        'mutation'
      )
    },
    RegisterPoolParticipation(
      variables: RegisterPoolParticipationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<RegisterPoolParticipationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RegisterPoolParticipationMutation>(
            RegisterPoolParticipationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'RegisterPoolParticipation',
        'mutation'
      )
    },
    CedClaimAllTokens(
      variables: CedClaimAllTokensQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<CedClaimAllTokensQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CedClaimAllTokensQuery>(
            CedClaimAllTokensDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'CedClaimAllTokens',
        'query'
      )
    },
    CedRewards(
      variables: CedRewardsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<CedRewardsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CedRewardsQuery>(CedRewardsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CedRewards',
        'query'
      )
    },
    ChakraInfo(
      variables?: ChakraInfoQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<ChakraInfoQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ChakraInfoQuery>(ChakraInfoDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ChakraInfo',
        'query'
      )
    },
    ClaimRewards(
      variables: ClaimRewardsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<ClaimRewardsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ClaimRewardsQuery>(ClaimRewardsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ClaimRewards',
        'query'
      )
    },
    GenerateTelegramCampaignCode(
      variables: GenerateTelegramCampaignCodeMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GenerateTelegramCampaignCodeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GenerateTelegramCampaignCodeMutation>(
            GenerateTelegramCampaignCodeDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GenerateTelegramCampaignCode',
        'mutation'
      )
    },
    GetLiquidityMiningList(
      variables?: GetLiquidityMiningListQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetLiquidityMiningListQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetLiquidityMiningListQuery>(
            GetLiquidityMiningListDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetLiquidityMiningList',
        'query'
      )
    },
    GetTelegramCampaign(
      variables?: GetTelegramCampaignQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetTelegramCampaignQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetTelegramCampaignQuery>(
            GetTelegramCampaignDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetTelegramCampaign',
        'query'
      )
    },
    GetTelegramCampaignWithSession(
      variables: GetTelegramCampaignWithSessionQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetTelegramCampaignWithSessionQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetTelegramCampaignWithSessionQuery>(
            GetTelegramCampaignWithSessionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetTelegramCampaignWithSession',
        'query'
      )
    },
    KYCInfo(
      variables?: KycInfoQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<KycInfoQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<KycInfoQuery>(KycInfoDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'KYCInfo',
        'query'
      )
    },
    Pool(
      variables: PoolQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PoolQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PoolQuery>(PoolDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Pool',
        'query'
      )
    },
    Pools(
      variables?: PoolsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PoolsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PoolsQuery>(PoolsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Pools',
        'query'
      )
    },
    PoolsWithSession(
      variables: PoolsWithSessionQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PoolsWithSessionQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PoolsWithSessionQuery>(
            PoolsWithSessionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'PoolsWithSession',
        'query'
      )
    },
    StakingInfo(
      variables?: StakingInfoQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<StakingInfoQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<StakingInfoQuery>(StakingInfoDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'StakingInfo',
        'query'
      )
    },
    StakingInfoMultichain(
      variables?: StakingInfoMultichainQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<StakingInfoMultichainQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<StakingInfoMultichainQuery>(
            StakingInfoMultichainDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'StakingInfoMultichain',
        'query'
      )
    },
  }
}

export type Sdk = ReturnType<typeof getSdk>
