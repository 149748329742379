import { graphqlSdk } from 'gql/client'
import invariant from 'tiny-invariant'
import metamaskIcon from 'resources/images/wallets/metamask.svg'
import walletConnectIcon from 'resources/images/wallets/walletconnect.svg'
import { attach } from 'effector'
import { isMobile } from 'react-device-detect'
import { getMetamaskProvider, getWalletConnectProvider } from 'utils/providers'
import { ethers } from 'ethers'
import { $selectedChainId } from '.'
import { getBlockchainNameByChainId } from 'utils/blockchain'

export enum ProviderId {
  MetaMask = 'metaMask',
  WalletConnect = 'walletConnect',
}

function openMetaMaskUrl(url: string) {
  const a = document.createElement('a')
  a.href = url
  a.target = '_self'
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export const metamaskAuthFx = attach({
  source: $selectedChainId,
  effect: async (selectedChainId) => {
    const blockchainName = getBlockchainNameByChainId(selectedChainId)
    const metamaskProvider = await getMetamaskProvider()
    const provider = metamaskProvider
      ? new ethers.providers.Web3Provider(metamaskProvider, 'any')
      : null

    if (isMobile && !provider?.provider.isMetaMask) {
      // window.open(process.env.REACT_APP_DAPP_URL, '_blank')
      openMetaMaskUrl(process.env.REACT_APP_DAPP_URL!.toString())
    }

    if (!provider?.provider.isMetaMask) {
      window.open('https://metamask.io', '_blank', 'noopener,noreferrer')
    }

    invariant(provider?.provider.isMetaMask, 'metamask: extension not found')

    const accounts = await window.ethereum?.request({
      method: 'eth_requestAccounts',
    })
    invariant(
      Array.isArray(accounts) && accounts.length,
      'metamask: accounts not found'
    )

    const address = accounts[0] as string
    const authMessageData = await graphqlSdk.GetAuthMessage({
      address,
      blockchain: blockchainName,
    })
    const authMessage = authMessageData?.getAuthMessage ?? ''

    let sign
    if (provider?.provider.request) {
      sign = await provider?.provider.request({
        method: 'personal_sign',
        params: [address, authMessage],
      })
    }
    const authData = await graphqlSdk.Auth({
      input: { sign, authMessage, blockchain: blockchainName },
    })

    return { address, session: authData.auth.session }
  },
})

export const walletConnectAuthFx = attach({
  source: $selectedChainId,
  effect: async (selectedChainId) => {
    const blockchainName = getBlockchainNameByChainId(selectedChainId)
    const walletConnectProvider = await getWalletConnectProvider()
    await walletConnectProvider.connect()
    const provider = new ethers.providers.Web3Provider(
      walletConnectProvider,
      'any'
    )

    const address = walletConnectProvider.accounts[0]
    const authMessageData = await graphqlSdk.GetAuthMessage({
      address,
      blockchain: blockchainName,
    })
    const authMessage = authMessageData?.getAuthMessage ?? ''

    let sign = ''
    if (provider.provider.request) {
      sign = await provider.provider.request({
        method: 'personal_sign',
        params: [address, authMessage],
      })
    }

    const authData = await graphqlSdk.Auth({
      input: { sign, authMessage, blockchain: blockchainName },
    })

    return { address, session: authData.auth.session }
  },
})

export const metamask = {
  id: ProviderId.MetaMask,
  name: 'MetaMask',
  icon: metamaskIcon,
  authFx: metamaskAuthFx,
}

export const walletConnect = {
  id: ProviderId.WalletConnect,
  name: 'Wallet Connect',
  icon: walletConnectIcon,
  authFx: walletConnectAuthFx,
}

export const wallets = [metamask, walletConnect]

export const getWalletNameById = (id: ProviderId) =>
  wallets.filter((w) => w.id === id).pop()?.name ?? ''

export const getWalletIconById = (id: ProviderId) =>
  wallets.filter((w) => w.id === id).pop()?.icon ?? ''
