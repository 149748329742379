import Timer from 'components/dumb/Timer'
import { PoolPipeline, PoolStatus, Vesting } from 'gql'
import { ReactComponent as JoinSaleArrow } from 'resources/images/icons/join-sale-arrow.svg'
import useCountdownTimeAndTitle from 'utils/pool/useCountdownTimeAndTitle'
import t from 'utils/t'
import Badge from './Badge'
import Block from './Block'
import Field, { FieldProps } from './Field'
import ProgressLine, { ProgressLineProps } from './ProgressLine'

export interface HomePoolCampaignCardProps {
  id: string
}

export default function HomePoolCampaignCard({
  id,
  onClick,
}: HomePoolCampaignCardProps & {
  onClick: (id: string) => void
}) {
  const subtitle = 'Unlocking the era of personalized digital experiences.'
  const pipeline = {
    whitelistingTime: '2024-10-28T08:00:00Z',
    prelaunchTime: '2024-10-28T08:00:00Z',
    startTime: '2024-10-28T14:00:00Z',
    finishTime: '2024-11-02T14:00:00Z',
  } satisfies PoolPipeline
  const now = new Date()
  const inProgress =
    now >= new Date(pipeline.startTime) && now < new Date(pipeline.finishTime)
  const poolStatus = calcPoolStatus(now, pipeline)
  const vesting = undefined satisfies Vesting | undefined
  const isPrivate = true
  const progressLine: ProgressLineProps = {
    appearance: 'card',
    percent: '0',
    softCap: '5,000',
    softCapPercent: '2.5',
    targetTokenSymbol: 'USD',
    totalTarget: '200,490',
  }
  const fields: FieldProps[] = [
    {
      title: 'Nodes To Sell',
      value: 410,
    },
    {
      title: 'Minimal Node Price',
      value: 489,
      symbol: 'USD',
    },
  ]

  const { countdownTime, countdownTitle } = useCountdownTimeAndTitle({
    vesting,
    pipeline,
    poolStatus,
    isPrivate,
  })

  return (
    <Block
      containerClassName="!py-0 !px-0 overflow-hidden rounded-[10px]"
      wrapperClassName="group relative z-20"
      onClick={() => onClick(id)}
    >
      <div className="relative z-30 cursor-pointer">
        <div className="grid grid-cols-1 grid-rows-[1fr,2fr] ml:auto-rows-fr t:grid-rows-1 dxs:grid-cols-2">
          <div className="relative flex flex-col">
            <div
              className="bg-105 bg-cover-repeat absolute z-0 h-full w-full bg-cover bg-center"
              style={{
                backgroundImage: `url(https://occam-razer.s3.eu-central-1.amazonaws.com/idoDescriptions/BluwhaleBackground.png)`,
              }}
            ></div>
            <div className="relative z-10 flex grow flex-col justify-between p-5">
              <div className="flex items-center space-x-3">
                <Badge>
                  <div className="flex h-full items-center space-x-2">
                    <div className="relative top-0 h-[8px] w-[8px]">
                      <div className="absolute h-full w-full animate-ping-slow rounded-full bg-red"></div>
                      <div className="relative h-[8px] w-[8px] rounded-full bg-transparent"></div>
                    </div>
                    <span className="text-[14px] font-medium uppercase text-brightGray">
                      {t('live')}
                    </span>
                  </div>
                </Badge>
              </div>
            </div>
          </div>
          <div className="p-[20px] pb-[20px] dxs:h-[410px]">
            <div className="flex h-full flex-col justify-between">
              <div>
                <div className="relative flex items-center space-x-[16px]">
                  <div className="relative row-span-2 h-[70px] w-[70px] rounded-[6px] border border-geyser/40 bg-porcelain p-1">
                    <div
                      className="absolute left-1/2 top-1/2 h-[60px] w-[60px] -translate-x-1/2 -translate-y-1/2 bg-cover bg-no-repeat"
                      style={{
                        backgroundImage: `url(https://occam-razer.s3.eu-central-1.amazonaws.com/idoDescriptions/BluwhaleLogo.png)`,
                      }}
                    />
                  </div>
                  <div>
                    <div className="self-center text-3xl leading-9 text-brightGray ml:self-start">
                      Bluwhale
                    </div>
                  </div>
                </div>

                <p className="mb-6 mt-4 truncate font-manrope text-lg leading-[130%] text-boulder">
                  {subtitle}
                </p>

                <div className="">
                  <ProgressLine {...progressLine} appearance="card" />
                  <div className="mt-8 grid grid-cols-1 gap-y-4 mm:grid-cols-2 ml:grid-cols-3">
                    {fields.map((f) => (
                      <Field
                        key={f.title}
                        {...f}
                        type="card"
                        icon={null}
                        sub={null}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <Timer
                  endTime={countdownTime}
                  text={countdownTitle}
                  containerClassName="bg-geyser/40 !items-center rounded-[5px] p-3"
                  textClassName="!leading-none"
                  countdownClassName="font-neue !text-[22px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {inProgress && (
        <div className="relative z-20 m-auto w-full cursor-pointer border-t border-geyser/60 bg-primary/11">
          <div className="peer relative z-10 flex items-center justify-center py-[0.8rem]">
            <span className="flex items-center text-[0.8rem] uppercase leading-[140%] text-primary">
              {t('join_sale_button')}
              <JoinSaleArrow className="ml-2 h-[0.7rem] w-[0.7rem] fill-current text-primary" />
            </span>
          </div>
        </div>
      )}
    </Block>
  )
}

const calcPoolStatus = (
  now: Date,
  pipeline: {
    finishTime: string
    startTime: string
    prelaunchTime: string
  }
): PoolStatus => {
  if (now >= new Date(pipeline.finishTime)) {
    return PoolStatus.SuccessfullyFinished
  }

  if (now >= new Date(pipeline.startTime)) {
    return PoolStatus.InProgress
  }

  if (now >= new Date(pipeline.prelaunchTime)) {
    return PoolStatus.Prelaunch
  }

  return PoolStatus.Registration
}
