import t from 'utils/t'
import Block from './Block'
import SocialLink, { SocialLinkProps } from './SocialLink'

export type Props = {
  links: SocialLinkProps[]
}

export default function ProjectPageSocialLinks({ links }: Props) {
  return (
    <Block
      wrapperClassName="mb-12"
      containerClassName="space-y-3 ml:flex ml:space-y-0 justify-between"
    >
      <div className="text-2xl font-light text-brightGray">{t('links')}</div>
      <div className="flex space-x-3">
        {links?.map((link) => {
          return link.url ? (
            <div
              key={`link-${link.icon}`}
              className="relative h-[26px] w-[26px] shrink-0 cursor-pointer rounded-[5px] bg-geyser/40 hover:bg-geyser/80"
            >
              <SocialLink
                url={link.url}
                icon={link.icon}
                className="absolute left-1/2 top-1/2 h-4 w-4 -translate-x-1/2 -translate-y-1/2 transform fill-current text-primary"
              />
            </div>
          ) : null
        })}
      </div>
    </Block>
  )
}
