export default function StakingPriceSkelet1() {
  return (
    <>
      <svg
        width="40%"
        height="100%"
        viewBox="0 0 175 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        className="absolute hidden t:block"
      >
        <rect y="6" width="82" height="12" rx="3" fill="#DDE4EE" />
        <rect y="29" width="175" height="20" rx="5" fill="#DDE4EE" />
      </svg>

      <svg
        width="60%"
        height="100%"
        viewBox="0 0 175 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        className="absolute block t:hidden"
      >
        <rect y="0" width="82" height="9" rx="3" fill="#DDE4EE" />
        <rect y="11" width="175" height="14" rx="5" fill="#DDE4EE" />
      </svg>
    </>
  )
}
