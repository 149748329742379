import classNames from 'classnames'

export default function Spinner({ className = '' }: { className?: string }) {
  const offset = 187
  return (
    <svg
      className={classNames('spinner', className)}
      width="65px"
      height="65px"
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="spinner-path"
        fill="none"
        strokeDasharray={offset}
        strokeDashoffset={0}
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      ></circle>
    </svg>
  )
}
