export default function HomeCover2() {
  return (
    <>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 540 175"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        className="hidden ml:block"
      >
        <rect width="191" height="31" rx="8" fill="#DDE4EE" />
        <rect y="43" width="226" height="70" rx="10" fill="#DDE4EE" />
        <rect y="133" width="118" height="42" rx="8" fill="#DDE4EE" />
        <rect x="134" y="133" width="118" height="42" rx="8" fill="#DDE4EE" />
      </svg>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 270 175"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        className="block ml:hidden"
      >
        <rect width="158" height="26" rx="8" fill="#DDE4EE" />
        <rect y="38" width="105" height="75" rx="8" fill="#DDE4EE" />
        <rect y="133" width="129" height="42" rx="8" fill="#DDE4EE" />
        <rect x="141" y="133" width="129" height="42" rx="8" fill="#DDE4EE" />
      </svg>
    </>
  )
}
