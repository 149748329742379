export const APPLY_IDO_LINK = 'https://forms.gle/tztXqpjcSWmS9TjE6'
export const FAQ_LINK = 'https://hackmd.io/@OccamDAO/OccamDAO'
export const RAZER_APP_LINK = 'https://razer.occam.fi/'
export const OCCAMX_APP_LINK = 'https://app.occam-x.fi/'
export const RAZER_STAKE_LINK =
  'https://razer.occam.fi/personal/occ-panel/staking'
export const CAREERS_LINK = 'https://angel.co/company/occam-fi/jobs'
export const ADA_STAKE_POOL_LINK =
  'https://adapools.org/pool/34f513d0f993d188557696d598f54944879c24cb60811aea840fce47'
export const ADA_STAKE_POOL_BASE_LINK = 'https://adapools.org/pool/'
export const FORUM_LINK = 'https://forum.occam.fi/'
export const OCCAMX_LINK = 'https://occam-x.fi'
export const INCUBATOR_LINK = 'https://occam.fi/incubator'
export const OCCAMX_DASHBOARD_LINK = 'https://occam-x.fi/ocx_board'
export const INCUBATOR_DECK = 'https://docsend.com/view/nfnc4sumeptuevry'
export const MAIL_LINK = 'mailto:alikhan@occam.fi'
export const SUPPORT_LINK = 'https://support.occam.fi/support/tickets/new'
export const IDO_KYC = 'https://occam.fi/ido-kyc'
export const USING_IDO_KYC = 'https://occam.fi/usinst-ido-kyc'
export const GOVERNANCE = 'https://hackmd.io/@OccamDAO/Governance'
export const VC_DAO = 'https://occam.fi/vcdao'
export const STAKING_GUIDE = 'https://hackmd.io/@OccamDAO/StakingGuide'
export const LM_GUIDE = 'https://hackmd.io/@OccamDAO/LiquidityMining'
export const APPLY_FOR_KYC_LINK =
  process.env.REACT_APP_NETWORK === 'mainnet'
    ? 'https://in.sumsub.com/idensic/l/#/uni_aJXf63Bea9tu1rKS'
    : 'https://in.sumsub.com/idensic/l/#/sbx_uni_DCfgTH1enTm6A7Xo'
export const KYC_SUPPORT_LINK = 'https://support.occam.fi/support/tickets/new'

export const SOCIAL_LINKS_FOOTER = [
  {
    name: 'twitter',
    link: 'https://twitter.com/OccamFi',
  },
  // {
  //   name: 'instagram',
  //   link: 'https://www.instagram.com/occamfi',
  // },
  //{
  //name: 'reddit',
  //link: 'https://www.reddit.com/user/OccamFi',
  //},
  {
    name: 'telegram',
    link: 'https://t.me/occamfi_com',
  },
  {
    name: 'medium',
    link: 'https://medium.com/occam-finance',
  },
  {
    name: 'discord',
    link: 'https://discord.gg/occamfi',
  },
]

export const SOCIAL_LINKS_HEADER = {
  twitter: 'https://twitter.com/OccamFi',
  medium: 'https://medium.com/occam-finance',
  telegram: 'https://t.me/occamfi_com',
  discord: 'https://discord.gg/occamfi',
}
