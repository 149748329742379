import Button from 'components/dumb/Button'
import { ReactComponent as DotsLoader } from 'resources/images/dots-loader.svg'
import { $claimLTSent, getRewardLMFx } from 'models/lm'
import { LM } from 'models/lm/lm'
import { openWallet } from 'models/wallet'
import { noop } from 'utils'
import { printNumberThresholds } from 'utils/numbers'
import t from 'utils/t'
import { useStore } from 'effector-react'
import { $session } from 'models/sessions'
import isEmpty from 'lodash/isEmpty'

type Props = { pool: LM }
export default function ClaimTerminal({ pool }: Props) {
  const claimLTSent = useStore($claimLTSent)
  const session = useStore($session)

  let buttonText = 'Claim'
  let buttonDisabled = false
  let handleClick = noop
  let showLoader = false

  switch (pool.claimTerminalState) {
    case 'walletNotConnected':
      buttonText = t('connect_wallet')
      handleClick = openWallet
      break
    case 'readyToClaim':
      handleClick = () => getRewardLMFx()
      if (claimLTSent) {
        buttonText = 'Claiming'
        buttonDisabled = true
        showLoader = true
      } else {
        buttonText = 'Claim'
        buttonDisabled = false
        showLoader = false
      }
      break
    case 'notReadyToClaim':
    default:
      buttonDisabled = true
      break
  }

  const rewardTotal = printNumberThresholds(pool.rewardsTotalDec, 6)

  const rewardAvailable = printNumberThresholds(pool.rewardsAvailableDec, 6)

  return (
    <div className="relative">
      <div className="mb-4 rounded-[0.35rem] border border-iron p-[0.75rem]">
        <div className="text-2xl text-brightGray">
          {t('available_for_claiming')}
        </div>
        <div className="flex justify-between space-x-8">
          <div className="py-2">
            <div className="flex items-baseline text-primary">
              <div className="mr-[0.5rem] text-[1.95rem]">
                {rewardAvailable === 'NaN' || isEmpty(session)
                  ? '0'
                  : rewardAvailable}
              </div>
              <div className="relative text-base uppercase">
                {pool.rewardsSymbol}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-4 text-sm text-grayTertiary">
        {pool.rewardsSymbol} {t('total_earned')}:{' '}
        {isEmpty(session) ? '0' : rewardTotal}
      </div>
      <Button
        className="w-full"
        onClick={handleClick}
        disabled={buttonDisabled}
      >
        <div className="flex items-center justify-center">
          {buttonText}
          {showLoader && (
            <div className="ml-2">
              <DotsLoader />
            </div>
          )}
        </div>
      </Button>
    </div>
  )
}
