export default function strings() {
  return {
    connect_wallet: 'Connect Wallet',
    connect_wallet_to_see_your_tier: 'Connect Wallet to see your tier',
    calculator: 'Calculator',
    switch_network: 'Switch network',
    go_to_staking: 'Go to staking',
    proceed: 'Proceed',
    register: 'Register',
    buy: 'Buy',
    claim: 'Claim',
    refund: 'Refund',
    stake: 'Stake',
    unstake: 'Unstake',
    wallet: 'Wallet',
    error: 'Error',
    error_modals_text: 'Something went wrong.',
    network: 'Network',

    // home page
    welcom_to: 'Welcome to the OccamRazer Launchpad',
    active_projects: 'Active Projects',
    upcoming_projects: 'Upcoming Projects',
    finished_projects: 'Finished Projects',
    stake_symbol: 'Stake {{symbol}}',
    go_to_dashboard: 'Go to Dashboard',
    apply_for_ido: 'Apply for IDO',
    apply_for_ido_text: `
      Bear market is the time and place to ape to build. NEARStarter is
      opening its doors for new aspiring projects launching on NEAR.
    `,
    staking_guide: 'Staking Guide',
    lm_guide: 'Liquidity Mining Guide',

    //wallet
    your_tier: 'Your Tier',
    your_stake: 'Your Stake',
    terms_of_use: 'Terms of Use',
    terms_of_use_text: 'By connecting a wallet you accept {{termsOfUse}}',
    connected_wallet: 'Connected Wallet',

    // register
    register_tg_text: `You’re almost done! The sale will be carried out in Telegram. Follow the button below to confirm your registration in Telegram and complete the process.`,
    register_text: `
      Registration is non-reversible. You can still move to a higher tier
      before the whitelisting stage begins. You should have your tokens
      staked until the end of the IDO, otherwise we do not guarantee
      correct whitelisting and allocation assignment
    `,
    confirm_registration: 'Confirm Registration',
    confirm_tg_registration: 'Continue in Telegram',
    waiting_for_registration: 'Waiting for Registration',
    registration_complete: 'Registration Complete',
    registration_complete_text: 'You have successfully registered for the IDO.',

    // transaction
    waiting_for_confirmation: 'Waiting for Confirmation',
    confirm_transaction_text: 'Confirm this transaction in your wallet.',
    transaction_submitted: 'Transaction Submitted',
    transaction_submitted_text:
      'Transaction is submitted and is yet to be confirmed by the blockchain.',
    view_transaction: 'View transaction on explorer',
    view_transaction_short: 'View on Explorer',

    // toast
    transaction_successful: 'Transaction successful',
    transaction_successful_text: `Transaction is confirmed by the blockchain.`,
    transaction_failed: 'Transaction failed',
    transaction_pending: 'Transaction is pending',
    transaction_pending_text:
      'Transaction is submitted and is yet to be confirmed by the blockchain.',

    // kyc texts
    your_kyc_status: 'Your KYC status',
    regular_kyc: 'Regular KYC',
    us_accredited_investor: 'U.S. Accredited Investor',
    venture_capital: 'Venture Capital',
    requires_kyc_text: 'Participation requires KYC verification',
    apply_for_kyc: 'Apply for KYC',
    finalize_kyc: 'Finalize KYC',
    verified: 'Verified',
    verified_description: 'You have successfully completed the KYC procedure.',
    rejected: 'Rejected',
    rejected_description:
      'You failed to pass verification due to major violations.',
    resubmission_requested: 'Resubmission Requested',
    resubmission_requested_description:
      'Application requires additional actions.',
    not_verified: 'Not Verified',
    not_verified_description:
      'Your address is not yet approved by KYC procedure.',

    // about kyc
    is_kyc_required: 'Is KYC required for IDO participation?',
    is_kyc_required_answer: `
      As a DAO, Occam Razer does not require users to verify their identity before participating in IDOs.
      However, some projects launching on our Launchpad will have different expectations
      and will require users to be KYC verified to participate in their IDO.
      Passing the KYC process once will make you eligible to participate in these IDOs.
    `,
    how_long: 'How long does the KYC verification process take?',
    how_long_answer: `
      Depending on the jurisdiction the KYC application may be
      in review between up to 36 hours. Upon successful verification,
      the status of successful KYC will be updated on our website within 24 hours.
    `,
    why_failed: 'Why has my KYC verification failed?',
    why_failed_answer: `
      In very rare cases, your KYC verification may fail.
      To minimize the chance of this happening, make sure
      that the documents you submit are clearly readable,
      not expired or physically damaged.If this happens,
      please get in contact with us by clicking
      on "Contact Support" link below.
    `,
    having_kyc_issues: 'Having KYC Issues & Questions? {{button}}',
    contact_support: 'Contact Support',

    // ido info params
    target: 'Target',
    token_price: 'Token Price',
    initial_market_cap: 'Initial Market Cap',
    tokens_to_sell: 'Tokens To Sell',
    vesting: 'Vesting',
    user_registered: 'Users Registered',
    user_participated: 'Users Participated',

    // active pool card. some tooltips also for details page
    copy_token_address: 'Copy Token Address',
    add_token_to_wallet: 'Add Token to Wallet',
    incubated_by: 'Incubated by OccamDAO',
    live: 'Live',
    ido_type_tooltip_text: `
      Public access type means “first come first serve”.
      Private access means that staking tier and registration is required.
      Most IDO pools also require KYC verification.
    `,
    incubated_by_launchpad: 'Incubated by NEARStarter',
    due_diligence_report: 'Due Diligence Report',
    join_sale_button: 'Join sale',
    copied_to_clipboard: 'Copied to Clipboard',

    // finished pool card and sale statistics
    ath_roi: 'ATH ROI',
    ido_price: 'IDO Price',
    total_raised: 'Total Raised',
    participants: 'Participants',
    ended: 'Ended',
    secondary_market: 'Secondary Market',
    exchange: 'Exchange',
    project_name: 'Project name',
    tokens_sold: 'Tokens Sold',
    ath_token_price: 'ATH Token Price',

    // ido timer and statuses texts
    registartion_opens_in: 'Registration opens in',
    registration_dates_to_be_announced:
      'Registration dates to be announced. Follow updates',
    registration_ends_in: 'Registration ends in',
    ido_begins_in: 'IDO begins in',
    nodesale_begins_in: 'Nodesale begins in',
    ido_failed: 'IDO failed',
    sale_ends_in: 'Sale ends in',
    next_unlock_in: 'Next unlock in',

    // details page heading
    read_more: 'Read more',
    open_pdf: 'Open PDF',

    // details sale statistics
    sale_has_ended: 'Sale Has Ended',
    vesting_progress: 'Vesting Progress',
    vesting_ended: 'Vesting Has Ended',
    successfully_funded: 'Successfully funded',

    // details sale progress
    sale_progress: 'Sale Progress',

    // details participation terminal
    participation_terminal: 'Participation terminal',
    not_eligible_tip: `You did not register for this IDO. Read more about {{link}}`,
    ido_process: 'IDO process',
    nodesale_not_eligible_tip: `You did not register for this Nodesale. Read more about {{link}}`,
    nodesale_process: 'Nodesale process',
    kyc_rejected_final_tip: `
      Account failed to pass KYC verification and
      is not eligible for participation
    `,
    privat_and_non_participating_tip: `
      To participate in IDOs you need to stake enough {{symbol}} and
      register before pre-launch begins. Read more about {{link}}.
    `,
    nodesale_private_and_non_participating_tip: `
      To participate in Nodesales you need to stake enough {{symbol}} and
      register before pre-launch begins. When the sale starts,
      we will send you the link in the bot. Read more about {{link}}.
    `,
    insufficient_token_stake: 'Insufficient {{symbol}} stake',
    resident_text: `
      I confirm I am not a resident of Afghanistan, Iran, Nicaragua, North
      Korea, Syria, Yemen or of the United States of America.
    `,
    coming_soon: 'Coming soon',
    you_are_registered: 'You are registered',
    enter_an_amount: 'Enter an amount',
    insufficient_balance: 'Insufficient balance',
    allocation_exceeded: 'Allocation exceeded',
    you_send: 'You send',
    used_alloc: 'Used alloc.',
    you_receive: 'You receive',
    tokens_bought: 'Tokens bought',
    vested: 'Vested',
    available_claim: 'Available claim',
    claimed: 'Claimed',
    available_for_refund: 'Available for refund',
    switch_network_terminal: 'Please, switch the network in your wallet.',
    withdraw: 'Withdraw',

    // details tier
    get_your_tier: 'Get your Tier',
    connect_wallet_tier_text: 'Connect wallet to see your tier.',
    no_tier_requirements: 'No tier requirements',
    no_tier_requirements_text: `
      This is a public IDO that doesn’t require a tier.
      You can still benefit from staking {{symbol}}.
    `,
    missed_ido: `
      Looks like you missed this IDO.
      Make sure you have enough stake for the next one.
    `,
    tier_update_description: `
      Stake {{tokenAndSymbol}} and increase your chances
      for participation.
    `,
    highest_tier_text: 'You have the highest tier. Nice.',
    ido_canceled: `
      The IDO has not reached the softcap
      and has been canceled.
    `,

    // details timeline titles
    registration: 'Registration',
    whitelisting: 'Whitelisting',
    pre_launch: 'Pre-launch',
    sale: 'Sale',
    distribution: 'Distribution',

    // details social links
    links: 'Links',

    // details vesting
    date: 'Date',
    percentage: 'Percentage',
    total_unlock: 'Total Unlock',
    your_claim: 'Your Claim',
    your_unlock: 'Your Unlock',
    status: 'Status',
    vesting_claim_button_text: 'Claim all unlocked ',
    vesting_schedule: 'Vesting Schedule',

    // details participation guide
    how_to_participate: 'How to Participate',
    connect_wallet_description:
      'Connect your preferred wallet and set it to the correct network',
    get_verified: 'Get verified',
    get_verified_description:
      'Apply for KYC verification since most IDOs require it',
    confirm_residency: 'Confirm residency',
    confirm_residency_description:
      'Confirm that you are a resident of an eligible country',
    stake_token: 'Stake {{symbol}}',
    stake_token_description:
      'Get a participation tier by staking and earn staking rewards',
    register_for_an_ido: 'Register for an IDO',
    register_for_an_ido_description:
      'Confirm your interest in participation during registration stage',
    buy_token: 'Buy {{symbol}}',
    buy_token_description: `Don’t miss your chance to buy your maximum available allocation`,
    claim_token: 'Claim {{symbol}}',
    claim_token_description: 'Follow vesting schedule and claim your tokens',

    // details project details block
    project_details: 'Project Details',

    // staking page
    left_to_be_mined: 'Left to be mined',
    total_staked: 'Total Staked',
    mined_per_day: 'Mined per Day',
    staking_terminal: 'Staking Terminal',
    staking_terminal_text: `
      Stake {{stakingSymbol}} to participate in IDOs & earn
      {{rewardSymbol}}. Unstaking results in a
      {{unstakingFeeRatio}}% cast-out fee.
    `,
    staking_lm_terminal_text: `Stake {{liquidityTokenPrettyName}} liquidity tokens to earn {{rewardSymbol}}.`,
    approve_staking: 'Approve staking',
    you_stake: 'Stake',
    current_stake: 'Current stake',
    you_unstake: 'Unstake',
    available_for_claiming: 'Available for claiming',
    total_earned: 'Total earned',
    tiers: 'Tiers',
    about_tiers: 'About Tiers',
    participation: 'Participation',
    odds_multiplier: 'Odds Multiplier',
    pool_weight: 'Pool Weight',
    multiplier: 'Multiplier',
    symbol_price: '{{symbol}} Price',
    your_apy: 'Your APY',

    // dashboard page
    dashboard_trade: `
    Buy and Sell \${{rewardSymbol}} on the OccamX DEX, and other supported platforms.
    `,
    dashboard_burn: `
    The only way to withdraw assets from the Chakra Pool is by burning {{rewardSymbol}} tokens.
    `,

    // ced
    ced_rewards: 'CED Rewards',
  }
}
