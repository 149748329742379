export type SkeletType = 'staking' | 'stakingIcon' | 'chakra' | null | undefined

interface Props {
  skeletType: SkeletType
  withSub: boolean
}

export default function FieldSkeleton({ withSub, skeletType }: Props) {
  if (!skeletType) {
    return null
  }

  if (skeletType === 'staking') {
    return <SkeletStaking withSub={withSub} />
  }

  if (skeletType === 'stakingIcon') {
    return <SkeletStakingIcon />
  }

  if (skeletType === 'chakra') {
    return <SkeletChakra />
  }

  return null
}

const SkeletChakra = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 236 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMid"
    >
      <rect width="160" height="24" rx="6" fill="#C9D6E9" />
      <rect y="33" width="96" height="34" rx="8" fill="#C9D6E9" />
    </svg>
  )
}

const SkeletStaking = ({ withSub }: { withSub: boolean }) => {
  return (
    <svg
      viewBox="0 0 236 97"
      height="100%"
      width="100%"
      preserveAspectRatio="xMinYMin"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="124" height="24" rx="6" fill="#C9D6E9" />
      <rect y="32" width="160" height="32" rx="8" fill="#C9D6E9" />
      <rect x="168" y="44" width="35" height="20" rx="6" fill="#C9D6E9" />
      {withSub ? (
        <rect y="76" width="90" height="16" rx="5" fill="#C9D6E9" />
      ) : null}
    </svg>
  )
}

const SkeletStakingIcon = () => (
  <svg
    viewBox="0 0 243 52"
    height="100%"
    width="100%"
    preserveAspectRatio="xMinYMid"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <g clip-path="url(#clip0_6517_177811)">
      <rect width="46" height="46" rx="23" fill="#C9D6E9" />
    </g>
    <rect x="62" y="5" width="111" height="12" rx="3" fill="#C9D6E9" />
    <rect x="62" y="25" width="64" height="20" rx="5" fill="#C9D6E9" />
    <defs>
      <clipPath id="clip0_6517_177811">
        <rect width="46" height="46" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
