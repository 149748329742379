export default function StakingPriceSkelet2() {
  return (
    <svg
      width="100%"
      height="40"
      viewBox="0 0 135 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="relative w-full capitalize ml:w-[170px] dl:w-[135px] dxl:w-[170px]"
      preserveAspectRatio="none"
    >
      <rect width="135" height="40" rx="8" fill="#DDE4EE" />
    </svg>
  )
}
