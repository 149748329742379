import Button from 'components/dumb/Button'
import TextLink from 'components/dumb/TextLink'
import { useStore } from 'effector-react'
import { $kyc } from 'models/generalInfo'
import { openAboutKyc } from 'models/wallet'
import { ReactNode } from 'react-markdown/lib/react-markdown'
import { formatUTC } from 'utils/dayjs'
import { APPLY_FOR_KYC_LINK } from 'utils/links'
import t from 'utils/t'

export default function WalletKyc() {
  const kyc = useStore($kyc)

  let status: string
  let description: string
  let button: ReactNode = null

  switch (kyc.status) {
    case 'verified':
      status = t('verified')
      description = t('verified_description')
      break
    case 'rejectedFinal':
      status = t('rejected')
      description = t('rejected_description')
      break
    case 'rejectedRetry':
      status = t('resubmission_requested')
      description = t('resubmission_requested_description')
      button = <Button>{t('finalize_kyc')}</Button>
      break
    case 'notVerified':
    default:
      status = t('not_verified')
      description = t('not_verified_description')

      // const applyOptions = [
      //   { text: t('regular_kyc') },
      //   { text: t('us_accredited_investor') },
      //   { text: t('venture_capital'), disabled: true },
      // ]
      button = (
        <a target="_blank" href={APPLY_FOR_KYC_LINK} rel="noreferrer">
          <Button>{t('apply_for_kyc')}</Button>
        </a>
      )
      break
  }

  const lastSyncLabel = `(Last sync ${formatUTC(kyc.lastSyncTime)})`
  description = `${description} ${lastSyncLabel}`

  return (
    <div>
      <div className="mt-4 w-full border-t border-grayDA" />
      <div className="mt-4 text-boulder">{t('your_kyc_status')}</div>
      <div className="text-[1.375rem] text-codGray">{status}</div>
      <div className="mt-1 text-sm text-boulder">{description}</div>
      <div className="my-4">{button}</div>
      <TextLink onClick={() => openAboutKyc()}>About KYC</TextLink>
    </div>
  )
}
