import classNames from 'classnames'

export interface ProgressLineProps {
  percent: string
  totalTarget: string
  targetTokenSymbol: string
  softCapPercent: string
  softCap?: string | null
  appearance?: 'card' | 'projectDetails'
}

export default function ProgressLine({
  totalTarget,
  targetTokenSymbol,
  softCapPercent,
  softCap,
  percent,
  appearance = 'projectDetails',
}: ProgressLineProps) {
  return (
    <div className="col-span-auto grid gap-y-4 mm:col-span-2">
      <div className="col-span-auto flex space-x-[25px] mm:col-span-2 mm:space-x-[50px]">
        <ProgressLineField
          title="Target"
          value={totalTarget}
          symbol={targetTokenSymbol}
          appearance={appearance}
        />
        <ProgressLineField
          title="SoftCap"
          value={softCap}
          symbol={targetTokenSymbol}
          appearance={appearance}
          secondary
        />
      </div>
      <div className="relative col-span-2 w-full">
        <TheLine percent={percent} />
        <SoftCapLabel softCapPercent={softCapPercent} />
      </div>
    </div>
  )
}

const ProgressLineField = ({
  title,
  value,
  symbol,
  secondary = false,
  appearance,
}: {
  title: string
  symbol: string
  appearance: 'card' | 'projectDetails'
  value?: string | null
  secondary?: boolean
}) => {
  const isProjectDetails = appearance === 'projectDetails'
  const isActivePoolCard = appearance === 'card'

  return (
    <div
      className={classNames(
        isProjectDetails
          ? {
              'text-brightGray': secondary,
              'text-primary': !secondary,
            }
          : 'text-brightGray'
      )}
    >
      <div
        className={classNames('leading-[140%]', {
          'text-[20px]': isActivePoolCard,
          'text-2xl ds:text-xl dm:text-2xl': isProjectDetails,
        })}
      >
        {title}
      </div>
      <div className="flex items-baseline space-x-[6px]">
        <span
          className={classNames('relative leading-[130%]', {
            'text-[39px]': isProjectDetails,
            'text-[30px] text-primary': isActivePoolCard,
          })}
        >
          {value}
        </span>
        <span
          className={classNames('uppercase leading-[130%]', {
            'text-[24px] font-light': isProjectDetails,
            'text-[20px] text-primary': isActivePoolCard,
          })}
        >
          {symbol}
        </span>
      </div>
    </div>
  )
}

const TheLine = ({ percent }: { percent: string }) => {
  return (
    <div className="absolute bottom-0 left-0 h-[3px] w-full rounded-line bg-grayDA">
      <div
        className={`transition-width h-full rounded-line rounded-br-line bg-primary duration-500 ${
          percent !== '100' ? 'rounded-tr-base' : ''
        }`}
        style={{ width: `${percent}%` }}
      ></div>
    </div>
  )
}

const SoftCapLabel = ({ softCapPercent }: { softCapPercent: string }) => {
  return (
    <div
      className="relative bottom-0 top-[2px] "
      style={{
        left: `${softCapPercent}%`,
      }}
    >
      <div className="absolute left-0 h-[10px] w-[2px] rounded-line bg-grayDA" />
      <span className="absolute left-2 top-1 text-xs text-boulder">
        SoftCap
      </span>
    </div>
  )
}
