import { ReactComponent as ChevronIcon } from 'resources/images/icons/chevron-right.svg'
import Block from './Block'

export type Props = {
  items: TimelineItemProps[]
}

export default function ProjectPageTimeline({ items }: Props) {
  return (
    <Block
      wrapperClassName="mb-12 z-10"
      containerClassName="flex flex-col ml:flex-row ml:items-center ml:justify-between ml:py-0"
    >
      {items.map((item) => {
        return <TimelineItem key={`timeline-item-${item.title}`} {...item} />
      })}
    </Block>
  )
}

type TimelineItemProps = {
  title: string
  date: string
  isActive: boolean
  isLast: boolean
}

export function TimelineItem({
  title,
  date,
  isActive,
  isLast,
}: TimelineItemProps) {
  return (
    <div className="relative flex h-full flex-col justify-between last:mr-0 ml:mr-[2.1rem] ml:max-w-[33%] ml:grow ml:flex-row ml:items-center ml:py-[1rem]">
      {isActive && (
        <div className="absolute left-0 top-0 hidden h-[0.15rem] w-[85%] bg-greenLight ml:block"></div>
      )}
      <div>
        <div className="mb-2 text-lg text-codGray">{title}</div>
        <div className="text-sm text-friarGray">{date}</div>
      </div>
      {!isLast && <ChevronIcon className="my-2 h-4 w-4 ml:my-0 ml:rotate-0" />}
    </div>
  )
}
