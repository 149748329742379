import Block from 'components/dumb/Block'
import SmartWalletButton from 'components/smart/SmartWalletButton/Button'
import Decimal from 'decimal.js'
import { useStore } from 'effector-react'
import { PoolPipeline, PoolStatus, Vesting } from 'gql'
import {
  $kyc,
  $kycInfo,
  $residenceConfirmed,
  setResidenceConfirmed,
} from 'models/generalInfo'
import {
  $registerCampaignModalStatus,
  refundFx,
  setRegisterCampaignModalStatus,
  withdrawFx,
} from 'models/pools'
import { telegramBotBaseURL } from 'models/pools/nodesale'
import { Pool } from 'models/pools/pools'
import { $staking } from 'models/staking'
import { ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as InfoIcon } from 'resources/images/icons/info.svg'
import { ReactComponent as RejectIcon } from 'resources/images/icons/reject.svg'
import { ReactComponent as SuccessIcon } from 'resources/images/icons/success.svg'
import { ReactComponent as WarningIconSvg } from 'resources/images/icons/warning.svg'
import { ReactComponent as WatchIcon } from 'resources/images/icons/watch.svg'
import dayjs, { formatUTC } from 'utils/dayjs'
import { APPLY_FOR_KYC_LINK } from 'utils/links'
import { formatNumber } from 'utils/numbers'
import t from 'utils/t'

import Button from '../dumb/Button'
import CurrencyInputPanel from '../dumb/CurrencyInputPanel'
import Timer from '../dumb/Timer'
import useCountdownTimeAndTitle from './useCountdownTimeAndTitleCampaign'

export type Props = {
  pool: Pool
  poolStatus: PoolStatus
  connected: boolean
  finished: boolean
  pipeline: PoolPipeline
  vesting?: Vesting
  idoTokenSymbol?: string
  targetTokenSymbol?: string
  tokensHasBeenDistributed: boolean
  availableToRefund: string
  isPrivate: boolean
  isKYCRequired: boolean
  isAssetManager: boolean
  isRegistered?: boolean
  wave?: number | null
}

export default function CampaignPageTerminalMaster({
  pool,
  poolStatus,
  connected,
  finished,
  pipeline,
  vesting,
  isAssetManager,
  targetTokenSymbol,
  idoTokenSymbol,
  availableToRefund,
  isPrivate,
  isKYCRequired,
  isRegistered,
  tokensHasBeenDistributed,
  wave,
}: Props) {
  const residenceConfirmed = useStore($residenceConfirmed)
  const kyc = useStore($kyc)
  const staking = useStore($staking)

  const hasTier = staking.level !== 0

  const viaAirdrop = pool.viaAirdrop
  const { countdownTime, countdownTitle } = useCountdownTimeAndTitle({
    vesting,
    pipeline,
    poolStatus,
    isPrivate,
  })

  if (!connected) {
    return (
      <TerminalWrapper
        subtitle={
          tokensHasBeenDistributed && poolStatus !== PoolStatus.Failed
            ? viaAirdrop
              ? 'Vesting ended. Tokens will be airdropped.'
              : 'Tokens are fully vested.'
            : ''
        }
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
      >
        <div>
          <div className="flex flex-col items-center justify-between ml:flex-row"></div>
          <SmartWalletButton className="m-auto w-full text-center" />
        </div>
      </TerminalWrapper>
    )
  }

  if (isAssetManager && pool.finished) {
    return (
      <TerminalAssetManager pool={pool} targetTokenSymbol={targetTokenSymbol} />
    )
  }

  if (
    poolStatus === PoolStatus.SuccessfullyFinished ||
    poolStatus === PoolStatus.SoldOut
  ) {
    return <TerminalClaim isRegistered={isRegistered} />
  }

  if (poolStatus === PoolStatus.Failed) {
    return <TerminalRefund pool={pool} availableToRefund={availableToRefund} />
  }

  if (
    isPrivate &&
    !hasTier &&
    (poolStatus === PoolStatus.ComingSoon ||
      poolStatus === PoolStatus.Registration)
  ) {
    return (
      <TerminalStaking
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
        stakingSymbol={staking.stakingSymbol}
      />
    )
  }

  if (isKYCRequired && kyc.status !== 'verified') {
    return (
      <TerminalKYC
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
        kycStatus={kyc.status}
      />
    )
  }

  if (!isKYCRequired && !residenceConfirmed && !finished) {
    return (
      <TerminalResidence
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
      />
    )
  }

  if (poolStatus === PoolStatus.ComingSoon) {
    return (
      <TerminalComingSoon
        isPrivate={isPrivate}
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
      />
    )
  }

  if (poolStatus === PoolStatus.Registration) {
    return (
      <TerminalRegistration
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
        isRegistered={isRegistered}
      />
    )
  }

  if (isPrivate && !isRegistered) {
    return (
      <TerminalNotRegistered
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
        poolStatus={poolStatus}
      />
    )
  }

  if (
    poolStatus === PoolStatus.Prelaunch ||
    poolStatus === PoolStatus.Whitelisting
  ) {
    return (
      <TerminalPrelaunch
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
        isPrivate={isPrivate}
        wave={wave}
      />
    )
  }

  if (poolStatus === PoolStatus.InProgress) {
    return (
      <TerminalSale
        pool={pool}
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
        idoTokenSymbol={idoTokenSymbol}
        targetTokenSymbol={targetTokenSymbol}
      />
    )
  }

  return null
}

export type TerminalStateProps = {
  pool?: Pool | null
  subtitle?: string
  countdownTime?: string | null
  countdownTitle?: string | null
  idoTokensBoughtDec?: Decimal
  idoTokenSymbol?: string
  targetTokenSymbol?: string
  vestingTotalAvailable?: string
  userToReserve?: string
  claimed?: string
  vested?: string
  allowedToClaim?: boolean
  privateAndNonParticipating?: boolean
  availableToRefund?: string
  isPrivate?: boolean
  kycStatus?: string
  stakingSymbol?: string
  isRegistered?: boolean
  hasTier?: boolean
  poolStatus?: string
  tokensHasBeenDistributed?: boolean
  wave?: number | null
}

function TerminalKYC({
  countdownTime,
  countdownTitle,
  kycStatus,
}: TerminalStateProps) {
  const kycInfo = useStore($kycInfo)
  const isRejectedFinal = kycStatus === 'rejectedFinal'

  return (
    <TerminalWrapper
      countdownTime={countdownTime}
      countdownTitle={countdownTitle}
    >
      {isRejectedFinal ? (
        <KYCRejectedFinal />
      ) : (
        <>
          <div className="mb-5 flex justify-start">
            <WarningIcon />
            <div className="text-sm text-friarGray">
              {t('requires_kyc_text')}
            </div>
          </div>
          {kycStatus === 'notVerified' && (
            <a target="_blank" href={APPLY_FOR_KYC_LINK} rel="noreferrer">
              <Button className="w-full">{t('apply_for_kyc')}</Button>
            </a>
          )}
          {kycStatus === 'rejectedRetry' && (
            <Button
              className="w-full uppercase"
              onClick={() => {
                window.open(
                  kycInfo?.account?.resubmitLink,
                  '_blank',
                  'noopener,noreferrer'
                )
              }}
            >
              {t('finalize_kyc')}
            </Button>
          )}
        </>
      )}
    </TerminalWrapper>
  )
}

function TerminalStaking({
  countdownTime,
  countdownTitle,
  stakingSymbol,
  subtitle,
}: TerminalStateProps) {
  const navigate = useNavigate()

  return (
    <TerminalWrapper
      subtitle={subtitle}
      countdownTime={countdownTime}
      countdownTitle={countdownTitle}
    >
      <div className="mb-5 flex justify-start">
        <WarningIcon />
        <div className="text-sm text-friarGray">
          {t('insufficient_token_stake', { symbol: stakingSymbol })}
        </div>
      </div>
      <Button className="w-full uppercase" onClick={() => navigate('/staking')}>
        {t('go_to_staking')}
      </Button>
    </TerminalWrapper>
  )
}

function TerminalResidence({
  countdownTime,
  countdownTitle,
}: TerminalStateProps) {
  const [checked, setChecked] = useState(false)

  const handleToggle = () => {
    setChecked(!checked)
  }

  const handleButtonClick = () => {
    setResidenceConfirmed(true)
  }

  return (
    <TerminalWrapper
      countdownTime={countdownTime}
      countdownTitle={countdownTitle}
    >
      <div>
        <div className="mb-5 flex">
          <input
            onClick={handleToggle}
            type="checkbox"
            id="res"
            className={`
                      relative mr-[0.66rem] h-[1.125rem] w-[1.125rem] cursor-pointer appearance-none rounded-none border border-quillGray bg-white
                      after:absolute after:left-[0.1rem] after:top-[0.1rem] after:hidden after:h-[0.75rem] after:w-[0.75rem] after:bg-checkboxCheck after:bg-cover checked:after:block
                    `}
          />
          <label
            htmlFor="res"
            className="relative top-[-0.1rem] w-full cursor-pointer text-sm leading-5 text-friarGray"
          >
            {t('resident_text')}
          </label>
        </div>
        <Button
          className="w-full uppercase"
          disabled={!checked}
          onClick={handleButtonClick}
        >
          {t('proceed')}
        </Button>
      </div>
    </TerminalWrapper>
  )
}

function TerminalComingSoon({
  countdownTime,
  countdownTitle,
  isPrivate,
}: TerminalStateProps) {
  return (
    <>
      {!isPrivate ? (
        <TerminalWrapper
          countdownTime={countdownTime}
          countdownTitle={countdownTitle}
        >
          <PrivateAndNonParticipatingTip />
          <div>
            <Button className="w-full uppercase" disabled>
              {t('register')}
            </Button>
          </div>
        </TerminalWrapper>
      ) : (
        <TerminalWrapper
          countdownTime={countdownTime}
          countdownTitle={countdownTitle}
        >
          <PrivateAndNonParticipatingTip />
          <div>
            <Button className="w-full uppercase" disabled>
              {t('register')}
            </Button>
          </div>
        </TerminalWrapper>
      )}
    </>
  )
}

function TerminalRegistration({
  countdownTime,
  countdownTitle,
  isRegistered,
}: TerminalStateProps) {
  const registerModalStatus = useStore($registerCampaignModalStatus)
  if (
    isRegistered &&
    registerModalStatus !== '' &&
    registerModalStatus !== 'registered'
  ) {
    setRegisterCampaignModalStatus('registered')
  }

  const handleClick = () => {
    setRegisterCampaignModalStatus('open')
  }

  return (
    <TerminalWrapper
      countdownTime={countdownTime}
      countdownTitle={countdownTitle}
    >
      {!isRegistered ? (
        <PrivateAndNonParticipatingTip />
      ) : (
        <div className="mb-[1rem] flex justify-start">
          <SuccessIcon className="relative top-[0.1rem] mr-2 h-[0.87rem] w-[0.87rem]" />
          <div className="text-sm text-friarGray">
            {t('you_are_registered')}
          </div>
        </div>
      )}

      <div>
        <Button
          className="w-full uppercase"
          disabled={isRegistered}
          onClick={handleClick}
        >
          {isRegistered ? 'Join sale in Telegram' : 'Register'}
        </Button>
      </div>
    </TerminalWrapper>
  )
}

function TerminalNotRegistered({
  countdownTime,
  countdownTitle,
  poolStatus,
}: TerminalStateProps) {
  if (
    poolStatus === PoolStatus.Failed ||
    poolStatus === PoolStatus.SoldOut ||
    poolStatus === PoolStatus.SuccessfullyFinished
  ) {
    return (
      <TerminalWrapper
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
      >
        <PrivateAndNonParticipatingTip />
      </TerminalWrapper>
    )
  }
  return (
    <TerminalWrapper
      countdownTime={countdownTime}
      countdownTitle={countdownTitle}
    >
      <NotEligibleTip />
      {/* <div className="mb-[1rem]">
        <Button
          className="w-full uppercase"
          onClick={() => {
            setRegisterModalStatus('open')
          }}
        >
          Register
        </Button>
      </div> */}
    </TerminalWrapper>
  )
}

function TerminalPrelaunch({
  countdownTime,
  countdownTitle,
  isPrivate,
  wave,
}: TerminalStateProps) {
  return (
    <TerminalWrapper
      countdownTime={countdownTime}
      countdownTitle={countdownTitle}
    >
      {isPrivate && (
        <div className="mb-[1rem] flex">
          <WatchIcon className="relative top-[0.13rem] mr-2 h-[0.87rem] w-[0.87rem]" />

          <div className="text-sm text-friarGray">
            {wave !== null
              ? 'You are successfully registered to participate in the sale. Stay tuned and keep an eye on your Telegram – you will receive a message with link to join the sale.'
              : 'Whitelisting results coming soon'}
          </div>
        </div>
      )}
      <div>
        <Button className="w-full uppercase" disabled>
          Join sale in Telegram
        </Button>
      </div>
    </TerminalWrapper>
  )
}

function TerminalSale({ countdownTime, countdownTitle }: TerminalStateProps) {
  return (
    <TerminalWrapper
      countdownTime={countdownTime}
      countdownTitle={countdownTitle}
    >
      <Button
        className="w-full uppercase"
        onClick={() => window.open(telegramBotBaseURL)}
      >
        Join sale in Telegram
      </Button>
    </TerminalWrapper>
  )
}

function TerminalClaim({
  isRegistered,
}: Pick<TerminalStateProps, 'isRegistered'>) {
  const subtitle = isRegistered
    ? 'Nodes will be distributed via airdrop. Please, wait for further announcements in the telegram bot.'
    : ''

  const content = isRegistered ? (
    <Button
      className="w-full uppercase"
      onClick={() => window.open('https://t.me/BluwhaleNodesaleBot')}
    >
      Open in Telegram
    </Button>
  ) : (
    <PrivateAndNonParticipatingTip />
  )

  return <TerminalWrapper subtitle={subtitle}>{content}</TerminalWrapper>
}

function TerminalRefund({
  pool,
  targetTokenSymbol,
  availableToRefund,
}: TerminalStateProps) {
  const refund = () => {
    refundFx(pool!)
  }
  return (
    <TerminalWrapper subtitle={t('ido_failed')}>
      <CurrencyInputPanel
        disabled
        label={t('available_for_refund')}
        symbol={targetTokenSymbol}
        value={availableToRefund!}
      />
      <Button
        className="mt-5 w-full uppercase"
        onClick={refund}
        disabled={availableToRefund === '0'}
      >
        {t('refund')}
      </Button>
    </TerminalWrapper>
  )
}

export type TerminalWrapperProps = {
  children: ReactNode
  countdownTime?: string | null
  countdownTitle?: string | null
  title?: string
  subtitle?: string
}

function TerminalWrapper({
  children,
  countdownTime,
  countdownTitle,
  title,
  subtitle,
}: TerminalWrapperProps) {
  return (
    <Block
      wrapperClassName="h-full min-h-[345px]"
      containerClassName="!px-5 flex flex-col h-full justify-between"
    >
      <div>
        <div className="mb-2 text-2xl font-light leading-8">
          {title ? title : t('participation_terminal')}
        </div>
        {subtitle ? (
          <div className="font-light text-gray">{subtitle}</div>
        ) : null}
        <Timer
          endTime={countdownTime}
          text={countdownTitle}
          textClassName="!text-base"
          countdownClassName="!text-[72px] !ml-0"
          containerClassName="flex-col !items-start"
        />
      </div>
      <div>{children}</div>
    </Block>
  )
}

function NotEligibleTip() {
  return (
    <div className="mb-5 flex">
      <RejectIcon className="relative top-[0.1rem] mr-2 h-[0.8rem] w-[0.8rem] shrink-0" />
      <div className="text-sm text-friarGray">
        {t('nodesale_not_eligible_tip', {
          link: (
            <a
              href="https://ct-com.gitbook.io/ct.com-faq"
              target="_blank"
              className="underline hover:no-underline"
              rel="noreferrer"
            >
              {t('nodesale_process')}
            </a>
          ),
        })}
      </div>
    </div>
  )
}

function TerminalAssetManager({ pool, targetTokenSymbol }: TerminalStateProps) {
  const totalRaised = pool?.original.progress?.totalRaised ?? 0

  const timer = pool?.original?.vesting?.unlockCheckpoints?.[0]

  const vestingStartTime = formatUTC(timer)

  const buttonDisabled = timer ? dayjs.utc().isBefore(dayjs.utc(timer)) : true

  const handleClick = () => {
    withdrawFx({ pool: pool! })
  }

  return (
    <TerminalWrapper
      countdownTime={vestingStartTime}
      countdownTitle="Funds unlock in"
    >
      <div>
        <CurrencyInputPanel
          disabled
          label="Funds raised"
          symbol={targetTokenSymbol}
          value={formatNumber(totalRaised, { fractionDigits: 4 })}
        />
        <Button
          className="mt-5 w-full uppercase"
          disabled={buttonDisabled}
          onClick={handleClick}
        >
          {t('withdraw')}
        </Button>
      </div>
    </TerminalWrapper>
  )
}

function KYCRejectedFinal() {
  return (
    <div className="mb-5 flex justify-center">
      <RejectIcon className="relative top-[0.1rem] mr-2 h-4 w-4" />
      <div className="text-sm text-friarGray">
        {t('kyc_rejected_final_tip')}
      </div>
    </div>
  )
}

function PrivateAndNonParticipatingTip() {
  const { stakingSymbol } = useStore($staking)

  return (
    <div className="mb-5 flex">
      <InfoIcon className="relative top-[0.1rem] mr-2  h-4 w-4 grow" />
      <div className="grow text-sm text-friarGray">
        {t('nodesale_private_and_non_participating_tip', {
          symbol: stakingSymbol,
          link: (
            <a
              href="https://ct-com.gitbook.io/ct.com-faq"
              target="_blank"
              className="underline hover:no-underline"
              rel="noreferrer"
            >
              {t('nodesale_process')}
            </a>
          ),
        })}
      </div>
    </div>
  )
}

function WarningIcon() {
  return <WarningIconSvg className="relative top-[0.1rem] mr-2 h-4 w-4" />
}
