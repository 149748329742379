import classNames from 'classnames'
import { ReactNode } from 'react'
import { noop } from 'utils'

interface GhostButtonProps {
  onClick?(): void
  children?: ReactNode
  className?: string
  selected?: boolean
  disabled?: boolean
}
export default function GhostButton({
  children,
  onClick = noop,
  className = '',
  selected = false,
  disabled = false,
}: GhostButtonProps) {
  const base = classNames(
    className,
    `cursor-pointer rounded-[0.25rem] border pt-[0.09rem]
     px-[6px] text-sm leading-[140%]  outline-none 
     hover:border-primaryHover hover:text-primaryHover hover:bg-geyser/40
     active:border-primaryActive2 active:text-primaryActive2
  disabled:bg-athensGray disabled:pointer-events-none disabled:text-gray disabled:border-gray
`
  )
  const normal = 'bg-geyser/40 text-primary border-primary'
  const sel = 'bg-primary border-white text-white'

  return (
    <button
      onClick={onClick}
      className={classNames(base, {
        [sel]: selected,
        [normal]: !selected,
      })}
      disabled={disabled}
    >
      {children}
    </button>
  )
}
