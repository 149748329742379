import Decimal from 'decimal.js'
import { ChakraInfoQuery } from 'gql'
import numeral from 'numeral'
import { toDecimal } from 'utils/numbers'

type ChakraToken = ChakraInfoQuery['stakingInfoCHAKRA']['rewardToken']

export interface ChakraTierMapped {
  index: number
  name: string
  minStake: string
  apy: string
  tokenEarningsPerYear: string
}

export class ChakraInfo {
  chakraSymbol: string
  chakraEarned: string
  chakraAvailable: string
  chakraPending: string
  stakingSymbol: string
  hasTier: boolean
  tiers: ChakraTierMapped[]
  rewardTokenTotalSupply: string
  chakraToken?: ChakraToken
  stakingBalanceDec: Decimal
  chakraApyDec: Decimal
  tokensEarningPerYear: string

  constructor(raw: ChakraInfoQuery | null) {
    this.chakraSymbol = raw?.stakingInfoCHAKRA?.rewardToken.symbol ?? ''
    this.chakraEarned = raw?.stakingInfoCHAKRA?.account?.rewardTotal ?? '0'
    this.chakraAvailable =
      raw?.stakingInfoCHAKRA?.account?.rewardAvailable ?? '0'
    this.chakraPending = raw?.stakingInfoCHAKRA?.account?.rewardPending ?? '0'
    this.chakraToken = raw?.stakingInfoCHAKRA?.rewardToken
    this.stakingSymbol = raw?.stakingInfoCHAKRA?.stakingToken?.symbol ?? ''

    this.rewardTokenTotalSupply = numeral(
      raw?.stakingInfoCHAKRA?.rewardToken?.totalSupply ?? '0'
    ).format('0,00')

    this.hasTier =
      typeof raw?.stakingInfoMultichain?.account?.tier?.level === 'number'

    const tiers = raw?.stakingInfoMultichain?.tiers ?? []
    this.tiers =
      tiers.map((tier, i) => ({
        index: tier.index,
        name: tier.name,
        minStake: tier.minStake,
        apy: raw?.apy?.apyPerTier?.[i].apy ?? '0',
        tokenEarningsPerYear:
          raw?.apy?.apyPerTier?.[i].tokensEarningPerYear ?? '0',
      })) ?? []

    this.stakingBalanceDec = toDecimal(
      raw?.stakingInfoMultichain?.account?.stakingBalance ?? '0'
    )
    this.chakraApyDec = toDecimal(raw?.apy?.accountAPY)
    this.tokensEarningPerYear = raw?.apy?.tokensEarningPerYear ?? '0'
  }
}
