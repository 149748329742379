import TextLink from 'components/dumb/TextLink'
import { useStore } from 'effector-react'
import { Tx } from 'models/transactions'
import { $provider, $selectedChainId } from 'models/wallet'
import { useEffect, useState } from 'react'
import toast, { Toast } from 'react-hot-toast'
import { ReactComponent as CloseIcon } from 'resources/images/icons/close.svg'
import { ReactComponent as ConfirmedIcon } from 'resources/images/icons/tx-confirmed.svg'
import { ReactComponent as FailedIcon } from 'resources/images/icons/tx-failed.svg'
import { ReactComponent as PendingIcon } from 'resources/images/icons/tx-pending.svg'
import { ReactComponent as SubmittedIcon } from 'resources/images/icons/tx-submitted.svg'
import invariant from 'tiny-invariant'
import { getTxExplorerUrl } from 'utils'
import { ETHEREUM_MAINNET_CHAIN_ID } from 'utils/constants'
import tr from 'utils/t'

type Props = {
  t: Toast
  tx: Tx
}

export default function TxToast({ t, tx }: Props) {
  const [status, setStatus] = useState(tx.status)
  const provider = useStore($provider)
  const selectedChainId = useStore($selectedChainId)

  useEffect(() => {
    const getTxStatus = async (hash: string) => {
      invariant(provider, 'web3 provider not found')

      if (status === 'confirmed' || status === 'failed') return

      const receipt = await provider.getTransactionReceipt(hash)
      if (receipt) {
        const updatedStatus = receipt.status === 1 ? 'confirmed' : 'failed'
        setStatus(updatedStatus)
        setTimeout(() => toast.dismiss(t.id), 4000)
      }
    }

    const interval = setInterval(() => getTxStatus(tx.hash), 2000)

    return () => clearInterval(interval)
  }, [tx.hash, status, t])

  let label = ''
  let text = ''
  let icon = null
  switch (status) {
    case 'confirmed':
      icon = <ConfirmedIcon className="h-5 w-5" />
      label = tr('transaction_successful')
      text = tr('transaction_successful_text')
      break
    case 'failed':
      icon = <FailedIcon className="h-5 w-5" />
      label = tr('transaction_failed')
      text = tr('error_modals_text')
      break
    case 'submitted':
      label = tr('transaction_submitted')
      text = tr('transaction_submitted_text')
      icon = <SubmittedIcon className="h-5 w-5" />
      break
    default:
      label = tr('transaction_pending')
      text = tr('transaction_pending_text')
      icon = <PendingIcon className="h-5 w-5 animate-spin" />
      break
  }

  return (
    <div
      className={`${
        t.visible ? 'animate-enter' : 'animate-leave'
      } toast-shadow pointer-events-auto flex w-full max-w-md rounded-[10px] border border-white bg-porcelain`}
    >
      <div className="w-0 flex-1 p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0 pt-0.5">{icon}</div>
          <div className="ml-3 flex-1">
            <p className="text-sm font-semibold text-codGray">{label}</p>
            <p className="mt-1 text-sm font-light text-friarGray">{text}</p>
            <TextLink
              className="text-sm"
              href={getTxExplorerUrl(
                tx.hash,
                selectedChainId ?? ETHEREUM_MAINNET_CHAIN_ID
              )}
            >
              {tr('view_transaction_short')}
            </TextLink>
          </div>
        </div>
      </div>

      <CloseIcon
        className="m-4 h-4 w-4 cursor-pointer fill-current text-primary"
        onClick={() => {
          toast.dismiss(t.id)
        }}
      />
    </div>
  )
}
