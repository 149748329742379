import { attach, createEvent, createStore, restore } from 'effector'
import { ethers } from 'ethers'
import invariant from 'tiny-invariant'
import { shortenAddress } from 'utils'
import { ETHEREUM_MAINNET_CHAIN_ID, networks } from 'utils/constants'
import { toDecimal } from 'utils/numbers'
import { ProviderId } from './wallets'
import { persist } from 'effector-storage/local'
import { getChainId } from 'utils/blockchain'

export type Wallet = {
  id: ProviderId
  state: 'connected' | 'notConnected' | 'connecting' | 'error'
} | null

export const logout = createEvent()
export const openWallet = createEvent()
export const closeWallet = createEvent()
export const setWallet = createEvent<Wallet>()
export const connectProvider = createEvent<ProviderId>()
export const setNetworkIsOk = createEvent<boolean>()
export const setAddress = createEvent<string>()
export const openAboutKyc = createEvent()
export const setOpenCedRewards = createEvent<boolean>()
export const closeAboutKyc = createEvent()
export const setSelectedChainId = createEvent<string>()
export const setUserSelectedChainId = createEvent<string>()
export const setProvider = createEvent<ethers.providers.Web3Provider | null>()

export const $provider = restore(setProvider, null)
export const $targetTokenBalance = createStore(toDecimal(0))
export const $address = restore(setAddress, '')
export const $showWallet = createStore(false)
export const $showAboutKyc = createStore(false)
export const $shortAddress = $address.map(shortenAddress)
export const $showCedRewards = restore(setOpenCedRewards, false)
export const $selectedChainId = restore(setSelectedChainId, getChainId('bsc'))
export const $userSelectedChainId = restore(
  setUserSelectedChainId,
  getChainId('bsc')
)

export const $wallet = restore(setWallet, null)
persist({ store: $wallet, key: 'wallet' })
persist({ store: $selectedChainId, key: 'selectedChainId' })

export const deactivateFx = attach({
  source: $provider,
  async effect(provider) {
    if (provider) {
      // @TODO: WC
      //localStorage.clear()
    }
  },
})

export const switchNetworkFx = attach({
  source: $provider,
  async effect(provider, chainId: string) {
    await provider?.provider.request?.({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId }],
    })
  },
})

export const addNetworkFx = attach({
  source: $provider,
  async effect(provider, chainId: string) {
    const info = networks[chainId]
    if (chainId === ETHEREUM_MAINNET_CHAIN_ID) return

    await provider?.provider?.request?.({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId,
          chainName: info.label,
          rpcUrls: info.rpcUrls,
          nativeCurrency: info.nativeCurrency,
          blockExplorerUrls: [info.explorer],
        },
      ],
    })
  },
})

export const fetchTargetTokenBalanceFx = attach({
  source: [$address, $provider],
  async effect([address, provider]) {
    invariant(provider, 'ethereum provider not found')

    const balance = await provider.getBalance(address)
    return toDecimal(balance._hex).div(1e18)

    // const abi = [
    //   'function balanceOf(address _owner) public view returns (uint256 balance)',
    // ]

    // const targetTokenAddress = '0x2F109021aFe75B949429fe30523Ee7C0D5B27207' // todo OCC
    // const targetTokenDecimal = 18
    // const contract = new ethers.Contract(targetTokenAddress, abi, provider)
    // const balance = await contract.balanceOf(address)
    // return new Decimal(balance?._hex ?? 0).div(
    //   new Decimal(10).pow(targetTokenDecimal)
    // )
  },
})
