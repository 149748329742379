export default function StakingTerminalSkeleton() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 450 289"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute"
      preserveAspectRatio="none"
    >
      <rect width="177" height="26" rx="6" fill="#DDE4EE" />
      <rect x="266" width="52" height="26" rx="6" fill="#DDE4EE" />
      <rect x="326" width="64" height="26" rx="6" fill="#DDE4EE" />
      <rect x="398" width="52" height="26" rx="6" fill="#DDE4EE" />
      <rect y="44" width="336" height="12" rx="3" fill="#DDE4EE" />
      <rect y="68" width="237" height="12" rx="3" fill="#DDE4EE" />
      <rect y="119" width="59" height="12" rx="3" fill="#DDE4EE" />
      <rect x="356" y="119" width="94" height="12" rx="3" fill="#DDE4EE" />
      <rect y="143" width="450" height="54" rx="6" fill="#DDE4EE" />
      <rect y="209" width="110" height="12" rx="3" fill="#DDE4EE" />
      <rect x="302" y="209" width="148" height="12" rx="3" fill="#DDE4EE" />
      <rect y="249" width="450" height="40" rx="8" fill="#DDE4EE" />
    </svg>
  )
}
