import detectEthereumProvider from '@metamask/detect-provider'
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import { desiredNetwork } from './constants'

export async function getMetamaskProvider() {
  return await detectEthereumProvider()
}

export async function getWalletConnectProvider() {
  return await EthereumProvider.init({
    projectId:
      process.env.REACT_APP_PROJECT_ID ?? 'ae7e6b84bacee8cddd1692f2096c9a35',
    chains: [1, 56], // eth, bnb
    showQrModal: true,
    metadata: {
      name: 'Occam Razer',
      description: 'Smart DeFi Layer',
      url:
        desiredNetwork === 'testnet'
          ? 'https://stage.razer.occam.fi/'
          : 'https://razer.occam.fi', //'https://razer.occam.fi/',
      icons: ['https://s2.coinmarketcap.com/static/img/coins/200x200/9191.png'],
    },
  })
}
