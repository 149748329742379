import TextLink from 'components/dumb/TextLink'
import { useStore } from 'effector-react'
import { $staking } from 'models/staking'
import { $address, closeWallet } from 'models/wallet'
import numeral from 'numeral'
import { useNavigate } from 'react-router-dom'
import t from 'utils/t'
import { getNftUrl } from 'utils/tier'

export default function WalletTier() {
  const navigate = useNavigate()
  const { hasTier, stakingSymbol, stakingBalance, tierName } =
    useStore($staking)
  const address = useStore($address)
  const url = getNftUrl(address)
  const balance = numeral(stakingBalance).format('0,[.][0000]')

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <div className="flex flex-col">
            <div className="text-base text-boulder">{t('your_tier')}</div>
            <div className="text-[1.375rem] leading-8 text-codGray">
              {tierName}
            </div>
          </div>
          <div className="mt-1">
            <div className="text-base text-boulder">{t('your_stake')}</div>
            <div className="text-[1.375rem] leading-8 text-codGray">
              {balance} {stakingSymbol}
            </div>
          </div>
        </div>
        {hasTier && (
          <div>
            <img className="h-[100px] w-[100px]" src={url} alt="" />
          </div>
        )}
      </div>
      <div className="mb-3 mt-4 flex items-center space-x-4">
        <TextLink
          onClick={() => {
            navigate('/staking')
            closeWallet()
          }}
        >
          {t('go_to_staking')}
        </TextLink>
      </div>
    </div>
  )
}
