import Field, { FieldProps } from 'components/dumb/Field'
import { LM } from 'models/lm/lm'
import numeral from 'numeral'
import { ReactComponent as ArrowInfoIcon } from 'resources/images/icons/arrow-info.svg'
import { ReactComponent as PackIcon } from 'resources/images/icons/pack.svg'

type Props = { pool: LM }
export default function SmartLiquidityMiningStats({ pool }: Props) {
  const fields: FieldProps[] = [
    {
      icon: 'staked',
      title: 'TVL',
      value: numeral(pool.poolTvlUSDDec).format('0,0.00'),
      symbol: 'USD',
    },
    {
      icon: 'staked',
      title: 'Liquidity Token price',
      value: numeral(pool.priceLiquidityTokenUSDDec).format('0,0.00'),
      symbol: 'USD',
    },
  ]

  const infoButtonClick = () => {
    window.open(pool.poolInfoLink, '_blank', 'noopener,noreferrer')
  }

  return (
    <div className="row-span-2">
      <div className="mb-[1.7rem] grid grid-cols-1 gap-[1rem] ml:grid-cols-2">
        <Field
          title="APY"
          titleClassName="!text-friarGray font-thin"
          value={
            pool.isOccam ? (
              pool.apy
            ) : (
              <div className="flex items-center space-x-2">
                <div>Finished</div>
                <PackIcon className="h-6 w-6" />
              </div>
            )
          }
          valueClassName={pool.isOccam ? '!text-malachite' : '!text-brightGray'}
        />
        <Field
          title="Total staked"
          titleClassName="!text-friarGray font-thin"
          value={numeral(pool.totalStakedUSDDec).format('0,0.00')}
          symbol="USD"
          symbolClassName="!text-brightGray"
          valueClassName="!text-brightGray"
        />
      </div>

      <div
        className="
          relative mb-[1.6rem] items-center rounded-[0.3rem] 
          border border-iron bg-white/20 p-[0.7rem] ml:flex
        "
      >
        <div className="absolute top-[-0.6rem] px-[0.1rem] text-[0.8rem] text-friarGray">
          POOL INFO
        </div>
        <div className="mb-[0.5rem] flex space-x-[2.5rem] ml:mb-0">
          {fields.map((fieldProps) => (
            <Field key={fieldProps.title} {...fieldProps} />
          ))}
        </div>
        <button
          className="
              flex h-[1.6rem] items-center rounded-[5px] border 
              border-iron bg-athensGray/60 px-[0.5rem] !text-[0.8rem] normal-case
              text-friarGray hover:bg-white/50
              ml:ml-auto
            "
          onClick={infoButtonClick}
        >
          {/* {pool.liquidityTokenPrettyName}  */}
          Pool Info
          <ArrowInfoIcon className="ml-[0.35rem] h-[0.45rem] w-[0.45rem] stroke-current text-friarGray" />
        </button>
      </div>
      <div className="text-base text-friarGray">
        Provide liquidity to Uniswap pools and receive rewards in projects
        tokens.
        <br /> Liquidity mining rewards are given in addition to the Uniswap
        trading fees rewards.
        <br />{' '}
        <span className="font-medium">
          Be aware of possible impermanent loss.
        </span>
      </div>
    </div>
  )
}
