import Button from 'components/dumb/Button'
import { useStore } from 'effector-react'
import { $session } from 'models/sessions'
import { $shortAddress, openWallet } from 'models/wallet'
import { ReactNode } from 'react-markdown/lib/react-markdown'
import t from 'utils/t'

interface Props {
  className?: string
}

export default function SmartWalletButton({ className = '' }: Props) {
  const shortAddress = useStore($shortAddress)
  const session = useStore($session)

  let state: 'notConnected' | 'connected' | 'connectedToWrongNetwork'
  if (session === '') {
    state = 'notConnected'
  } else {
    state = 'connected'
  }

  let content: ReactNode = null
  switch (state) {
    case 'connected':
      content = shortAddress
      break
    case 'notConnected':
    default:
      content = t('connect_wallet')
      break
  }

  return (
    <Button
      className={`
        ${className}
        ${state === 'connected' ? 'lowercase' : 'uppercase'}
      `}
      onClick={() => openWallet()}
    >
      {content}
    </Button>
  )
}
