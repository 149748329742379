import Button from 'components/dumb/Button'
import CurrencyInputPanel from 'components/dumb/CurrencyInputPanel'
import { useGate, useStore } from 'effector-react'
import { ReactComponent as DotsLoader } from 'resources/images/dots-loader.svg'
import isEmpty from 'lodash/isEmpty'
import { $session } from 'models/sessions'
import {
  $staking,
  $approveSent,
  addStakeFx,
  approveStakeFx,
  WalletBalanceGate,
  $stakeSent,
} from 'models/staking'
import { $selectedChainId, openWallet } from 'models/wallet'
import numeral from 'numeral'
import { useState } from 'react'
import { noop } from 'utils'
import t from 'utils/t'
import ButtonAddToWallet from 'components/dumb/ButtonAddToWallet'
import { getBlockchainNameByChainId, getShortName } from 'utils/blockchain'

export default function StakeTerminal() {
  useGate(WalletBalanceGate)

  const staking = useStore($staking)
  const session = useStore($session)
  const approveSent = useStore($approveSent)
  const stakeSent = useStore($stakeSent)

  const [input, setInput] = useState('')

  const selectedChainId = useStore($selectedChainId)
  const blockchain = getBlockchainNameByChainId(selectedChainId)
  const shortName = getShortName(blockchain)
  const apyLabel = `$${staking.stakingSymbol} ${shortName} APY: ${staking.currentChainApy}`

  const handleInput = (value: string) => {
    setInput(value)
  }

  const onMax = () => {
    setInput(staking.walletBalance)
  }

  let buttonText = 'Stake'
  let buttonDisabled = false
  let handleClick = noop
  let inputDisabled = false
  let showLoader = false

  switch (staking.stakeTerminalState(input)) {
    case 'walletNotConnected':
      buttonText = t('connect_wallet')
      handleClick = openWallet
      inputDisabled = true
      break
    case 'allowanceExceeded':
      if (approveSent) {
        buttonText = 'Approving'
        buttonDisabled = true
        showLoader = true
      } else {
        buttonText = t('approve_staking')
        handleClick = () => approveStakeFx()
        buttonDisabled = false
        showLoader = false
      }
      break
    case 'readyToStake':
      handleClick = () => addStakeFx(input).then(() => setInput(''))
      if (stakeSent) {
        buttonText = 'Staking'
        buttonDisabled = true
        showLoader = true
      } else {
        buttonText = 'Stake'
        buttonDisabled = false
        showLoader = false
      }
      break
    case 'waitingForInput':
      buttonText = t('enter_an_amount')
      break
    case 'insufficientBalance':
      buttonText = t('insufficient_balance')
      buttonDisabled = true
      break
    case 'notReadyToStake':
    default:
      buttonDisabled = true
      break
  }

  return (
    <div>
      <CurrencyInputPanel
        label={t('you_stake')}
        leftSub={t('current_stake')}
        leftCount={numeral(staking.stakingBalanceCurrentNetworkDec).format(
          '0.00'
        )}
        rightSub={`${isEmpty(session) ? '' : t('wallet')}`}
        rightCount={`${
          isEmpty(session)
            ? ''
            : numeral(staking.walletBalanceDec).format('0.00')
        }`}
        bottomRightSub={apyLabel}
        symbol={staking.stakingSymbol}
        value={input}
        onUserInput={handleInput}
        onMax={onMax}
        decimals={staking.stakingTokenDecimals}
        showMaxButton={!inputDisabled && !isEmpty(session)}
        disabled={inputDisabled}
      />
      <div className="flex space-x-4">
        <Button
          className="h-[41px] w-full"
          disabled={buttonDisabled}
          onClick={handleClick}
        >
          <div className="flex items-center justify-center">
            {buttonText}
            {showLoader && (
              <div className="ml-2">
                <DotsLoader />
              </div>
            )}
          </div>
        </Button>
        <ButtonAddToWallet
          tokenAddress={staking.stakingTokenAddress}
          tokenSymbol={staking.stakingSymbol}
          tokenDecimals={staking.stakingTokenDecimals}
        />
      </div>
    </div>
  )
}
