export const poolAbi = `
[
{
  "inputs": [
    {
      "internalType": "contractERC20",
      "name": "_token",
      "type": "address"
    },
    {
      "internalType": "uint256",
      "name": "_tokenAmountToSell",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "_startTime",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "_endTime",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "_minimumFillPercentage",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "_minimumOrderSize",
      "type": "uint256"
    },
    {
      "internalType": "uint256[]",
      "name": "_maximumAllocation",
      "type": "uint256[]"
    },
    {
      "internalType": "uint256[]",
      "name": "_minimumStakeTiers",
      "type": "uint256[]"
    },
    {
      "internalType": "uint256",
      "name": "_claimLockDuration",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "_price",
      "type": "uint256"
    },
    {
      "internalType": "addresspayable",
      "name": "_assetManager",
      "type": "address"
    },
    {
      "internalType": "address",
      "name": "_projectAdmin",
      "type": "address"
    },
    {
      "internalType": "address",
      "name": "_razerAdmin",
      "type": "address"
    },
    {
      "internalType": "contractIOCCStaking",
      "name": "_stakingContract",
      "type": "address"
    },
    {
      "internalType": "contractIWhitelist",
      "name": "_whitelistContract",
      "type": "address"
    }
  ],
  "stateMutability": "nonpayable",
  "type": "constructor"
},
{
  "anonymous": false,
  "inputs": [
    {
      "indexed": false,
      "internalType": "uint256",
      "name": "newClaimAvailableTimestamp",
      "type": "uint256"
    }
  ],
  "name": "ChangeClaimAvailableTimestamp",
  "type": "event"
},
{
  "anonymous": false,
  "inputs": [
    {
      "indexed": false,
      "internalType": "address",
      "name": "user",
      "type": "address"
    },
    {
      "indexed": false,
      "internalType": "uint256",
      "name": "claimAmount",
      "type": "uint256"
    },
    {
      "indexed": false,
      "internalType": "uint256",
      "name": "OCCRewardAmount",
      "type": "uint256"
    }
  ],
  "name": "Claim",
  "type": "event"
},
{
  "anonymous": false,
  "inputs": [
    {
      "indexed": false,
      "internalType": "address",
      "name": "user",
      "type": "address"
    },
    {
      "indexed": false,
      "internalType": "uint256",
      "name": "refundAmount",
      "type": "uint256"
    }
  ],
  "name": "Refund",
  "type": "event"
},
{
  "anonymous": false,
  "inputs": [
    {
      "indexed": true,
      "internalType": "address",
      "name": "user",
      "type": "address"
    },
    {
      "indexed": false,
      "internalType": "uint256",
      "name": "reserveAmount",
      "type": "uint256"
    },
    {
      "indexed": false,
      "internalType": "uint256",
      "name": "pricePaid",
      "type": "uint256"
    }
  ],
  "name": "ReserveTokens",
  "type": "event"
},
{
  "inputs": [],
  "name": "GLOBAL_MINIMUM_FILL_PERCENTAGE",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "MINIMUM_DURATION",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "OCC",
  "outputs": [
    {
      "internalType": "contractERC20",
      "name": "",
      "type": "address"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "OCCReward",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "PRICE_DENOMINATOR",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "RemainingOCCWithdrawnByRazerAdmin",
  "outputs": [
    {
      "internalType": "bool",
      "name": "",
      "type": "bool"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "SENDBACK_THRESHOLD",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [
    {
      "internalType": "address",
      "name": "",
      "type": "address"
    }
  ],
  "name": "amountPaid",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "assetManager",
  "outputs": [
    {
      "internalType": "addresspayable",
      "name": "",
      "type": "address"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [
    {
      "internalType": "addresspayable",
      "name": "newAssetManager",
      "type": "address"
    }
  ],
  "name": "changeAssetManager",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [
    {
      "internalType": "uint256",
      "name": "newClaimLockDuration",
      "type": "uint256"
    }
  ],
  "name": "changeClaimLockDuration",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [
    {
      "internalType": "uint256",
      "name": "newMinimumFillPercentage",
      "type": "uint256"
    }
  ],
  "name": "changeMinimumFillPercentage",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [
    {
      "internalType": "uint256",
      "name": "newMinimumOrderSize",
      "type": "uint256"
    }
  ],
  "name": "changeMinimumOrderSize",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [
    {
      "internalType": "uint256",
      "name": "newPrice",
      "type": "uint256"
    }
  ],
  "name": "changePrice",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [],
  "name": "changeProjectAdmin",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [],
  "name": "changeRazerAdmin",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [
    {
      "internalType": "contractIOCCStaking",
      "name": "newStakingContract",
      "type": "address"
    }
  ],
  "name": "changeStakingContract",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [
    {
      "internalType": "uint256[]",
      "name": "newMinimumStakeTiers",
      "type": "uint256[]"
    },
    {
      "internalType": "uint256[]",
      "name": "newMaximumAllocation",
      "type": "uint256[]"
    }
  ],
  "name": "changeTiersAndMaximumAllocation",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [
    {
      "internalType": "uint256",
      "name": "newStartTime",
      "type": "uint256"
    },
    {
      "internalType": "uint256",
      "name": "newEndTime",
      "type": "uint256"
    }
  ],
  "name": "changeTime",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [
    {
      "internalType": "address",
      "name": "newToken",
      "type": "address"
    }
  ],
  "name": "changeToken",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [
    {
      "internalType": "uint256",
      "name": "newTokenAmountToSell",
      "type": "uint256"
    }
  ],
  "name": "changeTokenAmountToSell",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [
    {
      "internalType": "contractIWhitelist",
      "name": "newWhitelistContract",
      "type": "address"
    }
  ],
  "name": "changeWhitelistContract",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [],
  "name": "claimAvailableTimestamp",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "claimLockDuration",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "emergencyCancel",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [
    {
      "internalType": "uint256",
      "name": "newClaimAvailableTimestamp",
      "type": "uint256"
    }
  ],
  "name": "emergencyChangeClaimAvailable",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [],
  "name": "emergencyPause",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [],
  "name": "emergencyUnpause",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [],
  "name": "endTime",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "fundRaised",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "name": "maximumAllocation",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "maximumFunding",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "minimumFill",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "minimumFillPercentage",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "minimumOrderSize",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "name": "minimumStakeTiers",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "newProjectAdmin",
  "outputs": [
    {
      "internalType": "address",
      "name": "",
      "type": "address"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "newRazerAdmin",
  "outputs": [
    {
      "internalType": "address",
      "name": "",
      "type": "address"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "price",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "projectAdmin",
  "outputs": [
    {
      "internalType": "address",
      "name": "",
      "type": "address"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "razerAdmin",
  "outputs": [
    {
      "internalType": "address",
      "name": "",
      "type": "address"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "refund",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [],
  "name": "reserve",
  "outputs": [],
  "stateMutability": "payable",
  "type": "function"
},
{
  "inputs": [
    {
      "internalType": "address",
      "name": "_newProjectAdmin",
      "type": "address"
    }
  ],
  "name": "setNewProjectAdmin",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [
    {
      "internalType": "address",
      "name": "_newRazerAdmin",
      "type": "address"
    }
  ],
  "name": "setNewRazerAdmin",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [
    {
      "internalType": "uint256",
      "name": "_OCCReward",
      "type": "uint256"
    },
    {
      "internalType": "contractERC20",
      "name": "_OCC",
      "type": "address"
    }
  ],
  "name": "setOCCReward",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [],
  "name": "setPoolFinish",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [],
  "name": "setPoolOngoing",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [],
  "name": "setPoolReady",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [],
  "name": "stakingContract",
  "outputs": [
    {
      "internalType": "contractIOCCStaking",
      "name": "",
      "type": "address"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "startTime",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "state",
  "outputs": [
    {
      "internalType": "enumState.StateType",
      "name": "",
      "type": "uint8"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "stateBeforePause",
  "outputs": [
    {
      "internalType": "enumState.StateType",
      "name": "",
      "type": "uint8"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "token",
  "outputs": [
    {
      "internalType": "contractERC20",
      "name": "",
      "type": "address"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "tokenAmountLeft",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "tokenAmountSold",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "tokenAmountToSell",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "totalWithdrawnAmount",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [
    {
      "internalType": "address",
      "name": "",
      "type": "address"
    }
  ],
  "name": "userToReserve",
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "whitelistContract",
  "outputs": [
    {
      "internalType": "contractIWhitelist",
      "name": "",
      "type": "address"
    }
  ],
  "stateMutability": "view",
  "type": "function"
},
{
  "inputs": [],
  "name": "withdrawFund",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [
    {
      "internalType": "address",
      "name": "OCCwallet",
      "type": "address"
    }
  ],
  "name": "withdrawOCCAfterFail",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [
    {
      "internalType": "address",
      "name": "OCCwallet",
      "type": "address"
    }
  ],
  "name": "withdrawRemainingOCC",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [],
  "name": "withdrawRemainingTokens",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [],
  "name": "withdrawReservedTokens",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
},
{
  "inputs": [],
  "name": "withdrawTokenAfterFail",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}
]
`
