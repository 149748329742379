export default function ChakraDescSkeleton() {
  return (
    <div className="mt-6 h-full w-full">
      <svg
        width="100%"
        height="50%"
        viewBox="0 0 405 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <rect y="5" width="401" height="12" rx="3" fill="#C9D6E9" />
        <rect y="29" width="390" height="12" rx="3" fill="#C9D6E9" />
        <rect y="53" width="205" height="12" rx="3" fill="#C9D6E9" />
      </svg>
    </div>
  )
}
