import dayjs from 'dayjs'
import { attach, combine, createStore } from 'effector'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { $address } from 'models/wallet'

export const $sessions = createStore<{ [address: string]: string }>({})
export const $session = combine($sessions, $address).map(
  ([sessions, address]) => sessions[address] ?? ''
)

export const isSessionExpiredFx = attach({
  source: $session,
  async effect(session) {
    if (!session) return false

    const decoded = jwtDecode<JwtPayload>(session)
    const exp = decoded?.exp ?? 0
    const isExpired = dayjs.unix(exp).isBefore(dayjs())
    return isExpired
  },
})
