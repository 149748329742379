import { sample } from 'effector'
import { fetchChakraInfo } from 'models/rewards'
import { $session } from 'models/sessions'
import { fetchStakingInfo } from 'models/staking'
import { fetchKYCInfoFx, fetchKYCInfo, setKycInfo } from '.'

sample({
  source: $session,
  target: [fetchKYCInfo, fetchChakraInfo, fetchStakingInfo],
})

sample({
  clock: fetchKYCInfo,
  source: $session,
  filter: (session) => Boolean(session),
  target: fetchKYCInfoFx,
})

sample({
  clock: fetchKYCInfoFx.doneData,
  fn: (data) => data.kycInfo,
  target: setKycInfo,
})

fetchKYCInfo()
