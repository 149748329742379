import Field from 'components/dumb/Field'
import Modal from 'components/dumb/Modal'
import ModalHeader from 'components/dumb/ModalHeader'
import TextInput from 'components/dumb/TextInput'
import { useStore } from 'effector-react'
import { $staking } from 'models/staking'
import { getIcon } from 'utils/blockchain'
import { ReactComponent as InfoIcon } from 'resources/images/icons/info.svg'
import { $session } from 'models/sessions'
import numeral from 'numeral'
import { twMerge } from 'tailwind-merge'
import { openUrl } from 'utils'
import { toDecimal } from 'utils/numbers'

interface Props {
  show: boolean
  onClose(): void
}
export default function StakingCalculator({ show, onClose }: Props) {
  const staking = useStore($staking)
  const connected = useStore($session.map(Boolean))

  const yourApy = connected ? staking.yourApy : staking.yourApyNotConnected
  const yourOrTier5Label = connected && staking.hasTier ? 'Your' : 'Tier V'

  // const [bscValue, setBscValue] = useState('')
  // const [ethValue, setEthValue] = useState('')

  const tier5 = staking.tiers[0]
  const chakraApy = connected
    ? staking.chakraApy
    : numeral(tier5?.apy).format('0.[00]%')

  const balanceBsc =
    connected && staking.hasTier ? staking.stakingBalanceBscDec : toDecimal(100)
  const balanceEth =
    connected && staking.hasTier ? staking.stakingBalanceEthDec : toDecimal(100)
  const balanceTotal =
    connected && staking.hasTier ? staking.totalStakingBalance : '200'

  // const onBscValueChange = (v: string) => {
  //   setBscValue(v)
  // }
  //
  // const onEthValueChange = (v: string) => {
  //   setEthValue(v)
  // }

  return (
    <Modal show={show} onClose={onClose}>
      <ModalHeader label="Staking APY calculator" onRightClick={onClose} />
      <div className="my-4 flex items-center justify-between space-x-8">
        <Field
          icon="mine"
          title={`${yourOrTier5Label} APY`}
          valueClassName="text-primary"
          value={yourApy}
          pending={false}
        />
        <div className="text-sm leading-5 text-gray">
          {yourOrTier5Label} APY sums up the weighted returns from staking in
          ETH and BSC, and {staking.rewardSymbol} rewards.
        </div>
      </div>

      <div className="mb-2 flex items-center space-x-1">
        <div className="text-md font-light leading-6 text-codGray">
          Rewards from unstaking fees
        </div>
        <div
          className="cursor-pointer"
          onClick={() =>
            openUrl('https://hackmd.io/@OccamDAO/StakingGuide#How-to-Unstake')
          }
        >
          <InfoIcon className="h-3 w-3 text-boulder" />
        </div>
      </div>

      <div className="rounded-[6px] border border-iron p-4">
        <ChainInput
          apy={staking.stakingApyBsc}
          chain="bsc"
          disabled
          label={`${yourOrTier5Label} BSC stake`}
          stakingSymbol={staking.stakingSymbol}
          value={numeral(balanceBsc).format('0.[00]')}
        />
        <div className="my-4 h-[1px] w-full bg-quillGray" />
        <ChainInput
          apy={staking.stakingApyEth}
          chain="ethereum"
          disabled
          label={`${yourOrTier5Label} ETH stake`}
          stakingSymbol={staking.stakingSymbol}
          value={numeral(balanceEth).format('0.[00]')}
        />
      </div>
      <div className="-mb-2 mt-4 text-center text-[32px] text-primary">+</div>

      <div className="mb-2 flex items-center space-x-1">
        <div className="text-md font-light leading-6 text-codGray">
          CHAKRA Rewards
        </div>
        <div
          className="cursor-pointer"
          onClick={() =>
            openUrl(
              'https://hackmd.io/@OccamDAO/chakra#How-users-will-get-CHAKRA-tokens'
            )
          }
        >
          <InfoIcon className="h-3 w-3 text-boulder" />
        </div>
      </div>
      <div className="rounded-[6px] border border-iron p-4">
        <ChainInput
          apy={chakraApy}
          disabled
          label={`${yourOrTier5Label} TOTAL stake`}
          stakingSymbol={staking.stakingSymbol}
          value={balanceTotal}
        />
      </div>
    </Modal>
  )
}

interface ChainInputProps {
  onUserInput?(v: string): void
  stakingSymbol: string
  value: string
  chain?: string
  apy: string
  label: string
  disabled?: boolean
}
function ChainInput({
  stakingSymbol,
  value,
  chain,
  apy,
  label,
  disabled = false,
}: ChainInputProps) {
  return (
    <div className="flex justify-between">
      <div>
        <div className="text-md mb-1 whitespace-nowrap text-boulder">
          {label}
        </div>
        <div
          className={twMerge(
            'flex max-w-[130px] justify-between rounded-[6px] border border-quillGray bg-white px-3 py-1',
            disabled && 'bg-quillGray/15'
          )}
        >
          <TextInput
            disabled
            value={value}
            placeholder="0.00"
            className="font-thing relative
            z-10 w-full flex-grow bg-transparent text-left font-thin text-boulder
            placeholder-boulder
            focus:outline-none disabled:bg-quillGray/15
            "
          />
          <span className="select-none font-thin text-boulder">
            {stakingSymbol}
          </span>
        </div>
      </div>
      <div className="flex items-center space-x-3">
        <div className="flex flex-col">
          <div className="text-[28px] leading-none text-primary">{apy}</div>
          <div className="self-end text-base text-boulder">APY</div>
        </div>
        {chain ? (
          <div className="relative h-[56px] w-[56px] rounded-[5px] border border-quillGray">
            <div className="place-center h-[36px] w-[36px] rounded-[50%] bg-primary">
              <div className="place-center h-3/4 w-3/4 text-white">
                {getIcon(chain)}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
