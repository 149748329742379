import classNames from 'classnames'
import SocialLink from 'components/dumb/SocialLink'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as OccamFooterrIcon } from 'resources/images/footer/occam.svg'
import * as links from 'utils/links'

export const LAYOUT_STYLES =
  'relative z-10 m-auto w-[285px] grow items-start mm:w-[90%] ml:w-[520px] t:w-[90%] dxs:w-[980px] dl:w-[1200px]'

export default function Footer() {
  return (
    <div className="mb-[80px] bg-footerBg bg-cover bg-top ml:mb-[100px] ds:mb-0">
      <div className="relative my-[8rem]" />
      <div className="col-span-2 mb-[1rem] h-[0.05rem] bg-minecraft/10 dxs:hidden" />

      <div className={`${LAYOUT_STYLES}`}>
        <div className="absolute left-0 top-[-2rem] block -translate-y-full dxs:hidden">
          <OccamFooterrIcon className="relative h-[4rem] w-[17rem]" />
        </div>
        <div
          className="
            dxs:grid  
            dxs:grid-cols-[10.6rem,auto] dxs:grid-rows-[2.8rem,auto] dxs:gap-x-[5.25rem]
            ds:grid-cols-[14.05rem,auto] ds:grid-rows-[3.55rem,auto]  
            ds:gap-x-[12.2rem]
            dl:gap-x-[14.25rem] 
          "
        >
          {/* 1 */}
          <div className="hidden dxs:block">
            <OccamFooterrIcon className="relative top-[0.05rem] h-full w-fit border-b border-b-minecraft t:w-[10.6rem] ds:w-[14.05rem]" />
          </div>
          {/* 2 */}
          <div className="relative top-[0.05rem] flex flex-wrap items-center mm:justify-between dxs:gap-x-[1rem]">
            <FooterLink
              secondType
              title="Razer Launchpad"
              link={links.RAZER_APP_LINK}
            />
            <FooterLink
              secondType
              title={
                <span>
                  OccamX<span className="hidden ml:inline"> DEX</span>
                </span>
              }
              link={links.OCCAMX_APP_LINK}
            />
            <FooterLink secondType title="Governance" link={links.GOVERNANCE} />
            <FooterLink
              secondType
              title="Incubator"
              link={links.INCUBATOR_LINK}
            />
          </div>
          <div className="col-span-2 hidden h-[0.05rem] bg-minecraft/10 dxs:block" />
          {/* 3 */}
          <div />
          {/* 4 */}
          <div
            className="
              mt-[2rem] flex flex-col items-start justify-between
              dxs:mt-[2.8rem] dxs:flex-row
            "
          >
            <div
              className="
                grid w-full grid-cols-[0.7fr,1fr,auto] gap-x-[1rem] 
                gap-y-[1.4rem]
                t:w-[26rem] dxs:gap-[20px]
              "
            >
              {FOOTER_LINKS.map((item, i) => (
                <FooterLink {...item} key={`footer-links-${i}`} />
              ))}
            </div>
            <div className="">
              <div
                className="
                  relative top-[-0.6rem] mb-[2rem] mt-12
                  grid grid-cols-4 gap-10 dxs:mb-0 dxs:mt-0 ds:grid-cols-2 dl:grid-cols-4
                "
              >
                {links.SOCIAL_LINKS_FOOTER.map((item, i) => (
                  <SocialLink
                    key={`social-links-${i}`}
                    icon={item.name}
                    url={item.link}
                    className="h-[20px] w-[20px] fill-current text-minecraft/30 hover:text-minecraft"
                  />
                ))}
              </div>
            </div>
          </div>
          <div />
          <div className="mt-[2.55rem] flex justify-between pb-[0.9rem] text-lg font-light text-minecraft opacity-60 dxs:text-right">
            <span> Interchain DAO-governed launchpad and incubator</span>
            <span>© 2020-{new Date().getFullYear()} OccamRazer DAO</span>
          </div>
        </div>
        <div className="absolute -left-[130px] -top-[23px] hidden h-[372px] w-[384px] bg-footerMonk dxs:block dl:-left-[100px]" />
      </div>
    </div>
  )
}

type FooterLinkProps = {
  link: string
  title: string | ReactNode
  inner?: boolean
  secondType?: boolean
}
function FooterLink({ link, title, inner, secondType }: FooterLinkProps) {
  const cn = {
    base: 'text-minecraft transition-opacity',
    first:
      'opacity-60 hover:opacity-100 text-[1.125rem] leading-[140%] block font-light hover:text-blue',
    second:
      'text-[1.25rem] w-fit font-medium border-b border-b-[#333333] text-center h-full pt-[0.6rem] hover:opacity-70 mr-[2rem] mm:mr-[1rem] last:mr-0',
  }
  const linkCn = !secondType ? cn.first : cn.second

  return inner ? (
    <Link className={classNames(cn.base, linkCn)} to={{ pathname: link }}>
      {title}
    </Link>
  ) : (
    <a
      className={classNames(cn.base, linkCn)}
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      {title}
    </a>
  )
}

const FOOTER_LINKS = [
  {
    link: links.VC_DAO,
    title: 'VC DAO',
  },
  {
    link: links.APPLY_IDO_LINK,
    title: 'Apply for IDO',
  },
  {
    link: '/pdf/occamrazer_wp_v1.pdf',
    title: 'Whitepaper',
  },
  {
    link: '/pdf/occam_deck.pdf',
    title: 'Deck',
  },
  {
    link: links.SUPPORT_LINK,
    title: 'Contact Us',
  },
  {
    link: links.SUPPORT_LINK,
    title: 'Support',
  },
  {
    link: links.FAQ_LINK,
    title: 'FAQ',
  },
  // {
  //   link: links.OCCAMX_DASHBOARD_LINK,
  //   title: 'OCX Dashboard',
  // },
  {
    link: 'https://drive.google.com/drive/folders/1KJRfZHf966IkpoMvsIVUOBWCsLZ15ERV?usp=sharing',
    title: 'Media Pack',
  },
  {
    link: links.CAREERS_LINK,
    title: 'Careers',
  },
  {
    link: links.FORUM_LINK,
    title: 'Forum',
  },
]
