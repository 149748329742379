export default function ChakraTerminalSkeleton() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 510 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <rect width="177" height="26" rx="6" fill="#DDE4EE" />
      <rect y="45" width="510" height="120" rx="8" fill="#DDE4EE" />
      <rect y="200" width="510" height="40" rx="8" fill="#DDE4EE" />
    </svg>
  )
}
