import { useNavigate } from 'react-router-dom'
import { noop } from 'utils'

interface Props {
  children: React.ReactNode
  href?: string
  onClick?(): void
  className?: string
}

export default function TextLink({
  href,
  children,
  className = '',
  onClick = noop,
}: Props) {
  const navigate = useNavigate()
  const isInternal = href && href.startsWith('/')

  const handleClick = () => {
    if (isInternal) {
      navigate(href)
    } else {
      if (href) {
        window.open(href, '_blank', 'noopener,noreferrer')
      } else if (onClick) {
        onClick()
      }
    }
  }

  return (
    <div
      className={`
      inline cursor-pointer text-sm leading-[140%]
      text-primary underline decoration-primary underline-offset-[0.45rem]
      hover:text-primaryHover hover:decoration-primaryHover active:text-primaryActive active:decoration-primaryActive
      ${className}
      `}
      onClick={handleClick}
    >
      {children}
    </div>
  )
}
