import './wallet/init'
import './sessions/init'
import './generalInfo/init'
import './staking/init'
import './lm/init'
import './transactions/init'
import './pools/init'
import './rewards/init'
import './app'
import Decimal from 'decimal.js'

Decimal.set({ toExpPos: 30 })
