import { attach, combine, createEvent, createStore } from 'effector'
import { DistributionCed, CedContractType, IndexTokenInfo } from 'gql'
import { graphqlSdk } from 'gql/client'
import { $session } from 'models/sessions'
import { $provider, $selectedChainId } from 'models/wallet'
import invariant from 'tiny-invariant'
import { getChainId } from 'utils/blockchain'
import { formatNumeral } from 'utils/numbers'
import { ChakraInfo } from './chakraInfo'

export const $indexTokenInfo = createStore<IndexTokenInfo | null>(null)
export const $cedRewardsNew = createStore<DistributionCed[]>([])
export const $cedRewardsOld = createStore<DistributionCed[]>([])
export const $cedIndicator = combine($cedRewardsNew, $cedRewardsOld).map(
  ([cedOld, cedNew]) =>
    (cedOld?.length > 0 ? 1 : 0) + (cedNew?.length > 0 ? 1 : 0)
)

export const $chakraInfo = createStore<ChakraInfo>(new ChakraInfo(null))

export const $chakraNetworkIsOK = $selectedChainId.map(
  (selectedChainId) => getChainId('bsc') === selectedChainId
)

export const fetchCedRewards = createEvent()
export const fetchChakraInfo = createEvent()

export const $chakraAPY = combine($chakraInfo, $session).map(
  ([chakraInfo, session]) => {
    const tier5 = chakraInfo.tiers?.[0]
    let chakraApy = '0%'
    let chakraApyTooltip = '0%'
    let balance = ''
    let perYear = ''

    if (!session || !chakraInfo.hasTier) {
      balance = tier5?.minStake ?? ''
      chakraApy = `Up to ${formatNumeral(tier5?.apy, {
        formatString: '0.[00]%',
      })}`

      chakraApyTooltip = formatNumeral(tier5?.apy, {
        formatString: '0.[00]%',
      })

      perYear = formatNumeral(tier5?.tokenEarningsPerYear, {
        formatString: '0,00',
      })
    } else {
      balance = formatNumeral(chakraInfo.stakingBalanceDec, {
        formatString: '0,0.00',
      })
      chakraApy = formatNumeral(chakraInfo.chakraApyDec, {
        formatString: '0.[00]%',
      })
      chakraApyTooltip = chakraApy
      perYear = formatNumeral(chakraInfo.tokensEarningPerYear, {
        formatString: '0,00',
      })
    }

    const data = { chakraApy, chakraApyTooltip, balance, perYear }
    return data
  }
)
export const $cedBannerOpen = combine($cedRewardsNew, $session).map(
  (/*[cedRewards, session]*/) =>
    /* cedRewards.length > 0 && Boolean(session)*/ false
)

export const fetchCedRewardsFx = (t: CedContractType) =>
  attach({
    source: $session,
    effect: async (session: string) => {
      return await graphqlSdk.CedRewards({
        session,
        cedContractType: t,
      })
    },
  })

export const fetchOldFx = fetchCedRewardsFx(CedContractType.Old)
export const fetchNewFx = fetchCedRewardsFx(CedContractType.New)

export const fetchChakraInfoFx = attach({
  source: $session,
  effect: async (session: string) => {
    const args = {
      session: Boolean(session) ? session : null,
    }
    return await graphqlSdk.ChakraInfo(args)
  },
})

export const claimPendingChakraFx = attach({
  source: [$provider, $session],
  async effect([provider, session]) {
    invariant(provider, 'web3 provider not found')
    try {
      const queryRes = await graphqlSdk.ClaimRewards({ session })
      const tx = queryRes.claimRewards
      const signer = provider.getSigner()

      const params = {
        from: tx.from,
        to: tx.to,
        // chainId: Number(tx.chainId),
        //gasPrice: tx.gasPrice,
        data: tx.data,
        nonce: tx.nonce,
        value: tx.value,
      }
      const estimatedGas0 = await signer.estimateGas(params)

      const res = await signer.sendTransaction({
        ...params,
        gasLimit: estimatedGas0.mul(120).div(100),
      })

      // const b = Buffer.from(JSON.stringify(claimInput)).toString('base64')
      // const r = Buffer.from(b, 'base64')

      // const encodedResolverOptions = defaultAbiCoder.encode(
      //   ['(uint256,address,uint256,uint256,bytes32[])'],
      //   [Object.values(claimInput)]
      // )
      // console.log(encodedResolverOptions)

      // const params = {
      //   from: address,
      //   to: '0x9fbe4a7788a58d884838ce22fcc72dada42083f6',
      //   data: r,
      // }
      // console.log(params)
      //
      // const estimatedGas0 = await signer.estimateGas(params)
      // const res = await signer.sendTransaction({
      //   ...params,
      //   gasLimit: estimatedGas0.mul(120).div(100),
      // })
      //
      return `${res?.hash ?? ''}`
    } catch (err) {
      console.log(err)
    }
  },
})

export const claimCedFx = (t: CedContractType) =>
  attach({
    source: [$provider, $session],
    async effect([provider, session]) {
      invariant(provider, 'web3 provider not found')
      const queryRes = await graphqlSdk.CedClaimAllTokens({
        session,
        cedContractType: t,
      })
      const tx = queryRes.cedClaimAllTokens
      const signer = provider.getSigner()

      const params = {
        from: tx.from,
        to: tx.to,
        // chainId: Number(tx.chainId),
        //gasPrice: tx.gasPrice,
        data: tx.data,
        nonce: tx.nonce,
        value: tx.value,
      }
      const estimatedGas0 = await signer.estimateGas(params)

      const res = await signer.sendTransaction({
        ...params,
        gasLimit: estimatedGas0.mul(120).div(100),
      })

      return `${res?.hash ?? ''}`
    },
  })

export const claimCedOld = claimCedFx(CedContractType.Old)
export const claimCedNew = claimCedFx(CedContractType.New)
