import Button from 'components/dumb/Button'
import Modal from 'components/dumb/Modal'
import ModalHeader from 'components/dumb/ModalHeader'
import TextLink from 'components/dumb/TextLink'
import { useStore } from 'effector-react'
import { $showTransaction, $tx, closeTransaction } from 'models/transactions'
import { $selectedChainId } from 'models/wallet'
import { ReactNode } from 'react-markdown/lib/react-markdown'
import { ReactComponent as FailedIcon } from 'resources/images/icons/tx-failed.svg'
import { ReactComponent as PendingIcon } from 'resources/images/icons/tx-pending.svg'
import { ReactComponent as SubmittedIcon } from 'resources/images/icons/tx-submitted.svg'
import { getTxExplorerUrl } from 'utils'
import { ETHEREUM_MAINNET_CHAIN_ID } from 'utils/constants'
import t from 'utils/t'

export default function Transaction() {
  const showTransaction = useStore($showTransaction)
  const { status, hash } = useStore($tx)
  const selectedChainId = useStore($selectedChainId)

  let label = ''
  let text = ''
  let icon: ReactNode = null
  let showCloseButton = true
  let showUrl = false
  switch (status) {
    case 'pending':
      label = t('waiting_for_confirmation')
      text = t('confirm_transaction_text')
      icon = <PendingIcon className="h-20 w-20 animate-spin" />
      showCloseButton = false
      break
    case 'failed':
      label = t('error')
      text = t('error_modals_text')
      icon = <FailedIcon className="h-20 w-20 text-red" />
      break
    case 'submitted':
      label = t('transaction_submitted')
      text = t('transaction_submitted_text')
      icon = <SubmittedIcon className="h-20 w-20 text-primary" />
      showUrl = true
      break
  }

  return (
    <Modal show={showTransaction} onClose={closeTransaction}>
      <ModalHeader label={label} onRightClick={closeTransaction} />
      <div className="flex justify-center py-5">{icon}</div>
      {showUrl && (
        <div className="mt-4 flex w-full justify-center">
          <TextLink
            className="text-sm"
            href={getTxExplorerUrl(
              hash,
              selectedChainId ?? ETHEREUM_MAINNET_CHAIN_ID
            )}
          >
            {t('view_transaction')}
          </TextLink>
        </div>
      )}
      <p className="mt-4 text-sm font-light text-friarGray">{text}</p>
      {showCloseButton && (
        <Button className="mt-5 w-full" onClick={() => closeTransaction()}>
          Close
        </Button>
      )}
    </Modal>
  )
}
