import Button from 'components/dumb/Button'
import { ReactComponent as DotsLoader } from 'resources/images/dots-loader.svg'
import isEmpty from 'lodash/isEmpty'
import CurrencyInputPanel from 'components/dumb/CurrencyInputPanel'
import { useStore } from 'effector-react'
import { $staking, $unstakeSent, removeStakeFx } from 'models/staking'
import { openWallet } from 'models/wallet'
import numeral from 'numeral'
import { useState } from 'react'
import { noop } from 'utils'
import t from 'utils/t'
import { $session } from 'models/sessions'
import ButtonAddToWallet from 'components/dumb/ButtonAddToWallet'

export default function UnstakeTerminal() {
  const [input, setInput] = useState('')
  const unstakeSent = useStore($unstakeSent)
  const staking = useStore($staking)
  const session = useStore($session)

  const handleInput = (value: string) => {
    setInput(value)
  }

  const onMax = () => {
    setInput(staking.stakingBalance)
  }

  let buttonText = 'Unstake'
  let buttonDisabled = false
  let handleClick = noop
  let inputDisabled = false
  let showLoader = false

  switch (staking.unstakeTerminalState(input)) {
    case 'walletNotConnected':
      buttonText = t('connect_wallet')
      handleClick = openWallet
      inputDisabled = true
      break
    case 'readyToUnstake':
      handleClick = () => removeStakeFx(input).then(() => setInput(''))
      if (unstakeSent) {
        buttonText = 'Unstaking'
        buttonDisabled = true
        showLoader = true
      } else {
        buttonText = 'Unstake'
        buttonDisabled = false
        showLoader = false
      }
      break
    case 'waitingForInput':
      buttonText = t('enter_an_amount')
      break
    case 'insufficientBalance':
      buttonText = t('insufficient_balance')
      buttonDisabled = true
      break
    case 'notReadyToUnstake':
    default:
      buttonDisabled = true
      break
  }

  return (
    <div>
      <CurrencyInputPanel
        label={t('you_unstake')}
        leftSub={t('current_stake')}
        rightSub={`${isEmpty(session) ? '' : t('wallet')}`}
        leftCount={numeral(staking.stakingBalanceCurrentNetworkDec).format(
          '0.00'
        )}
        rightCount={`${
          isEmpty(session)
            ? ''
            : numeral(staking.walletBalanceDec).format('0.00')
        }`}
        symbol={staking.stakingSymbol}
        value={input}
        onUserInput={handleInput}
        onMax={onMax}
        decimals={staking.stakingTokenDecimals}
        showMaxButton={!inputDisabled}
        disabled={inputDisabled}
      />
      <div className="flex space-x-4">
        <Button
          className="h-[41px] w-full"
          disabled={buttonDisabled}
          onClick={handleClick}
        >
          <div className="flex items-center justify-center">
            {buttonText}
            {showLoader && (
              <div className="ml-2">
                <DotsLoader />
              </div>
            )}
          </div>
        </Button>
        <ButtonAddToWallet
          tokenAddress={staking.stakingTokenAddress}
          tokenSymbol={staking.stakingSymbol}
          tokenDecimals={staking.stakingTokenDecimals}
        />
      </div>
    </div>
  )
}
