import Button from 'components/dumb/Button'
import { ReactComponent as DotsLoader } from 'resources/images/dots-loader.svg'
import { formatNumber, printNumberThresholds } from 'utils/numbers'
import { useStore } from 'effector-react'
import { $claimSent, $staking, getRewardFx } from 'models/staking'
import { openWallet } from 'models/wallet'
import { noop } from 'utils'
import { $session } from 'models/sessions'
import isEmpty from 'lodash/isEmpty'
import t from 'utils/t'
import ButtonAddToWallet from 'components/dumb/ButtonAddToWallet'

export default function ClaimTerminal() {
  const staking = useStore($staking)
  const session = useStore($session)
  const claimSent = useStore($claimSent)

  let buttonText = 'Claim'
  let buttonDisabled = false
  let handleClick = noop
  let showLoader = false

  switch (staking.claimTerminalState) {
    case 'walletNotConnected':
      buttonText = t('connect_wallet')
      handleClick = openWallet
      break
    case 'readyToClaim':
      handleClick = () => getRewardFx()
      if (claimSent) {
        buttonText = 'Claiming'
        buttonDisabled = true
        showLoader = true
      } else {
        buttonText = 'Claim'
        buttonDisabled = false
        showLoader = false
      }
      break
    case 'notReadyToClaim':
    default:
      buttonDisabled = true
      break
  }
  const rewardTotal = formatNumber(staking.rewardTotalDec)
  //const rewardAvailable = printNumberThresholds(staking.rewardAvailableDec, 5)
  const occRewardAvailable = printNumberThresholds(
    staking.occRewardAvailable,
    5
  )

  return (
    <div className="relative">
      <div className="mb-4 overflow-hidden rounded-[0.35rem] border border-iron p-4">
        <div className="text-2xl text-brightGray">
          {t('available_for_claiming')}
        </div>
        <div className="flex space-x-8">
          {/*<div className="py-2">
            <div className="flex items-baseline text-primary">
              <div className="mr-[0.5rem] text-[1.95rem]">
                {rewardAvailable === 'NaN' || isEmpty(session)
                  ? '0'
                  : rewardAvailable}
              </div>
              <div className="relative text-base uppercase">
                {staking.rewardSymbol}
              </div>
            </div>
            [><span className="text-friarGray/80">≈$27,274.31</span><]
          </div>*/}
          <div className="relative rounded-[10px] bg-catskill px-4 py-2">
            {/* <PlusIcon className="absolute left-2 top-5 fill-current text-primary/20" /> */}
            <div className="flex items-baseline text-primary">
              <div className="mr-[0.5rem] text-[1.95rem]">
                {occRewardAvailable === 'NaN' || isEmpty(session)
                  ? '0'
                  : occRewardAvailable}
              </div>
              <div className="relative text-base uppercase">OCC</div>
            </div>
            {/*<span className="ml-5 text-friarGray/80">≈$27,274.31</span>*/}
          </div>
        </div>
      </div>
      <div className="mb-4 text-sm text-grayTertiary">
        {staking.stakingSymbol} {t('total_earned')}:{' '}
        {isEmpty(session) ? '0' : rewardTotal}
      </div>
      <div className="flex space-x-4">
        <Button
          className="h-[41px] w-full"
          onClick={handleClick}
          disabled={buttonDisabled}
        >
          <div className="flex items-center justify-center">
            {buttonText}
            {showLoader && (
              <div className="ml-2">
                <DotsLoader />
              </div>
            )}
          </div>
        </Button>
        <ButtonAddToWallet
          tokenAddress={staking.stakingTokenAddress}
          tokenSymbol={staking.stakingSymbol}
          tokenDecimals={staking.stakingTokenDecimals}
        />
      </div>
    </div>
  )
}
