import classNames from 'classnames'
import { PrivatePool, PublicPool } from 'models/pools/pools'
import { ReactComponent as LoadMoreIcon } from 'resources/images/icons/loadmore.svg'
import t from 'utils/t'
import Block from './Block'
import Button from './Button'
import Dropdown from './Dropdown'
import { getIcon } from 'utils/blockchain'
import { openUrl } from 'utils'
import { useMemo, useState } from 'react'

interface FinishedPoolsProps {
  finishedPools: Array<PrivatePool | PublicPool>
  onClick(id: string, blockchain: string): void
}

export default function HomeFinishedPoolsTable({
  finishedPools,
  onClick,
}: FinishedPoolsProps) {
  const [index, setIndex] = useState(10)

  const finishedPools_ = useMemo(() => {
    return finishedPools.slice(0, index)
  }, [finishedPools, index])

  const handleLoadMoreClick = () => {
    setIndex((i) => i + 10)
  }

  return (
    <Block containerClassName="px-4 py-4 ds:px-4 ds:py-5">
      {/* mobile */}
      <div className="flex flex-col space-y-[20px] dxs:hidden">
        {finishedPools_.map((pool) => {
          const icon = getIcon(pool.original.blockchain)
          const markets = pool.secondaryMarkets.map((m) => ({
            text: m.name,
            onClick: () => openUrl(m.url),
          }))
          return (
            <div key={pool.name} className="rounded-md border border-iron p-2">
              <ProjectName
                pool={pool}
                onClick={() => onClick(pool.id, pool.blockchain)}
              />

              <div className="mt-3 flex flex-col text-base leading-[140%] text-friarGray">
                <div className="flex items-center justify-between p-[5px]">
                  <div>{t('network')}</div>
                  <div className="h-[1rem] w-[0.9rem]">{icon}</div>
                </div>
                <div className="flex justify-between p-[5px]">
                  <div>{t('ido_price')}</div>
                  <div className="font-medium text-brightGray">
                    {pool.tokenPrice}
                  </div>
                </div>
                <div className="flex justify-between bg-porcelain p-[5px] ">
                  <div>{t('total_raised')}</div>
                  <div className="font-medium text-brightGray">
                    {pool.totalRaised}
                  </div>
                </div>
                <div className="flex justify-between p-[5px]">
                  <div>{t('participants')}</div>
                  <div className="font-medium text-brightGray">
                    {pool.participantsCount}
                  </div>
                </div>
                <div className="flex items-center justify-between bg-porcelain p-[5px]">
                  <div>{t('ended')}</div>
                  <EndTime pool={pool} />
                </div>
                <Dropdown
                  disabled={markets.length === 0}
                  items={markets}
                  className="mt-3 w-full"
                >
                  {t('exchange')}
                </Dropdown>
              </div>
            </div>
          )
        })}
      </div>
      {/* desktop */}
      <div className="hidden dxs:block">
        <div
          className={classNames(
            'grid gap-x-[8px] pt-2 ds:gap-x-[20px]',
            'gap-x-[20px]',
            'text-right text-[14px] font-light leading-5 text-codGray',
            'dxs:grid-cols-finishedPoolsTable1000 ds:grid-cols-finishedPoolsTable1200 dl:grid-cols-finishedPoolsTable1600'
          )}
        >
          <div className=" pl-3 text-left">{t('project_name')}</div>
          <div className="">{t('network')}</div>
          <div className="">{t('ido_price')}</div>
          <div className="">{t('total_raised')}</div>
          <div className="">{t('participants')}</div>
          <div className="">{t('ended')}</div>
          <div className=" pr-3">{t('secondary_market')}</div>
        </div>
        <div className="mt-[34px] flex flex-col space-y-6">
          {finishedPools_.map((pool) => {
            const icon = getIcon(pool.original.blockchain)
            const markets = pool.secondaryMarkets.map((m) => ({
              text: m.name,
              onClick: () => openUrl(m.url),
            }))
            return (
              <div
                className={classNames(
                  'group grid items-center gap-x-[8px] py-3 ds:gap-x-[20px] ',
                  'rounded-md border border-brightGray/5 font-medium text-brightGray',
                  'hover:finishedPoolsTable-shadow text-right hover:bg-white',
                  'dxs:grid-cols-finishedPoolsTable1000 ds:grid-cols-finishedPoolsTable1200 dl:grid-cols-finishedPoolsTable1600'
                )}
                key={pool.name}
              >
                <ProjectName
                  pool={pool}
                  onClick={() => onClick(pool.id, pool.blockchain)}
                  className=" pl-3"
                />

                <div className="flex justify-end  group-hover:text-primary">
                  <div className="h-5 w-5">{icon}</div>
                </div>
                <div className="">{`${pool.tokenPrice} ${pool.targetTokenSymbol}`}</div>
                <div className="">{pool.totalRaised}</div>
                <div className="">{pool.participantsCount}</div>
                <div className="">
                  <EndTime pool={pool} />
                </div>
                <div className="">
                  <div className="flex justify-end pr-3 font-light">
                    <Dropdown disabled={markets.length === 0} items={markets}>
                      {t('exchange')}
                    </Dropdown>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {index < finishedPools.length ? (
        <Button
          onClick={handleLoadMoreClick}
          type="tertiary"
          className="mt-[20px] w-full"
        >
          <div className="flex items-center justify-center space-x-2">
            <span>Load more</span>
            <LoadMoreIcon />
          </div>
        </Button>
      ) : null}
    </Block>
  )
}

function ProjectName({
  onClick,
  pool,
  className = '',
}: {
  onClick(): void
  pool: PrivatePool | PublicPool
  className?: string
}) {
  return (
    <div
      className={classNames(
        'flex cursor-pointer space-x-[12px] text-left',
        className
      )}
      onClick={onClick}
    >
      <div className="relative h-[56px] w-[56px] shrink-0 rounded-md bg-porcelain ">
        <div
          className="absolute left-1/2 top-1/2 h-full w-full shrink-0 -translate-x-1/2 -translate-y-1/2 rounded-md bg-cover bg-no-repeat"
          style={{ backgroundImage: `url(${pool.logo})` }}
        />
      </div>
      <div className="flex flex-col space-y-[2px] self-center overflow-hidden">
        <div className="truncate font-medium">{pool.name}</div>
        <div className="text-[14px] font-medium text-friarGray">
          {pool.idoTokenSymbol}
        </div>
      </div>
    </div>
  )
}

function EndTime({ pool }: { pool: PrivatePool | PublicPool }) {
  const checkpointsLength = pool.original.vesting?.unlockCheckpoints.length || 0
  const lastIndex = checkpointsLength - 1
  const endTime = pool.original.vesting?.unlockCheckpoints[lastIndex]

  const currentDate = new Date()
  const compareDate = new Date(endTime || 0)

  return (
    <div className="text-right">
      <div className="font-medium text-brightGray">{pool.endTime}</div>
      <div className="whitespace-nowrap text-sm font-normal text-friarGray">
        {compareDate < currentDate ? 'Finished' : 'Distribution in progress'}
      </div>
    </div>
  )
}
