import { Effect, Event } from 'effector'
import { useEffect } from 'react'

export default function useFetchInterval(
  fx: Effect<void, any, Error> | Event<any>
) {
  useEffect(() => {
    fx()
    const id = setInterval(fx, 5000)

    return () => {
      clearInterval(id)
    }
  }, [fx])
}
