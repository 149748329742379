import { ReactComponent as ChevronIcon } from 'resources/images/icons/chevron-left.svg'
import { ReactComponent as CloseIcon } from 'resources/images/icons/close.svg'

interface Props {
  label: string
  onRightClick(): void
  onLeftClick?(): void
}

export default function ModalHeader({
  label,
  onLeftClick,
  onRightClick,
}: Props) {
  return (
    <div className="mb-4 flex items-center justify-between">
      {onLeftClick && (
        <ChevronIcon
          className="h-4 w-4 cursor-pointer fill-current text-primary"
          onClick={onLeftClick}
        />
      )}
      <p className="text-base font-medium text-codGray">{label}</p>
      <CloseIcon
        className="h-4 w-4 cursor-pointer fill-current text-primary"
        onClick={onRightClick}
      />
    </div>
  )
}
