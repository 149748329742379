import Modal from 'components/dumb/Modal'
import { useStore } from 'effector-react'
import {
  $showAboutKyc,
  $showCedRewards,
  $showWallet,
  $wallet,
  closeWallet,
} from 'models/wallet'
import { ReactNode } from 'react'
import AboutKyc from './AboutKyc'
import WalletConnected from './WalletConnected'
import WalletList from './WalletList'
import WalletCEDRewards from './WalletCEDRewards'

export default function Wallet() {
  const wallet = useStore($wallet)
  const showWallet = useStore($showWallet)
  const showAboutKyc = useStore($showAboutKyc)
  const showCedRewards = useStore($showCedRewards)

  let content: ReactNode = null

  if (wallet?.state === 'connected') {
    if (showAboutKyc) {
      content = <AboutKyc />
    } else if (showCedRewards) {
      content = <WalletCEDRewards />
    } else {
      content = <WalletConnected />
    }
  } else {
    content = <WalletList />
  }

  return (
    <Modal show={showWallet} onClose={closeWallet}>
      {content}
    </Modal>
  )
}
