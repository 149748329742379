import { useStore } from 'effector-react'
import { addTokenFx } from 'models/pools'
import { $session } from 'models/sessions'
import { ReactComponent as MetamaskIcon } from 'resources/images/staking/metamask.svg'
import { ReactComponent as PlusIcon } from 'resources/images/staking/plus2.svg'

interface Props {
  tokenAddress: string
  tokenSymbol: string
  tokenDecimals: number
}

export default function ButtonAddToWallet({
  tokenAddress,
  tokenSymbol,
  tokenDecimals,
}: Props) {
  const connected = useStore($session.map(Boolean))
  if (!connected) {
    return null
  }

  const handleClick = () => {
    addTokenFx({
      tokenAddress,
      tokenSymbol,
      tokenDecimals,
    })
  }
  return (
    <button
      className="rounded-[5px] bg-white px-4 py-2 hover:bg-primary/10 active:bg-primary/20"
      onClick={handleClick}
    >
      <div className="flex items-center space-x-2">
        <PlusIcon className="block h-4 w-4 fill-current text-primary ml:hidden" />
        <div className="hidden whitespace-nowrap text-base text-primary ml:block">
          Add to wallet
        </div>
        <MetamaskIcon className="h-5 w-5" />
      </div>
    </button>
  )
}
