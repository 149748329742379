import Block from 'components/dumb/Block'
import Button from 'components/dumb/Button'
import Dropdown from 'components/dumb/Dropdown'
import { ReactComponent as LockIcon } from 'resources/images/icons/lock.svg'
import { useStore } from 'effector-react'
import numeral from 'numeral'
import { useNavigate } from 'react-router-dom'
import { openUrl } from 'utils'
import { formatNumber } from 'utils/numbers'
import t from 'utils/t'
import DashboardTiers from 'components/smart/SmartDashboard/DashboardTiers'
import { Asset } from 'gql'
import { ReactComponent as PancakeSwapIcon } from 'resources/images/icons/pancake.svg'
import SmartChakraTerminal from './SmartChakraTerminal'
import useFetchInterval from 'utils/hooks/useFetchInterval'
import { $chakraInfo, $indexTokenInfo, fetchChakraInfo } from 'models/rewards'
import ChakraPoolSkeleton from 'components/dumb/skeleton/ChakraPoolSkeleton'
import ChakraAsssetSkeleton from 'components/dumb/skeleton/ChakraAssetSkeleton'

export default function SmartDashboard() {
  const indexTokenInfo = useStore($indexTokenInfo)
  const chakraInfo = useStore($chakraInfo)
  const rewardSymbol = chakraInfo?.chakraSymbol
  const stakingSymbol = chakraInfo?.stakingSymbol
  const pending = !chakraInfo || !indexTokenInfo

  const navigate = useNavigate()
  useFetchInterval(fetchChakraInfo)

  const tradeItems = [
    {
      text: 'PancakeSwap',
      icon: (
        <PancakeSwapIcon className="h-4 w-4 fill-current text-brightGray grayscale" />
      ),
      onClick: () =>
        openUrl(
          'https://pancakeswap.finance/swap?outputCurrency=0x0e8288939Da704123eF09D7c55f39c6834D0b63D&chainId=56&inputCurrency=0x2a4DFfa1Fa0F86CE7f0982F88Aecc199FB3476bc '
        ),
    },
  ]

  const assets = indexTokenInfo?.assets.filter((asset): asset is Asset =>
    Boolean(asset)
  )

  const pendingAssets = Array(6).fill(0)
  return (
    <>
      <SmartChakraTerminal pending={pending} />
      <div className="mt-[40px] text-brightGray t:mt-[80px]">
        <div className="mb-5 text-2xl font-light">
          <span className="">${rewardSymbol} </span>Pool status
        </div>
        <Block>
          {pending ? (
            <ChakraPoolSkeleton />
          ) : (
            <div className="grid grid-cols-1 gap-x-9 gap-y-9 t:grid-cols-2 dxs:grid-cols-[1.5fr,1fr,1.5fr]">
              <div className="bg-mandala4 bg-no-repeat">
                <div className="text-2xl font-light text-brightGray">
                  <span className="font-medium">${rewardSymbol} pool </span>
                  total worth{' '}
                </div>
                <div className="relative mb-3 flex items-end space-x-2 text-[54px] leading-[1.2] text-primary">
                  <div>
                    {numeral(indexTokenInfo?.totalValueUSD).format('0,00')}
                  </div>
                  <div className="relative bottom-[7px] text-[34px] font-light">
                    USD
                  </div>
                </div>
                <span className=" rounded-[4px] border border-primary px-4 py-2 text-lg leading-5 text-primary">
                  {assets?.length} assets in the pool
                </span>
              </div>
              <div className="grid-flow-cols-1 grid gap-5 ml:grid-cols-2 t:grid-cols-1">
                <div>
                  <div className="text-lg leading-[150%] text-boulder">
                    ${rewardSymbol} est. price
                  </div>
                  <div className="relative flex items-end space-x-1 leading-5">
                    <div className="text-[22px]">
                      {numeral(indexTokenInfo?.indexTokenFairPriceUSD).format(
                        '0,0.00[0000]'
                      )}
                    </div>
                    <div className="relative bottom-[-2px] text-sm font-light">
                      USD
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-lg leading-[150%] text-boulder">
                    ${rewardSymbol} token total supply
                  </div>
                  <div className="relative flex items-end space-x-1 leading-5">
                    <div className="text-[22px]">
                      {chakraInfo?.rewardTokenTotalSupply}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex justify-center rounded-[10px] border border-white bg-chakraStake bg-cover bg-no-repeat
              t:col-span-full dxs:col-span-1 dxs:min-h-[172px]"
              >
                <div
                  className="
                flex h-full w-full flex-col items-center justify-between px-6 py-7
                t:flex-row t:py-5
                dxs:w-[248px] dxs:flex-col dxs:py-7
                "
                >
                  <div className="mb-6 text-center text-xl font-light leading-[120%] t:mb-0">
                    Stake <span className="font-medium">${stakingSymbol}</span>{' '}
                    to Earn <span className="font-medium">${rewardSymbol}</span>
                  </div>

                  <Button
                    onClick={() => navigate('/staking')}
                    type="gradientBorder"
                  >
                    {t('go_to_staking')}
                  </Button>
                </div>
              </div>
            </div>
          )}

          {pending ? null : (
            <div className="my-5 h-[1px] w-full bg-geyser/40" />
          )}
          <div className="grid min-h-[280px] grid-cols-1 gap-x-3 gap-y-6 ml:grid-cols-2 dxs:grid-cols-3 dl:grid-cols-4">
            {pending
              ? pendingAssets.map((t, i) => <ChakraAsssetSkeleton key={i} />)
              : null}
            {!pending && assets
              ? assets?.map((t) => <AssetCard key={t.websiteLink} {...t} />)
              : null}
          </div>
        </Block>

        <div id="chakra-tiers" className="mb-5 mt-[48px] text-2xl font-light">
          ${stakingSymbol} Staking returns by Tier
        </div>
        {chakraInfo?.tiers && <DashboardTiers tiers={chakraInfo.tiers} />}

        <div className="mb-5 mt-[48px] text-2xl font-light">
          ${rewardSymbol} Utility{' '}
        </div>
        <div className="grid grid-cols-1 gap-5 dxs:grid-cols-3">
          <div className="rounded-[10px] border border-primary  p-6">
            <div className="mb-2 text-[22px] font-light leading-[130%] text-brightGray">
              HODL
            </div>
            <p className="font-light leading-6 text-boulder">
              <span className="font-medium">
                ${rewardSymbol} grants its holders exposure to the Chakra Pool
              </span>
              , a weighted-basket of tokens representing the value of projects
              launched on the OccamRazer platform.
            </p>
          </div>
          <div className="blockShadow flex flex-col justify-between  rounded-[10px] border border-white bg-athensGray2 p-6">
            <div>
              <div className="mb-2 text-[22px] font-light leading-[130%] text-brightGray">
                TRADE
              </div>
              <p className="mb-5 font-light leading-6 text-boulder">
                {t('dashboard_trade', { rewardSymbol })}
              </p>
            </div>
            <Dropdown items={tradeItems} className="w-fit">
              <span className="normal-case">Trade</span> {rewardSymbol}
            </Dropdown>
          </div>
          <div className="blockShadow rounded-[10px] border border-white bg-athensGray2 p-6">
            <div className="relative flex items-center">
              <div className="mb-2 text-[22px] font-light leading-[130%] text-brightGray">
                BURN
              </div>
              <div className="relative bottom-[4px] ml-2 rounded border border-primary  px-[10px] py-[2px] text-sm text-primary">
                Coming Soon
              </div>
            </div>
            <p className="mb-5 font-light leading-6 text-boulder">
              {t('dashboard_burn', { rewardSymbol })}
            </p>
            <Button disabled className="flex items-center space-x-2">
              <LockIcon className="relative bottom-[1px] h-4 w-4 text-quillGray" />
              <div>
                <span className="normal-case">Burn</span> ${rewardSymbol}
              </div>
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

function AssetCard({
  amount,
  logoLink,
  projectName,
  symbol,
  valueUSD,
  websiteLink,
}: Asset) {
  const handleClick = () => {
    openUrl(websiteLink)
  }
  const amount_ = isNaN(Number(amount))
    ? amount
    : `${formatNumber(amount)} ${symbol}`

  const usd_ = isNaN(Number(valueUSD))
    ? valueUSD
    : `${formatNumber(valueUSD)} USD`

  return (
    <div
      className="hover:chakra-item-gradient min-h-[128px] cursor-pointer rounded-lg border border-black/5 p-3 hover:border-white"
      onClick={handleClick}
    >
      <div className="flex space-x-3">
        <img alt="" src={logoLink} className="h-[43px] w-[43px] rounded-lg " />
        <div className="text-lg font-light leading-[1.4] text-brightGray">
          <div>{projectName}</div>
          <div className="font-medium">${symbol}</div>
        </div>
      </div>
      <div className="mt-2 flex justify-between space-x-4 leading-[1.2] ml:justify-start">
        <div>
          <div className="text-sm text-friarGray">Amount</div>
          <div className="text-base text-brightGray">
            {amount === '0' ? 'To be announced' : amount_}
          </div>
        </div>
        <div>
          <div className="text-sm text-friarGray">Value</div>
          <div className="text-base font-medium text-brightGray">
            {valueUSD === '0' ? 'To be announced' : usd_}
          </div>
        </div>
      </div>
    </div>
  )
}
