import Block from 'components/dumb/Block'
import Tab from 'components/dumb/StakingPageTerminalTab'
import { LM } from 'models/lm/lm'
import React, { useState } from 'react'
import t from 'utils/t'
import ClaimTerminal from './ClaimTerminal'
import StakeTerminal from './StakeTerminal'
import UnstakeTerminal from './UnstakeTerminal'
import { useStore } from 'effector-react'
import { $session } from 'models/sessions'
import { $selectedChainId, $wallet } from 'models/wallet'
import { ProviderId } from 'models/wallet/wallets'
import TerminalWrongNetwork from 'components/collections/TerminalWrongNetwork'
import { getChainId } from 'utils/blockchain'
import { networks } from 'utils/constants'

type TabButtonType = 'stake' | 'claim' | 'unstake'

type Props = { pool: LM }
export default function SmartLiquidityMiningTerminal({ pool }: Props) {
  const [currentTab, setCurrentTab] = useState<TabButtonType>('stake')

  const handleTabClick = (tab: TabButtonType) => {
    setCurrentTab(tab)
  }

  const connected = useStore($session.map(Boolean))
  const wallet = useStore($wallet)
  const selectedChainId = useStore($selectedChainId)

  const content = React.useMemo(() => {
    switch (currentTab) {
      case 'stake':
        return <StakeTerminal pool={pool} />
      case 'unstake':
        return <UnstakeTerminal pool={pool} />
      case 'claim':
        return <ClaimTerminal pool={pool} />
    }
  }, [currentTab, pool])

  const lmChainId = getChainId(pool.blockchain)
  const networkInfo = networks[lmChainId]

  if (
    connected &&
    selectedChainId !== lmChainId &&
    wallet?.id !== ProviderId.WalletConnect
  ) {
    return (
      <TerminalWrongNetwork
        blockchain={pool.blockchain}
        terminalTitle={t('staking_terminal')}
        heading={`This liquidity mining pool is available on ${networkInfo.label} only`}
      />
    )
  }

  return (
    <Block containerClassName="!px-5 flex flex-col min-h-[307px]">
      <div className="flex h-full grow flex-col justify-between">
        <div>
          <div className="mb-2 flex flex-col justify-between ml:flex-row">
            <h2 className="mb-2 text-2xl font-light leading-[1.3] text-codGray ml:mb-0">
              {t('staking_terminal')}
            </h2>
            <div className="flex items-center space-x-2">
              <Tab
                onClick={() => handleTabClick('stake')}
                current={currentTab === 'stake'}
              >
                {t('stake')}
              </Tab>
              <Tab
                onClick={() => handleTabClick('unstake')}
                current={currentTab === 'unstake'}
              >
                {t('unstake')}
              </Tab>
              <Tab
                onClick={() => handleTabClick('claim')}
                current={currentTab === 'claim'}
              >
                {t('claim')}
              </Tab>
            </div>
          </div>
          {currentTab !== 'claim' && (
            <div className="mb-4 w-full text-base font-light leading-[150%] text-gray dxs:mb-[1.45rem]">
              {t('staking_lm_terminal_text', {
                liquidityTokenPrettyName: pool.liquidityTokenPrettyName,
                rewardSymbol: pool.rewardsSymbol,
              })}
            </div>
          )}
        </div>
        {content}
      </div>
    </Block>
  )
}
