import { useSpring } from '@react-spring/web'
import isEmpty from 'lodash/isEmpty'
import { useRef, useState } from 'react'

export const ROMAN_TIER_NAME: { [key: string]: string } = {
  0: '-',
  1: 'VCDAO',
  2: 'Tier 0',
  3: 'Tier I',
  4: 'Tier II',
  5: 'Tier III',
  6: 'Tier IV',
  7: 'Tier V',
}

export function useAnimateTierImage() {
  const imgRef = useRef<HTMLImageElement>(null)
  const [xy, setXy] = useState([0, 0])
  const [scaleTo, setScaleTo] = useState(1)

  const springs = useSpring({
    x: xy[0],
    y: xy[1],
    scale: scaleTo,
  })

  const handleMouseLeave = () => {
    setXy([0, 0])
    setScaleTo(1)
  }

  const handleMouseMove = (e: React.MouseEvent) => {
    setScaleTo(2)
    if (imgRef.current) {
      const rect = imgRef.current.getBoundingClientRect()
      setXy(calcXy(e.clientX, e.clientY, rect))
    }
  }

  return { springs, handleMouseLeave, handleMouseMove, imgRef }
}

export const calcXy = (x: number, y: number, rect: DOMRect) => [
  -(x - rect.left - rect.width / 2),
  -(y - rect.top - rect.height / 2),
]

export const getNftUrl = (address: string) => {
  if (isEmpty(address)) return undefined
  return `https://static-nft.occam.fi/images/${address}.png`
}

//'https://static-nft.occam.fi/images/0x00a5eeca852d03c08f50fc8d75991b50dab953c9.png'

export const getNftOpenseaLink = (address: string) => {
  const tokenId = BigInt(address).toString()
  return `https://opensea.io/assets/ethereum/0x1385fd0c673d8f8e1501fdaf4d74c1d8c0f8303b/${tokenId}`
}
