export default function ChakraPoolSkeleton() {
  return (
    <div className="hidden h-full w-full dxs:block">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 948 172"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <rect width="330" height="172" rx="10" fill="#F4F5F7" />
        <rect x="15" y="20" width="231" height="18" rx="5" fill="#DDE4EE" />
        <rect x="15" y="58" width="249" height="40" rx="8" fill="#DDE4EE" />
        <rect x="274" y="73" width="46" height="25" rx="6" fill="#DDE4EE" />
        <rect x="15" y="124" width="183" height="33" rx="6" fill="#DDE4EE" />
        <rect x="363" y="28" width="170" height="16" rx="4" fill="#DDE4EE" />
        <rect x="363" y="55" width="76" height="19" rx="5" fill="#DDE4EE" />
        <rect x="363" y="104" width="217" height="16" rx="4" fill="#DDE4EE" />
        <rect x="363" y="131" width="123" height="19" rx="5" fill="#DDE4EE" />
        <rect x="608" width="340" height="172" rx="10" fill="#DDE4EE" />
        <rect x="654" y="103" width="248" height="40" rx="8" fill="#ECF1F8" />
        <rect x="683" y="37" width="190" height="16" rx="4" fill="#ECF1F8" />
        <rect x="732" y="61" width="92" height="16" rx="4" fill="#ECF1F8" />
      </svg>
    </div>
  )
}
