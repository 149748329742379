import { sample } from 'effector'
import { DistributionCed } from 'gql'
import { $session } from 'models/sessions'
import {
  $cedRewardsNew,
  $cedRewardsOld,
  $chakraInfo,
  $indexTokenInfo,
  fetchCedRewards,
  fetchChakraInfo,
  fetchChakraInfoFx,
  fetchNewFx,
  fetchOldFx,
} from '.'
import { ChakraInfo } from './chakraInfo'

sample({
  clock: fetchChakraInfo,
  target: fetchChakraInfoFx,
})

sample({
  clock: fetchCedRewards,
  source: $session,
  filter: Boolean,
  target: [fetchOldFx, fetchNewFx],
})

sample({
  clock: fetchChakraInfoFx.doneData,
  fn: (data) => {
    return new ChakraInfo(data)
  },
  target: $chakraInfo,
})

sample({
  clock: fetchChakraInfoFx.doneData,
  fn: (data) => data.indexTokenInfo,
  target: $indexTokenInfo,
})
sample({
  clock: $session,
  filter: Boolean,
  target: fetchCedRewards,
})

sample({
  clock: fetchNewFx.doneData,
  fn: (data) => data.cedRewards?.filter((v) => v !== null) as DistributionCed[],
  target: $cedRewardsNew,
})

sample({
  clock: fetchOldFx.doneData,
  fn: (data) => data.cedRewards?.filter((v) => v !== null) as DistributionCed[],
  target: $cedRewardsOld,
})
fetchCedRewards()
fetchChakraInfo()
