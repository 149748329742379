import classNames from 'classnames'
import { ReactNode } from 'react'
import Block from './Block'

export interface Props {
  title: string | ReactNode
  description?: string | ReactNode
  tierImage?: ReactNode
  bgImage?: string
  nftImageUrl?: string
  button?: ReactNode
  wrapperClassName?: string
  containerClassName?: string
  innerClassName?: string
  descriptionClassName?: string
  titleClassName?: string
}

export default function ProjectPageTier({
  bgImage = '',
  nftImageUrl,
  wrapperClassName = '',
  containerClassName = '',
  innerClassName = '',
  descriptionClassName = '',
  titleClassName = '',
  ...props
}: Props) {
  return (
    <Block
      bgImage={bgImage}
      nftImageUrl={nftImageUrl}
      wrapperClassName={`flex ml:col-span-1 col-span-2 overflow-hidden ${wrapperClassName}`}
      containerClassName={`flex px-0 py-0 ${containerClassName}`}
    >
      <div className="relative z-10  rounded-lg ">
        <div className="relative z-10 flex h-full flex-col justify-between dl:p-[1.5rem]">
          <div className={`flex flex-1 flex-col ${innerClassName}`}>
            <div
              className={classNames(
                'mb-2 text-[1.8rem] font-medium leading-[120%] text-brightGray mm:leading-[2.8rem] t:text-[2.35rem]',
                titleClassName
              )}
            >
              {props.title}
            </div>
            {props.description && (
              <div
                className={classNames(
                  'max-w-full text-[1rem] font-light leading-[120%] text-brightGray mm:text-[1.2rem] t:text-[1.4rem] t:leading-8',
                  descriptionClassName
                )}
              >
                {props.description}
              </div>
            )}
          </div>
          <div>{props.button}</div>
        </div>
      </div>
    </Block>
  )
}
