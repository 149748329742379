import Decimal from 'decimal.js'
import {
  ApyPerTier,
  Maybe,
  Scalars,
  StakingInfoMultichainQuery,
  StakingInfoQuery,
  StakingTier,
} from 'gql'
import numeral from 'numeral'
import { getBlockchainNameByChainId } from 'utils/blockchain'
import { formatNumeral, printNumberThresholds, toDecimal } from 'utils/numbers'

type StakingInfoMultichain = StakingInfoMultichainQuery['stakingInfoMultichain']
type VotingPower = StakingInfoQuery['votingPower']
type StakingInfoChakra = StakingInfoQuery['stakingInfoCHAKRA']
type StakingInfo = StakingInfoQuery['stakingInfo']

type Apy = {
  accountAPY?: Maybe<Scalars['Decimal']>
  apyPerTier?: Array<ApyPerTier>
  tokensEarningPerYear?: Maybe<Scalars['Decimal']>
}

export interface StakingTierMapped extends StakingTier {
  votingPower: string
  apy: string
  tokenEarningsPerYear: string
}

export class Staking {
  address: string
  allowanceDec: Decimal
  currentChainApy: string
  apyInfo?: Apy
  hasTier: boolean
  leftToBeMined: string
  level: number
  minedPerDay: string
  occRewardAvailable: Decimal
  ok: boolean
  original?: StakingInfoMultichain
  pending: boolean
  priceRewardTokenUSD: string
  priceStakingTokenUSD: string
  rewardAvailable: string
  rewardAvailableDec: Decimal
  rewardPending: string
  rewardSymbol: string
  rewardTotal: string
  rewardTotalDec: Decimal
  stakingBalanceCurrentNetworkDec: Decimal
  stakingInfoBsc?: StakingInfo
  stakingInfoEth?: StakingInfo
  stakingSymbol: string
  stakingTokenAddress: string
  stakingTokenDecimals: number
  stakingWalletBalanceDec: Decimal
  tier5MinStake: string
  tierName: string
  tiers: StakingTierMapped[]
  totalRewardToDistribute: string
  totalStakingSupply: string
  unstakingFeeRatio: string
  unstakingFeeRatioDec: Decimal
  walletBalance: string
  walletBalanceDec: Decimal

  constructor({
    walletBalanceDec,
    stakingInfoMultichain,
    votingPower,
    apyInfo,
    chakraInfo,
    selectedChainId,
  }: {
    walletBalanceDec: Decimal
    stakingInfoMultichain?: StakingInfoMultichain
    votingPower?: VotingPower
    apyInfo?: Apy
    chakraInfo?: StakingInfoChakra
    selectedChainId: string
  }) {
    this.original = stakingInfoMultichain
    const blockchain = getBlockchainNameByChainId(selectedChainId)
    const stakingInfo =
      stakingInfoMultichain?.stakingInfo.find(
        (data) => data.blockchain === blockchain
      ) ?? stakingInfoMultichain?.stakingInfo?.[0]

    this.stakingInfoBsc = stakingInfoMultichain?.stakingInfo.find(
      (data) => data.blockchain === 'bsc'
    )

    this.stakingInfoEth = stakingInfoMultichain?.stakingInfo.find(
      (data) => data.blockchain === 'ethereum'
    )
    this.currentChainApy = numeral(
      toDecimal(stakingInfo?.apy).toString()
    ).format('0.[00]%')

    const tiers = stakingInfoMultichain?.tiers ?? []

    this.ok = !!stakingInfoMultichain?.account
    // @TODO:
    this.pending = !Boolean(stakingInfoMultichain)

    this.walletBalanceDec = walletBalanceDec
    this.walletBalance = walletBalanceDec.toString()

    this.address = stakingInfo?.address ?? ''
    this.rewardSymbol = chakraInfo?.rewardToken.symbol ?? ''
    this.stakingSymbol = stakingInfo?.stakingToken?.symbol ?? ''
    this.apyInfo = apyInfo
    this.stakingBalanceCurrentNetworkDec = toDecimal(
      stakingInfo?.account?.stakingBalance ?? 0
    )

    this.tiers =
      tiers.map((tier, i) => ({
        ...tier,
        votingPower: votingPower ? votingPower[i] : '0',
        apy: apyInfo?.apyPerTier?.[i].apy ?? '0',
        tokenEarningsPerYear:
          apyInfo?.apyPerTier?.[i].tokensEarningPerYear ?? '0',
      })) ?? []

    this.tier5MinStake = tiers && tiers.length > 0 ? tiers[0].minStake : ''

    this.leftToBeMined = numeral(
      toDecimal(stakingInfo?.totalRewardToDistribute).sub(
        toDecimal(stakingInfo?.totalEmittedRewards)
      )
    ).format('0,0')

    this.totalRewardToDistribute = numeral(
      stakingInfo?.totalRewardToDistribute
    ).format('0,0')

    this.totalStakingSupply = numeral(
      stakingInfo?.stakingToken.totalSupply
    ).format('0,0')

    const rewardPerSecondDec = toDecimal(
      stakingInfo?.currentEpoch?.rewardsPerSecond ?? '0'
    )

    this.minedPerDay = !rewardPerSecondDec.eq(0)
      ? printNumberThresholds(rewardPerSecondDec.mul(24 * 60 * 60))
      : 'TBA'

    this.rewardAvailable = stakingInfo?.account?.rewardAvailable ?? '0'
    this.rewardTotal = stakingInfo?.account?.rewardTotal ?? '0'
    this.rewardTotalDec = toDecimal(stakingInfo?.account?.rewardTotal ?? '0')
    this.rewardAvailableDec = toDecimal(
      stakingInfo?.account?.rewardAvailable ?? '0'
    )
    this.rewardPending = stakingInfo?.account?.rewardPending ?? '0'

    this.occRewardAvailable = toDecimal(
      stakingInfo?.account?.rewardAvailable ?? '0'
    )

    this.stakingTokenAddress = stakingInfo?.stakingToken.address ?? ''
    this.stakingTokenDecimals = stakingInfo?.stakingToken.decimals ?? 0

    this.unstakingFeeRatio = toDecimal(stakingInfo?.unstakingFeeRatio ?? '0')
      .mul(100)
      .toString()

    this.unstakingFeeRatioDec = toDecimal(stakingInfo?.unstakingFeeRatio ?? '0')

    this.allowanceDec = toDecimal(stakingInfo?.account?.allowance ?? 0).minus(
      0
      /* TODO: ???? */
      /*toDecimal(stakingInfo?.account?.stakingBalance ?? 0) */
    )

    this.stakingWalletBalanceDec = toDecimal(
      stakingInfo?.account?.walletBalance ?? 0
    )

    this.level = stakingInfoMultichain?.account?.tier?.level ?? 0
    this.tierName = stakingInfoMultichain?.account?.tier?.name ?? '-'
    this.hasTier =
      typeof stakingInfoMultichain?.account?.tier?.level === 'number'

    this.priceRewardTokenUSD = stakingInfo?.priceRewardTokenUSD ?? '0'
    this.priceStakingTokenUSD =
      stakingInfoMultichain?.priceStakingTokenUSD ?? '0'
  }

  stakeTerminalState(input: string) {
    const amountDecimal = toDecimal(input)
    if (!this.ok) {
      return 'walletNotConnected'
    } else if (amountDecimal.gt(this.walletBalanceDec)) {
      return 'insufficientBalance'
    } else if (this.allowanceDec.lessThan(amountDecimal)) {
      return 'allowanceExceeded'
    } else if (this.walletBalanceDec.eq(0) || amountDecimal.eq(0)) {
      return 'notReadyToStake'
    } else if (input === '') {
      return 'waitingForInput'
    }
    return 'readyToStake'
  }

  get stakingBalanceDec() {
    return this.stakingBalanceCurrentNetworkDec
  }

  get stakingBalance() {
    return this.stakingBalanceCurrentNetworkDec.toString()
  }

  unstakeTerminalState(input: string) {
    const amountDecimal = toDecimal(input)

    if (!this.ok) {
      return 'walletNotConnected'
    } else if (this.stakingBalanceDec.eq(0) || amountDecimal.eq(0)) {
      return 'notReadyToUnstake'
    } else if (input === '') {
      return 'waitingForInput'
    } else if (amountDecimal.gt(this.stakingBalanceDec)) {
      return 'insufficientBalance'
    }
    return 'readyToUnstake'
  }

  get claimTerminalState() {
    if (!this.ok) {
      return 'walletNotConnected'
    } else if (this.rewardAvailableDec.eq(0) && this.occRewardAvailable.eq(0)) {
      return 'notReadyToClaim'
    }
    return 'readyToClaim'
  }

  get chakraApy() {
    let chakraAPY_ = this?.apyInfo?.accountAPY
      ? toDecimal(this.apyInfo?.accountAPY)
      : toDecimal(0)

    const chakraAPY = formatNumeral(chakraAPY_, { formatString: '0.00%' })
    return chakraAPY
  }

  get chakraApyDec() {
    return toDecimal(this?.apyInfo?.accountAPY)
  }

  get tokenEarningsPerYear() {
    return this.apyInfo?.tokensEarningPerYear
  }

  get apyDec() {
    const connected = Boolean(this.original?.account)
    return toDecimal(
      connected ? this.original?.account?.apy : this.original?.apy
    )
  }

  get apy() {
    return !this.apyDec.eq(0)
      ? `${numeral(this.apyDec.mul(100).toString()).format('0.00')}%`
      : 'TBA'
  }

  get yourApyNotConnected() {
    const tier5 = this.tiers[0]
    const chakraApy = toDecimal(tier5?.apy)
    const bscApy = toDecimal(this.stakingInfoBsc?.apy)
    const ethApy = toDecimal(this.stakingInfoEth?.apy)
    const result = chakraApy.add(bscApy.div(2)).add(ethApy.div(2))
    return numeral(result).format('0.[00]%')
  }

  get yourApy() {
    const tier5 = this.tiers[0]
    let yourApyDec: Decimal = toDecimal(0)

    if (this.stakingBalanceDec.gt(0)) {
      yourApyDec = this.hasTier
        ? toDecimal(this.chakraApyDec).add(this.apyDec)
        : this.apyDec
    } else {
      yourApyDec = toDecimal(tier5?.apy).add(this.apyDec)
    }
    const yourApy = numeral(yourApyDec).format('0.[00]%')
    return yourApy
  }

  get totalStakedDec() {
    const total = this.original?.stakingInfo.reduce(
      (acc, curr) => toDecimal(curr.totalStaked).add(acc),
      toDecimal(0)
    )
    return total ?? toDecimal(0)
  }

  get totalStaked() {
    return numeral(this.totalStakedDec).format('0,0')
  }

  get totalStakedUSD() {
    return numeral(this.totalStakedDec.mul(this.priceStakingTokenUSD)).format(
      '0,00'
    )
  }

  get uniqueStakers() {
    const total =
      this.original?.stakingInfo.reduce(
        (acc, curr) => toDecimal(curr.uniqueStakers).add(acc),
        toDecimal(0)
      ) ?? toDecimal(0)
    return numeral(total).format('0,00')
  }

  get stakingBalanceEthDec() {
    return toDecimal(this.stakingInfoEth?.account?.stakingBalance)
  }

  get stakingBalanceBscDec() {
    return toDecimal(this.stakingInfoBsc?.account?.stakingBalance)
  }

  get totalStakingBalance() {
    return numeral(
      this.stakingBalanceEthDec.add(this.stakingBalanceBscDec)
    ).format('0.[00]')
  }

  get stakingApyBsc() {
    return numeral(toDecimal(this.stakingInfoBsc?.apy).toString()).format(
      '0.[00]%'
    )
  }
  get stakingApyEth() {
    return numeral(toDecimal(this.stakingInfoEth?.apy).toString()).format(
      '0.[00]%'
    )
  }
}
