import classNames from 'classnames'
import Footer from 'components/dumb/Footer'
import StakingPageTiers from 'components/dumb/StakingPageTiers'
import SmartCampaignDetails from 'components/smart/SmartCampaignDetails'
import SmartDashboard from 'components/smart/SmartDashboard/SmartDashboard'
import SmartHeader from 'components/smart/SmartHeader'
import SmartHome from 'components/smart/SmartHome'
import SmartLiquidityMining from 'components/smart/SmartLiquidityMining'
import SmartProjectDetails from 'components/smart/SmartProjectDetails'
import SmartRegister from 'components/smart/SmartRegister'
import SmartCampaignRegister from 'components/smart/SmartRegister/SmartCampaignRegister'
import SmartStaking from 'components/smart/SmartStaking'
import SmartTransaction from 'components/smart/SmartTransaction'
import SmartWallet from 'components/smart/SmartWallet'
import SmartWalletButton from 'components/smart/SmartWalletButton/ButtonWithTiers'
import { useStore } from 'effector-react'
import { setWindowWidth } from 'models/app'
// import CedBanner from 'components/smart/CedBanner'
import { $cedBannerOpen } from 'models/rewards'
import { $staking } from 'models/staking'
import { $address } from 'models/wallet'
import { useEffect, useRef } from 'react'
import { Toaster } from 'react-hot-toast'
import { RouteObject, useLocation, useRoutes } from 'react-router-dom'
import useRect from 'utils/hooks/useRect'
import useResizeObserver from 'utils/hooks/useResizeObserver'

const routes: RouteObject[] = [
  {
    path: '/',
    element: <SmartHome />,
  },
  { path: '/staking', element: <SmartStaking /> },
  { path: '/chakra', element: <SmartDashboard /> },
  { path: '/liquidity-mining/:address', element: <SmartLiquidityMining /> },
  { path: '/nodesale/bluwhale', element: <SmartCampaignDetails /> },
  {
    path: '/pool/:id',
    element: <SmartProjectDetails />,
  },
  {
    path: '*',
    element: <div className="text-grayLight">Not found</div>,
  },
]

export default function App() {
  const element = useRoutes(routes)
  const staking = useStore($staking)
  const address = useStore($address)
  const cedBannerOpen = useStore($cedBannerOpen)
  const containerRef = useRef(null)
  const rect = useRect(containerRef)
  const { pathname } = useLocation()

  const { width } = useResizeObserver(containerRef)

  useEffect(() => {
    setWindowWidth(width)
  }, [width])

  return (
    <div className="relative flex min-h-[100vh] flex-col overflow-hidden bg-porcelain bg-no-repeat">
      <div className="absolute left-1/2 h-[57rem] w-full max-w-[2560px] -translate-x-1/2 bg-mountainBg bg-no-repeat " />
      <div className="absolute right-7 top-7 z-40 hidden h-[100px] w-[100px] ds:block ">
        <SmartWalletButton shadow />
      </div>
      <SmartHeader />
      {/* <CedBanner /> */}
      <div
        ref={containerRef}
        className="
        relative m-auto w-[300px] grow items-start
        mm:w-[90%] ml:w-[590px] t:w-[80%] dxs:w-[980px] dl:w-[1200px]
        "
      >
        <div
          className={classNames({
            'mt-[40px] t:mt-[80px]': !cedBannerOpen,
            'mt-[80px] ml:mt-[60px] dxs:mt-[80px] ds:mt-[120px]': cedBannerOpen,
          })}
        >
          {element}
        </div>
      </div>

      {pathname === '/staking' ? (
        <div className="mt-[60px] block dxs:mt-[48px] dxs:hidden">
          <StakingPageTiers
            level={staking.level}
            tiers={staking.tiers}
            stakingSymbol={staking.stakingSymbol}
            hasTier={staking.hasTier}
            address={address}
            leftOffset={rect.offsetLeft}
            pending={staking.pending}
          />
        </div>
      ) : null}
      <SmartWallet />
      <SmartTransaction />
      <SmartRegister />
      <SmartCampaignRegister />
      <Toaster position="bottom-right" reverseOrder={false} />
      <Footer />
    </div>
  )
}
