import Decimal from 'decimal.js'
import { attach, createEvent, createStore } from 'effector'
import { createGate } from 'effector-react'
import { ethers } from 'ethers'
import { graphqlSdk } from 'gql/client'
import { $session } from 'models/sessions'
import { $address, $provider } from 'models/wallet'
import invariant from 'tiny-invariant'
import { getChainId } from 'utils/blockchain'
import { toDecimal } from 'utils/numbers'
import { getNftOpenseaLink } from 'utils/tier'
import { stakingAbi, tokenAbi } from './abi'
import { Staking } from './staking'

export const WalletBalanceGate = createGate()

export const $staking = createStore<Staking>(
  new Staking({
    walletBalanceDec: toDecimal(0),
    stakingInfoMultichain: undefined,
    votingPower: undefined,
    selectedChainId: getChainId('ethereum'),
  })
)

export const fetchStakingInfo = createEvent()
export const stakingTick = createEvent()
export const openOpenseaLink = createEvent()

export const $stakingTokenWalletBalanceDec = createStore(new Decimal(0))
export const $stakingTokenWalletBalance = $stakingTokenWalletBalanceDec.map(
  (d) => d.toString()
)

export const $approveSent = createStore(false)
export const $stakeSent = createStore(false)
export const $unstakeSent = createStore(false)
export const $claimSent = createStore(false)
export const $stakingNetworkIsOk = createStore(false)

export const fetchStakingInfoFx = attach({
  source: $session,
  async effect(session) {
    const authorized = Boolean(session)
    const args = {
      session: authorized ? session : null,
    }
    return await graphqlSdk.StakingInfoMultichain(args)
  },
})

export const addStakeFx = attach({
  source: [$staking, $provider],
  async effect([staking, provider], rawAmount: string) {
    invariant(provider, 'web3 provider not found')
    invariant(staking.address, 'staking contract not specified')

    const mlt = toDecimal(10).pow(staking.stakingTokenDecimals)
    const amount = toDecimal(rawAmount).mul(mlt).floor().toString()

    const signer = provider.getSigner()
    const stakingContract = new ethers.Contract(
      staking.address,
      stakingAbi,
      signer
    )

    const gasLimit = await stakingContract.estimateGas.createStake(amount)
    const res = await stakingContract.createStake(amount, {
      gasLimit,
    })
    return `${res?.hash ?? ''}`
  },
})

export const approveStakeFx = attach({
  source: [$staking, $provider],
  async effect([staking, provider]) {
    invariant(provider, 'web3 provider not found')
    invariant(staking.address, 'staking contract not specified')

    const signer = provider.getSigner()
    const tokenContract = new ethers.Contract(
      staking.stakingTokenAddress,
      tokenAbi,
      signer
    )

    const res = await tokenContract.approve(
      staking.address,
      '10000000000000000000000000000000000'
    )
    return `${res?.hash ?? ''}`
  },
})

export const removeStakeFx = attach({
  source: [$staking, $provider],
  async effect([staking, provider], rawAmount: string) {
    invariant(provider, 'web3 provider not found')
    invariant(staking.address, 'staking contract not specified')

    const mlt = toDecimal(10).pow(staking.stakingTokenDecimals)
    const amount = toDecimal(rawAmount).mul(mlt).floor().toString()
    const maximumFee = toDecimal(rawAmount)
      .mul(mlt)
      .mul(staking.unstakingFeeRatioDec)
      .ceil()
      .toString()

    const signer = provider.getSigner()
    const stakingContract = new ethers.Contract(
      staking.address,
      stakingAbi,
      signer
    )

    const gasLimit = await stakingContract.estimateGas.removeStake(
      amount,
      maximumFee
    )

    const res = await stakingContract.removeStake(amount, maximumFee, {
      gasLimit,
    })

    return `${res?.hash ?? ''}`
  },
})

export const getRewardFx = attach({
  source: [$staking, $provider],
  async effect([staking, provider]) {
    invariant(provider, 'web3 provider not found')
    invariant(staking.address, 'staking contract not specified')

    const signer = provider.getSigner()
    const stakingContract = new ethers.Contract(
      staking.address,
      stakingAbi,
      signer
    )

    const res = await stakingContract.getReward()
    return `${res?.hash ?? ''}`
  },
})

export const fetchStakingTokenWalletBalanceFx = attach({
  source: [$address, $staking, $provider],
  async effect([address, staking, provider]) {
    invariant(provider, 'web3 provider not found')

    if (address === '') {
      return new Decimal(0)
    }

    const contract = new ethers.Contract(
      staking.stakingTokenAddress,
      tokenAbi,
      provider
    )
    const balance = await contract.balanceOf(address)
    const denom = new Decimal(10).pow(staking.stakingTokenDecimals)
    return new Decimal(balance?._hex ?? 0).div(denom)
  },
})

export const openOpenseaLinkFx = attach({
  source: $address,
  async effect(address) {
    const href = getNftOpenseaLink(address)
    window.open(href, '_blank', 'noopener,noreferrer')
  },
})
