import Block from 'components/dumb/Block'
import ActivePoolCard from 'components/dumb/HomeActivePoolCard'
import HomeFinishedPoolsTable from 'components/dumb/HomeFinishedPoolsTable'
import UpcomingPoolCard from 'components/dumb/HomeUpcomingPoolCard'
import HomeCover1 from 'components/dumb/skeleton/HomeCover1'
import HomeCover2 from 'components/dumb/skeleton/HomeCover2'
import UpcomingSkeleton from 'components/dumb/skeleton/UpcomingSkeleton'
import { useStore } from 'effector-react'
import {
  $activePools,
  $finishedPools,
  $pools,
  $upcomingPools,
  fetchPools,
} from 'models/pools'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as TextLogo1 } from 'resources/images/header/text-logo1.svg'
import { ReactComponent as TextLogo2 } from 'resources/images/header/text-logo2.svg'
import useFetchInterval from 'utils/hooks/useFetchInterval'
import t from 'utils/t'
import HomePoolCampaignCard from '../../dumb/HomePoolCampaignCard'
import SmartHomeStats from './SmartHomeStats'

export default function SmartHome() {
  const activePools = useStore($activePools)
  const upcomingPools = useStore($upcomingPools)
  const finishedPools = useStore($finishedPools)
  const pending = useStore($pools.map((p) => p.length === 0))
  const navigate = useNavigate()

  useFetchInterval(fetchPools)

  const handleNavigatePool = (id: string) => {
    navigate(`/pool/${id}`)
  }

  return (
    <div className="">
      <p className="mb-[26px] select-none text-[22px] font-light leading-7 text-brightGray dxs:text-2xl dxs:leading-8">
        {t('welcom_to')}
      </p>
      <div className="flex flex-col items-start space-y-[30px] ml:flex-row ml:items-center ml:space-x-[30px] ml:space-y-0">
        <div className="h-auto w-[300px] ml:w-[320px] dxs:w-[419px]">
          <TextLogo1 className="h-full w-full" />
        </div>
        <div className="h-auto w-[280px] ml:w-[280px] dxs:w-[362px]">
          <TextLogo2 className="h-full w-full" />
        </div>
      </div>
      <div
        className="
          mt-[2.8rem] grid auto-rows-[13.75rem] grid-cols-1 gap-[1.25rem]
          t:grid-cols-2 dxs:mt-[4.6rem] dxs:grid-rows-[13.75rem]
          dm:gap-[1.87rem]
        "
      >
        {pending ? (
          <>
            <Block containerClassName="h-full">
              <HomeCover1 />
            </Block>

            <Block containerClassName="h-full">
              <HomeCover2 />
            </Block>
          </>
        ) : (
          <SmartHomeStats />
        )}
      </div>
      <div className="mt-[5.8ren]">
        {pending ? (
          <div className="relative mt-[6.25rem]">
            <h2 className="mb-5 mt-[5rem] whitespace-nowrap text-2xl font-light leading-8 text-brightGray ds:mb-6 ds:mt-[6.25rem]">
              {t('upcoming_projects')}
            </h2>
            <div
              className="
                grid auto-rows-[25.6rem] grid-cols-1 gap-x-[1.875rem] gap-y-[2.5rem]
                mm:auto-rows-[28.56rem] ml:auto-rows-[32.9rem] t:auto-rows-[36.6rem]
                dxs:auto-rows-[28.75rem] dxs:grid-cols-2 ds:auto-rows-[32rem]
              "
            >
              <UpcomingSkeleton />
              <UpcomingSkeleton />
            </div>
          </div>
        ) : null}

        <h2 className="mb-4 mt-[5rem] whitespace-nowrap text-3xl font-semibold leading-9 text-brightGray antialiased">
          {t('active_projects')}
        </h2>
        <div className="flex flex-col space-y-[1.875rem]">
          <HomePoolCampaignCard
            onClick={() => navigate('/nodesale/bluwhale')}
            id="bluwhale"
          />
          {activePools.map((pool) => (
            <ActivePoolCard
              key={`pool-card-a-${pool.address}`}
              onClick={handleNavigatePool}
              {...pool.getHomeActiveCardProps()}
            />
          ))}
        </div>

        {upcomingPools.length > 0 ? (
          <div className="relative mt-[6.25rem]">
            <h2 className="mb-5 mt-[5rem] whitespace-nowrap text-2xl font-light leading-8 text-brightGray ds:mb-6 ds:mt-[6.25rem]">
              {t('upcoming_projects')}
            </h2>
            <div
              className="
                grid auto-rows-[25.6rem] grid-cols-1 gap-x-[1.875rem] gap-y-[2.5rem]
                mm:auto-rows-[28.56rem] ml:auto-rows-[32.9rem] t:auto-rows-[36.6rem]
                dxs:auto-rows-[28.75rem] dxs:grid-cols-2 ds:auto-rows-[32rem]
              "
            >
              {upcomingPools.map((pool) => (
                <UpcomingPoolCard
                  key={`pool-card-u-${pool.address}`}
                  onClick={handleNavigatePool}
                  {...pool.getHomeUpcomingCardProps()}
                />
              ))}
            </div>
          </div>
        ) : null}

        {finishedPools.length > 0 ? (
          <>
            <h2 className="mb-5 mt-[5rem] whitespace-nowrap text-2xl font-light leading-8 text-brightGray ds:mb-6 ds:mt-[6.25rem]">
              {t('finished_projects')}
            </h2>
            <HomeFinishedPoolsTable
              finishedPools={finishedPools}
              onClick={handleNavigatePool}
            />
          </>
        ) : null}
      </div>
    </div>
  )
}
