import { ETHEREUM_MAINNET_CHAIN_ID, networks } from './constants'

export const noop = () => {}
export const tw = (classNames: TemplateStringsArray) => `${classNames}`
export const openUrl = (url?: string) => {
  if (!url) return
  window.open(url, '_blank', 'noopener,noreferrer')
}
export const shortenAddress = (address: string) =>
  address.length > 4
    ? `${address.slice(0, 4)}...${address.slice(
        address.length - 4,
        address.length
      )}`
    : ''

export const getAddressExplorerUrl = (address: string, chainId?: string) => {
  const _chainId = chainId ?? ETHEREUM_MAINNET_CHAIN_ID
  const info = networks[_chainId] ?? networks[ETHEREUM_MAINNET_CHAIN_ID]
  return `${info.explorer}/address/${address}`
}

export const getTxExplorerUrl = (hash: string, chainId: string) => {
  const info = networks[chainId] ?? networks[ETHEREUM_MAINNET_CHAIN_ID]
  return `${info.explorer}/tx/${hash}`
}
