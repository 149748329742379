import ModalHeader from 'components/dumb/ModalHeader'
import { useStore } from 'effector-react'
import {
  $address,
  $selectedChainId,
  $wallet,
  closeWallet,
  logout,
} from 'models/wallet'
import { getWalletIconById, getWalletNameById } from 'models/wallet/wallets'
import { ReactComponent as LinkIcon } from 'resources/images/icons/external-link.svg'
import { ReactComponent as LogoutIcon } from 'resources/images/icons/logout.svg'
import { getAddressExplorerUrl, openUrl, shortenAddress } from 'utils'
import t from 'utils/t'
import WalletKyc from './WalletKyc'
import WalletTier from './WalletTier'
import { ETHEREUM_MAINNET_CHAIN_ID } from 'utils/constants'
import Button from 'components/dumb/Button'
import { setOpenCedRewards } from 'models/wallet'
import { $cedIndicator } from 'models/rewards'

export default function WalletConnected() {
  const wallet = useStore($wallet)
  const address = useStore($address)
  const selectedChainId = useStore($selectedChainId)
  const cedIndicator = useStore($cedIndicator)

  const showKyc = process.env.REACT_APP_SHOW_KYC === 'true'

  if (!wallet) {
    return null
  }

  const name = getWalletNameById(wallet.id)
  const icon = getWalletIconById(wallet.id)

  return (
    <div>
      <ModalHeader label={t('connected_wallet')} onRightClick={closeWallet} />
      <div className="w-full">
        <div className="flex flex-1 items-center rounded-md border border-quillGray">
          <div className="flex flex-1 cursor-default items-center justify-between px-4 py-3">
            <button
              className="flex cursor-pointer items-center"
              onClick={() =>
                openUrl(
                  getAddressExplorerUrl(
                    address,
                    selectedChainId ?? ETHEREUM_MAINNET_CHAIN_ID
                  )
                )
              }
            >
              <p className="text-sm font-medium text-primary">
                {shortenAddress(address)}
              </p>
              <LinkIcon className="ml-1 h-3 w-3 fill-current text-primary" />
            </button>
            <div className="flex items-center space-x-4">
              <LogoutIcon
                className="cursor-pointer fill-current text-primary"
                onClick={() => logout()}
              />
              <div className="relative h-8 w-8 border border-quillGray">
                <img
                  className="absolute left-1/2 top-1/2 h-5 w-5 -translate-x-1/2 -translate-y-1/2"
                  src={icon}
                  alt={name}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-1 flex-col rounded-md border border-quillGray p-4">
          {cedIndicator > 0 ? (
            <>
              <Button
                onClick={() => setOpenCedRewards(true)}
                className="w-full border !border-primary !text-primary"
                type="tertiary"
              >
                CLAIM CED REWARDS ({cedIndicator})
              </Button>
              <div className="my-4 w-full border-t border-grayDA" />
            </>
          ) : null}
          <WalletTier />
          {showKyc && <WalletKyc />}
        </div>
      </div>
    </div>
  )
}
