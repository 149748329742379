import classNames from 'classnames'
import Button from 'components/dumb/Button'
import ModalHeader from 'components/dumb/ModalHeader'
import { useStore } from 'effector-react'
import { DistributionCed } from 'gql'
import {
  $cedIndicator,
  $cedRewardsNew,
  $cedRewardsOld,
  claimCedNew,
  claimCedOld,
} from 'models/rewards'
import { $stakingNetworkIsOk } from 'models/staking'
import { closeWallet, setOpenCedRewards, switchNetworkFx } from 'models/wallet'
import { useState } from 'react'
import { getChainId } from 'utils/blockchain'
import { printNumberThresholds } from 'utils/numbers'
import t from 'utils/t'

export default function WalletCEDRewards() {
  const cedRewardsNew = useStore($cedRewardsNew)
  const cedRewardsOld = useStore($cedRewardsOld)
  const cedIndicator = useStore($cedIndicator)
  const stakingNetworkIsOk = useStore($stakingNetworkIsOk)

  const hasNew = cedRewardsNew.length > 0
  const hasOld = cedRewardsOld.length > 0
  const i = hasNew ? 0 : hasOld ? 1 : -1

  const [currentBatch, setCurrentBatch] = useState(i)

  const display = currentBatch === 0 ? cedRewardsNew : cedRewardsOld

  const handleClick = () => {
    if (stakingNetworkIsOk) {
      if (currentBatch === 0) {
        claimCedNew()
      } else if (currentBatch === 1) {
        claimCedOld()
      }
      return
    }
    const chainId = getChainId('ethereum')
    switchNetworkFx(chainId)
  }

  return (
    <div>
      <ModalHeader
        label={t('ced_rewards')}
        onLeftClick={() => setOpenCedRewards(false)}
        onRightClick={closeWallet}
      />
      <div className="relative flex">
        {hasNew ? (
          <Tab
            label="Batch One"
            current={currentBatch === 0}
            onClick={() => setCurrentBatch(0)}
          />
        ) : null}
        {hasOld ? (
          <Tab
            label={cedIndicator === 2 ? 'Batch Two' : 'Batch One'}
            current={currentBatch === 1}
            onClick={() => setCurrentBatch(1)}
            className={hasNew ? 'ml-2' : ''}
          />
        ) : null}
        <div className="absolute bottom-0 z-[-1] h-[1px] w-full bg-quillGray"></div>
      </div>
      <div className="no-scrollbar max-h-[60vh] overflow-x-hidden overflow-y-scroll ">
        <div className="my-4 flex flex-col space-y-2">
          {display.map((ced, i) => (
            <Item key={i} {...ced} />
          ))}
        </div>
      </div>
      <Button className="w-full" type="primary" onClick={handleClick}>
        {stakingNetworkIsOk ? 'Claim all' : 'Switch network'}
      </Button>
    </div>
  )
}

function Item({ tokenName, tokenSymbol, amount, img }: DistributionCed) {
  const handleClick = () => {}
  const logo = img ?? ''

  return (
    <div
      className="rounded-md border border-quillGray px-4 py-3"
      onClick={handleClick}
    >
      <div className="flex items-center justify-between">
        <div className="text-sm leading-[1.4] text-codGray">{tokenName}</div>
        <div className="flex items-center space-x-2">
          <div className="text-sm text-boulder">
            {`${printNumberThresholds(amount)} $${tokenSymbol}`}
          </div>
          <img alt="" src={logo} className="h-[32px] w-[32px] rounded-[5px] " />
        </div>
      </div>
    </div>
  )
}

function Tab({
  current,
  onClick,
  label,
  className = '',
}: {
  current: boolean
  label: string
  onClick?(): void
  className?: string
}) {
  return (
    <div
      className={classNames(
        'cursor-pointer text-boulder',
        {
          'border-b border-primary !text-primary': current,
        },
        className
      )}
      onClick={onClick}
    >
      <div className="pb-[5px] text-sm">{label}</div>
    </div>
  )
}
