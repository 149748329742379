import { attach, createEvent, createStore, restore } from 'effector'
import { ethers } from 'ethers'
import { LiquidityMiningListElem } from 'gql'
import { graphqlSdk } from 'gql/client'
import { $session } from 'models/sessions'
import { $address, $provider } from 'models/wallet'
import invariant from 'tiny-invariant'
import { toDecimal } from 'utils/numbers'
import { lmAbi, tokenAbi } from './abi'
import { LM } from './lm'

export const $lmInfo = createStore<LiquidityMiningListElem[]>([])

export const fetchLMList = createEvent()

export const $approveLTSent = createStore(false)
export const $stakeLTSent = createStore(false)
export const $unstakeLTSent = createStore(false)
export const $claimLTSent = createStore(false)

export const fetchLMListFx = attach({
  source: $address,
  async effect(address) {
    return await graphqlSdk.GetLiquidityMiningList({ account: address })
  },
})

export const $lmsPools = $lmInfo.map((pools) => {
  const session = $session.getState()
  return pools.map((pool) => new LM(pool, session))
})

export const setSelectedLMAddress = createEvent<string>()
export const $selectedLMAddress = restore(setSelectedLMAddress, '')

export const addLMFx = attach({
  source: [$provider, $lmsPools, $selectedLMAddress],
  async effect([provider, lmsPools, selectedLMAddress], rawAmount: string) {
    const currentLMPool = lmsPools.find(
      (pool) => pool.address === selectedLMAddress
    )

    invariant(provider, 'web3 provider not found')
    invariant(currentLMPool?.address, 'staking contract not specified')

    const mlt = toDecimal(10).pow(currentLMPool?.stakingTokenDecimals)
    const amount = toDecimal(rawAmount).mul(mlt).floor().toString()

    const signer = provider.getSigner()
    const stakingContract = new ethers.Contract(
      currentLMPool?.address,
      lmAbi,
      signer
    )

    const res = await stakingContract.stake(amount, { gasLimit: 500000 })
    return `${res?.hash ?? ''}`
  },
})

export const approveLMFx = attach({
  source: [$provider, $lmsPools, $selectedLMAddress],
  async effect([provider, lmsPools, selectedLMAddress]) {
    const currentLMPool = lmsPools.find(
      (pool) => pool.address === selectedLMAddress
    )
    invariant(provider, 'web3 provider not found')
    invariant(currentLMPool?.address, 'staking contract not specified')

    const signer = provider.getSigner()
    const tokenContract = new ethers.Contract(
      currentLMPool?.stakingTokenAddress,
      tokenAbi,
      signer
    )

    const res = await tokenContract.approve(
      currentLMPool?.address,
      '10000000000000000000000000000000000'
    )
    return `${res?.hash ?? ''}`
  },
})

export const removeLMFx = attach({
  source: [$provider, $lmsPools, $selectedLMAddress],
  async effect([provider, lmsPools, selectedLMAddress], rawAmount: string) {
    const currentLMPool = lmsPools.find(
      (pool) => pool.address === selectedLMAddress
    )
    invariant(provider, 'web3 provider not found')
    invariant(currentLMPool?.address, 'staking contract not specified')

    const mlt = toDecimal(10).pow(currentLMPool?.stakingTokenDecimals)
    const amount = toDecimal(rawAmount).mul(mlt).floor().toString()

    // TODO: need for old abi. if working without it - remove
    // const maximumFee = toDecimal(rawAmount)
    //   .mul(mlt)
    //   .mul(currentLMPool?.unstakeFeeRatioDec)
    //   .ceil()
    //   .toString()

    const signer = provider.getSigner()
    const stakingContract = new ethers.Contract(
      currentLMPool?.address,
      lmAbi,
      signer
    )
    const res = await stakingContract.withdraw(
      amount,
      // maximumFee,
      {
        gasLimit: 200000,
      }
    )

    return `${res?.hash ?? ''}`
  },
})

export const getRewardLMFx = attach({
  source: [$provider, $lmsPools, $selectedLMAddress],
  async effect([provider, lmsPools, selectedLMAddress]) {
    const currentLMPool = lmsPools.find(
      (pool) => pool.address === selectedLMAddress
    )

    invariant(provider, 'web3 provider not found')
    invariant(currentLMPool?.address, 'staking contract not specified')

    // await window.ethereum?.request({ method: 'eth_requestAccounts' })

    const signer = provider.getSigner()
    const stakingContract = new ethers.Contract(
      currentLMPool?.address,
      lmAbi,
      signer
    )

    const res = await stakingContract.getReward()
    return `${res?.hash ?? ''}`
  },
})
