import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const DATE_FORMAT_DATE = 'MMM DD, YYYY'
export const DATE_FORMAT_SHORT = 'MMM DD HH:mm'
export const DATE_FORMAT_TIME = 'HH:mm:ss'
export const DATE_FORMAT = DATE_FORMAT_DATE + ' ' + DATE_FORMAT_TIME
export const TIMESTAMP_START = '1970-01-01T00:00:00Z'

export const formatUTC = <T extends string | number | Dayjs | null | undefined>(
  date: T,
  formatString?: string,
  withoutSuffix?: boolean
): string => {
  if (!date) {
    return 'TBA'
  }
  if (typeof date === 'string' && date === TIMESTAMP_START) {
    return 'TBA'
  }
  const f = formatString ?? DATE_FORMAT
  return `${dayjs(date).utc().format(f)} ${withoutSuffix ? '' : 'UTC'} `
}

export const formatDate = <
  T extends string | number | Dayjs | null | undefined
>(
  date: T,
  formatString?: string
): string => {
  if (!date) {
    return 'TBA'
  }
  if (typeof date === 'string' && date === TIMESTAMP_START) {
    return 'TBA'
  }
  const fmt = formatString ?? DATE_FORMAT_DATE
  return dayjs(date).utc().format(fmt)
}

export const formatTime = <
  T extends string | number | Dayjs | null | undefined
>(
  date: T
): string => {
  if (!date) {
    return 'TBA'
  }
  if (typeof date === 'string' && date === TIMESTAMP_START) {
    return 'TBA'
  }
  return dayjs(date).utc().format('HH:mm').concat(' UTC')
}
export const toTimestamp = <
  T extends string | number | Dayjs | null | undefined
>(
  date: T
): number => {
  return dayjs(date).unix()
}

export default dayjs
