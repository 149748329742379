import classNames from 'classnames'

export default function Badge({
  children,
  className = '',
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <div
      className={classNames(
        'flex h-[26px] items-center whitespace-nowrap rounded-[4px] bg-white/90 px-[10px] text-sm text-brightGray',
        className
      )}
    >
      {children}
    </div>
  )
}
