import classNames from 'classnames'
import { Tooltip } from 'react-tooltip'
import { useStore } from 'effector-react'
import { $session } from 'models/sessions'
import { $staking } from 'models/staking'
import { $address, $shortAddress, openWallet } from 'models/wallet'
import { ReactComponent as WalletIcon } from 'resources/images/icons/wallet.svg'
import t from 'utils/t'
import { animated } from '@react-spring/web'
import React from 'react'
import { getNftUrl, ROMAN_TIER_NAME, useAnimateTierImage } from 'utils/tier'
import { $cedIndicator } from 'models/rewards'

interface Props {
  shadow?: boolean
}

export default function ButtonWithTiers({ shadow = false }: Props) {
  const address = useStore($address)
  const url = getNftUrl(address)
  const shortAddress = useStore($shortAddress)
  const staking = useStore($staking)
  const indicator = useStore($cedIndicator)

  const session = useStore($session)

  const { imgRef, springs, handleMouseMove, handleMouseLeave } =
    useAnimateTierImage()

  let state: 'notConnected' | 'connected' | 'connectedToWrongNetwork'
  if (session === '') {
    state = 'notConnected'
  } else {
    state = 'connected'
  }

  if (state === 'notConnected') {
    return (
      <Wrapper shadow={shadow}>
        <div className="absolute z-0 h-[100%] w-[100%] bg-primary/15 ds:bg-porcelain2" />
        <div className="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2">
          <div className="mb-[3px] flex justify-center">
            <WalletIcon className="w-[32px] t:w-[35px]" />
          </div>
          <div className="hidden text-center text-sm uppercase text-brightGray/40 group-hover:text-primary ds:block">
            {t('connect_wallet')}
          </div>
          <div className="hidden text-center text-[10px] uppercase text-brightGray/40 group-hover:text-primary ml:block t:text-[12px] ds:hidden">
            WALLET
          </div>
        </div>
      </Wrapper>
    )
  }

  if (!staking.hasTier) {
    return (
      <Wrapper
        shadow={shadow}
        showIndicator={indicator > 0}
        indicatorLabel={indicator}
      >
        <div
          className={classNames(
            'relative z-0 h-full w-full rounded-lg bg-no-repeat',
            'bg-walletMonk'
          )}
        />
        <div
          className={classNames(
            'absolute bottom-2 left-1/2 z-10 -translate-x-1/2 '
          )}
        >
          <div className="whitespace-nowrap text-center text-[0px] font-semibold text-brightGray ml:text-sm dxs:text-base">
            {shortAddress}
          </div>
        </div>
      </Wrapper>
    )
  }

  if (staking.hasTier) {
    return (
      <div
        data-tooltip-id="tier-tooltip"
        className="absolute z-50 block h-full w-full cursor-pointer"
      >
        <Wrapper
          ref={imgRef}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          className="group !right-0 !top-0"
          shadow={shadow}
          showIndicator={indicator > 0}
          indicatorLabel={indicator}
        >
          <div
            className="absolute z-0 block h-full w-full rounded-lg bg-center bg-no-repeat ds:hidden"
            style={{
              backgroundImage: `url("${url}")`,
              backgroundSize: '120%',
            }}
          />
          <animated.div
            className={classNames(
              'absolute z-0 hidden h-full w-full rounded-lg bg-center bg-no-repeat ds:block'
            )}
            style={{
              backgroundImage: `url("${url}")`,
              backgroundSize: '120%',
              ...springs,
            }}
          />
          <div className="wallet-button-hasTier-gradient absolute z-10 hidden h-full w-full group-hover:hidden ds:block" />
          <div className="absolute bottom-1 left-1/2 z-10 -translate-x-1/2 group-hover:hidden ">
            <div className="whitespace-nowrap text-center text-[0px] text-brightGray ml:text-sm dxs:text-lg">
              {ROMAN_TIER_NAME[staking.level]}
            </div>
          </div>
        </Wrapper>
        <Tooltip
          id="tier-tooltip"
          place="bottom"
          className="hidden ds:block"
          render={() => <div className="uppercase">{staking.tierName}</div>}
        ></Tooltip>
      </div>
    )
  }

  return <Wrapper>N/A</Wrapper>
}

interface WrapperProps {
  children?: React.ReactNode

  onMouseMove?(e: React.MouseEvent): void

  onMouseLeave?(e: React.MouseEvent): void

  className?: string
  shadow?: boolean
  showIndicator?: boolean
  indicatorLabel?: string | number
}

const Wrapper = React.forwardRef<HTMLDivElement, WrapperProps>(
  (
    {
      children,
      className,
      shadow = false,
      showIndicator = false,
      indicatorLabel = '',
      ...other
    },
    ref
  ) => {
    return (
      <div
        className={classNames(
          'group absolute h-full w-full cursor-pointer',
          className
        )}
        onClick={() => openWallet()}
        ref={ref}
        {...other}
      >
        {showIndicator ? (
          <div className="absolute -right-2 top-2 z-10 h-[20px] w-[20px] rounded-[50%] border border-white bg-primary">
            <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-xs font-medium text-athensGray2">
              {indicatorLabel}
            </span>
          </div>
        ) : null}
        <div
          className={classNames(
            'hover:walletButtonShadow h-full w-full transition-shadow',
            ' relative overflow-hidden rounded-lg',
            {
              'blockShadow border border-porcelain2': shadow,
            }
          )}
          onClick={() => openWallet()}
        >
          {children}
        </div>
      </div>
    )
  }
)
