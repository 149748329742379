import { createEvent, sample, createStore } from 'effector'

export const setWindowWidth = createEvent<number>()

export const $windowWidth = createStore(0)

sample({
  source: setWindowWidth,
  target: $windowWidth,
})
