import { combine, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { $selectedChainId, logout } from 'models/wallet'
import { getChainId } from 'utils/blockchain'
import {
  $stakingTokenWalletBalanceDec,
  $stakingNetworkIsOk,
  $approveSent,
  fetchStakingTokenWalletBalanceFx,
  stakingTick,
  WalletBalanceGate,
  openOpenseaLink,
  openOpenseaLinkFx,
  approveStakeFx,
  addStakeFx,
  $stakeSent,
  $unstakeSent,
  removeStakeFx,
  getRewardFx,
  $claimSent,
  fetchStakingInfo,
  fetchStakingInfoFx,
  $staking,
} from '.'
import { Staking } from './staking'

persist({ store: $stakingNetworkIsOk, key: 'stakingNetworkIsOk' })

sample({
  clock: fetchStakingInfo,
  target: fetchStakingInfoFx,
})

// Если данные пришли, сохраняем
sample({
  clock: fetchStakingInfoFx.doneData,
  source: combine($stakingTokenWalletBalanceDec, $selectedChainId),
  fn: ([balance, selectedChainId], data) =>
    new Staking({
      walletBalanceDec: balance,
      stakingInfoMultichain: data?.stakingInfoMultichain,
      votingPower: data?.votingPower,
      apyInfo: data?.apy,
      chakraInfo: data?.stakingInfoCHAKRA,
      selectedChainId,
    }),
  target: $staking,
})

// sample({
//   clock: fetchStakingInfoFx.doneData,
//   source: $stakingTokenWalletBalanceDec,
//   fn: (balance, data) => {
//     return new Staking({
//       walletBalanceDec: balance,
//       stakingInfoMultichain: data?.stakingInfoMultichain,
//       votingPower: data?.votingPower,
//       apyInfo: data?.apy,
//       chakraInfo: data?.stakingInfoCHAKRA,
//     })
//   },
//   target: $staking,
// })

sample({
  source: fetchStakingTokenWalletBalanceFx.doneData,
  target: $stakingTokenWalletBalanceDec,
})

$stakingTokenWalletBalanceDec.reset(logout)

setTimeout(fetchStakingTokenWalletBalanceFx, 1000)
setInterval(stakingTick, 10 * 1000)

sample({
  clock: stakingTick,
  source: WalletBalanceGate.status,
  filter: Boolean,
  target: fetchStakingTokenWalletBalanceFx,
})

sample({
  clock: $selectedChainId,
  fn: (chainId) =>
    getChainId('ethereum') === chainId || getChainId('bsc') === chainId,
  target: $stakingNetworkIsOk,
})

sample({
  clock: openOpenseaLink,
  target: openOpenseaLinkFx,
})

sample({
  clock: approveStakeFx,
  fn: () => true,
  target: $approveSent,
})

sample({
  clock: addStakeFx,
  fn: () => true,
  target: $stakeSent,
})

sample({
  clock: [addStakeFx.doneData, addStakeFx.fail],
  fn: () => false,
  target: $stakeSent,
})

sample({
  clock: removeStakeFx,
  fn: () => true,
  target: $unstakeSent,
})

sample({
  clock: [removeStakeFx.doneData, removeStakeFx.fail],
  fn: () => false,
  target: $unstakeSent,
})

sample({
  clock: getRewardFx,
  fn: () => true,
  target: $claimSent,
})

sample({
  clock: [getRewardFx.doneData, getRewardFx.fail],
  fn: () => false,
  target: $claimSent,
})

fetchStakingInfo()
