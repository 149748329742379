import Decimal from 'decimal.js'
import { LiquidityMiningListElem } from 'gql'
import isEmpty from 'lodash/isEmpty'
import numeral from 'numeral'
import { toDecimal } from 'utils/numbers'

export class LM {
  ok: boolean
  blockchain: string
  address: string
  poolAddress: string
  name: string
  apy: string
  liquidityTokenPrettyName: string
  poolInfoLink: string
  rewardsSymbol: string
  stakingSymbol: string

  stakingTokenAllowanceDec: Decimal

  stakingBalance: string
  stakingBalanceDec: Decimal

  rewardsTotal: string
  rewardsTotalDec: Decimal

  rewardsClaimed: String
  rewardsClaimedDec: Decimal

  rewardsAvailable: String
  rewardsAvailableDec: Decimal

  rewardTokenBalance: string
  rewardTokenBalanceDec: Decimal

  stakingTokenAddress: string
  stakingTokenDecimals: number

  walletBalance: string
  walletBalanceDec: Decimal

  totalStakedUSD: string
  totalStakedUSDDec: Decimal

  totalStakedLT: string
  totalStakedLTDec: Decimal

  priceLiquidityTokenUSD: string
  priceLiquidityTokenUSDDec: Decimal

  poolTvlUSD: string
  poolTvlUSDDec: Decimal

  unstakeFeeRatio: string
  unstakeFeeRatioDec: Decimal

  isOccam: boolean

  constructor(pool: LiquidityMiningListElem, session: string) {
    this.ok = !isEmpty(session)
    this.blockchain = pool.commonData.blockchain
    this.address = pool.commonData.address ?? ''
    this.poolAddress = pool.commonData.poolAddress
    this.name = pool.commonData.name
    this.liquidityTokenPrettyName = pool.commonData.liquidityTokenPrettyName
    this.poolInfoLink = pool.commonData.poolInfoLink

    this.apy = !toDecimal(pool.commonData?.apy).eq(0)
      ? `${numeral(toDecimal(pool.commonData?.apy).mul(100).toString()).format(
          '0.00'
        )}%`
      : 'TBA'

    this.rewardsSymbol = pool?.commonData.rewardsToken?.symbol ?? ''
    this.stakingSymbol = pool?.commonData.liquidityToken?.symbol ?? ''

    this.stakingTokenAddress = pool.commonData?.liquidityToken?.address ?? ''
    this.stakingTokenDecimals = pool.commonData?.liquidityToken?.decimals ?? 0

    this.stakingBalanceDec = toDecimal(pool.accountData?.stakingBalance ?? '0')
    this.stakingBalance = pool.accountData?.stakingBalance ?? '0'

    this.rewardsTotal = pool.accountData?.rewardsTotal ?? '0'
    this.rewardsTotalDec = toDecimal(pool.accountData?.rewardsTotal ?? '0')

    this.rewardsClaimed = pool.accountData?.rewardsClaimed ?? '0'
    this.rewardsClaimedDec = toDecimal(pool.accountData?.rewardsClaimed ?? '0')

    this.rewardsAvailable = pool.accountData?.rewardsAvailable ?? '0'
    this.rewardsAvailableDec = toDecimal(
      pool.accountData?.rewardsAvailable ?? '0'
    )

    this.rewardTokenBalance = pool.accountData?.rewardTokenBalance ?? '0'
    this.rewardTokenBalanceDec = toDecimal(
      pool.accountData?.rewardTokenBalance ?? '0'
    )

    this.walletBalance = pool.accountData?.walletBalance ?? '0'
    this.walletBalanceDec = toDecimal(pool.accountData?.walletBalance ?? '0')

    this.totalStakedUSD = pool.commonData.totalStakedUSD
    this.totalStakedUSDDec = toDecimal(pool.commonData.totalStakedUSD)

    this.totalStakedLT = pool.commonData.totalStakedLT
    this.totalStakedLTDec = toDecimal(pool.commonData.totalStakedLT)

    this.priceLiquidityTokenUSD = pool.commonData.priceLiquidityTokenUSD
    this.priceLiquidityTokenUSDDec = toDecimal(
      pool.commonData.priceLiquidityTokenUSD
    )

    this.poolTvlUSD = pool.commonData.priceLiquidityTokenUSD ?? '0'
    this.poolTvlUSDDec = toDecimal(pool.commonData.poolTvlUSD ?? '0')

    this.unstakeFeeRatio = pool.commonData.unstakeFeeRatio ?? '0'
    this.unstakeFeeRatioDec = toDecimal(pool.commonData.unstakeFeeRatio ?? '0')

    this.stakingTokenAllowanceDec = toDecimal(
      pool.accountData?.stakingTokenAllowance ?? 0
    )

    this.isOccam = pool.commonData.name === 'OccamFi'
  }

  stakeTerminalState(input: string) {
    const amountDecimal = toDecimal(input)
    if (!this.ok) {
      return 'walletNotConnected'
    } else if (this.stakingTokenAllowanceDec.lessThan(amountDecimal)) {
      return 'allowanceExceeded'
    } else if (this.walletBalanceDec.eq(0) || amountDecimal.eq(0)) {
      return 'notReadyToStake'
    } else if (input === '') {
      return 'waitingForInput'
    } else if (amountDecimal.gt(this.walletBalanceDec)) {
      return 'insufficientBalance'
    }
    return 'readyToStake'
  }

  unstakeTerminalState(input: string) {
    const amountDecimal = toDecimal(input)

    if (!this.ok) {
      return 'walletNotConnected'
    } else if (this.stakingBalanceDec.eq(0) || amountDecimal.eq(0)) {
      return 'notReadyToUnstake'
    } else if (input === '') {
      return 'waitingForInput'
    } else if (amountDecimal.gt(this.stakingBalanceDec)) {
      return 'insufficientBalance'
    }
    return 'readyToUnstake'
  }

  get claimTerminalState() {
    if (!this.ok) {
      return 'walletNotConnected'
    } else if (this.rewardsAvailableDec.eq(0)) {
      return 'notReadyToClaim'
    }
    return 'readyToClaim'
  }
}
