import { useLayoutEffect, useState } from 'react'

export default function useResizeObserver(ref: React.RefObject<HTMLElement>) {
  const [width, setWidth] = useState(0)

  useLayoutEffect(() => {
    let resizeObserver: ResizeObserver | undefined
    let elem = ref.current

    if (typeof ResizeObserver === 'function' && elem) {
      resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          setWidth(entry.contentRect.width)
        }
      })

      resizeObserver.observe(elem)
    }

    return () => {
      if (elem && resizeObserver) {
        resizeObserver.unobserve(elem)
      }
    }
  }, [ref])

  return { width }
}
