import classNames from 'classnames'
import Block from 'components/dumb/Block'
import Button from 'components/dumb/Button'
import SmartWalletButton from 'components/smart/SmartWalletButton/Button'
import { useStore } from 'effector-react'
import { $kyc, $residenceConfirmed } from 'models/generalInfo'
import { $staking } from 'models/staking'
import { $poolNetworkIsOk } from 'models/pools'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as NextIcon } from 'resources/images/icons/next.svg'
import { ReactComponent as LightningIcon } from 'resources/images/icons/lightning.svg'
import { ReactComponent as CheckIcon } from 'resources/images/icons/check-circle.svg'
import { noop } from 'utils'
import t from 'utils/t'
import ChevronButton from 'components/dumb/ChevronButton'

export interface ParticipationGuideProps {
  isPublic: boolean
  isRegistered?: boolean
  connected: boolean
  idoTokenSymbol: string
  comingSoon: boolean
  registration: boolean
  whitelistingOrPrelaunch: boolean
  finished: boolean
  tokensHasBeenDistributed: boolean
  userParticipated: boolean
}

const steps = [
  'ConnectWallet',
  'PassKYC',
  'ConfirmResidency',
  'Stake',
  'Register',
  'Buy',
  'Claim',
  'None',
] as const
type StepType = (typeof steps)[number]

export default function SmartProjectPageParticipationGuide({
  connected,
  idoTokenSymbol,
  terminalRef,
  isPublic,
  finished,
  comingSoon,
  registration,
  isRegistered,
  tokensHasBeenDistributed,
  userParticipated,
  whitelistingOrPrelaunch,
}: ParticipationGuideProps & { terminalRef: any }) {
  const navigate = useNavigate()
  const residenceConfirmed = useStore($residenceConfirmed)
  const poolNetworkIsOk = useStore($poolNetworkIsOk)
  const { stakingSymbol, level: tierLevel } = useStore($staking)
  const kyc = useStore($kyc)
  const passedKYC = kyc.status === 'verified'
  const notEnoughStake = tierLevel === 0

  const handleScrollToTerminalButtonClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      if (terminalRef && terminalRef.current) {
        terminalRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    },
    [terminalRef]
  )

  const handleGoToStake = useCallback(() => {
    navigate('/staking')
  }, [navigate])

  const activeStep: StepType = React.useMemo(() => {
    if (!connected) {
      return 'ConnectWallet'
    }

    if (isPublic) {
      if (finished && !userParticipated) {
        return 'None'
      }
      if (!residenceConfirmed) {
        return 'ConfirmResidency'
      } else if (!finished) {
        return 'Buy'
      } else if (!tokensHasBeenDistributed) {
        return 'Claim'
      }
      return 'None'
    }

    if (!passedKYC) {
      return 'PassKYC'
    } else if (notEnoughStake || comingSoon) {
      return 'Stake'
    } else if (!isRegistered && (registration || whitelistingOrPrelaunch)) {
      return 'Register'
    } else if (isRegistered && !finished) {
      return 'Buy'
    } else if (finished && !tokensHasBeenDistributed) {
      return 'Claim'
    }
    return 'None'
  }, [
    finished,
    comingSoon,
    connected,
    tokensHasBeenDistributed,
    notEnoughStake,
    isRegistered,
    registration,
    residenceConfirmed,
    isPublic,
    passedKYC,
    userParticipated,
    whitelistingOrPrelaunch,
  ])

  const items = useMemo(() => {
    const finishedAndNotParticipated = finished && !userParticipated
    const activeStepIndex = finishedAndNotParticipated
      ? -1
      : steps.indexOf(activeStep)

    return [
      {
        title: t('connect_wallet'),
        desc: t('connect_wallet_description'),
        button: <SmartWalletButton />,
        isActive: activeStep === 'ConnectWallet',
        completed: activeStepIndex > 0,
        hide: false,
      },
      {
        title: t('get_verified'),
        desc: t('get_verified_description'),
        button: (
          <Button onClick={handleScrollToTerminalButtonClick}>
            {t('apply_for_kyc')}
          </Button>
        ),
        isActive: activeStep === 'PassKYC',
        completed: activeStepIndex > 0,
        onClick: noop,
        hide: isPublic,
      },
      {
        title: t('confirm_residency'),
        desc: t('confirm_residency_description'),
        button: (
          <Button onClick={handleScrollToTerminalButtonClick}>
            {t('proceed')}
          </Button>
        ),
        isActive: activeStep === 'ConfirmResidency',
        completed: activeStepIndex > 2,
        onClick: noop,
        hide: !isPublic,
      },
      {
        title: t('stake_token', { symbol: stakingSymbol }),
        desc: t('stake_token_description'),
        button: (
          <Button onClick={handleGoToStake} disabled={!notEnoughStake}>
            Stake
          </Button>
        ),
        isActive: activeStep === 'Stake',
        completed: activeStepIndex > 3,
        onClick: noop,
        hide: isPublic,
      },
      {
        title: t('register_for_an_ido'),
        desc: t('register_for_an_ido_description'),
        button: (
          <Button
            onClick={handleScrollToTerminalButtonClick}
            disabled={isRegistered}
          >
            {t('register')}
          </Button>
        ),
        isActive: activeStep === 'Register',
        completed: activeStepIndex > 4,
        onClick: noop,
        hide: isPublic,
      },
      {
        title: t('buy_token', { symbol: idoTokenSymbol }),
        desc: t('buy_token_description'),
        button: (
          <Button onClick={handleScrollToTerminalButtonClick}>
            {t('buy')}
          </Button>
        ),
        isActive: activeStep === 'Buy',
        completed: activeStepIndex > 5,
        onClick: noop,
        hide: false,
      },
      {
        title: t('claim_token', { symbol: idoTokenSymbol }),
        desc: t('claim_token_description'),
        button: (
          <Button onClick={handleScrollToTerminalButtonClick}>
            {t('claim')}
          </Button>
        ),
        isActive: activeStep === 'Claim',
        completed: activeStepIndex > 6,
        onClick: noop,
        hide: false,
      },
    ].filter((item) => item.hide === false)
  }, [
    activeStep,
    stakingSymbol,
    idoTokenSymbol,
    isPublic,
    handleScrollToTerminalButtonClick,
    handleGoToStake,
    notEnoughStake,
    isRegistered,
    finished,
    userParticipated,
  ])

  const [showContent, setShowContent] = useState(true)

  if (connected && finished) {
    return null
  }

  const activeItem = items.find((i) => i.isActive)

  const gridClassNames = isPublic
    ? 'grid-cols-1 ml:grid-cols-2 dxs:grid-cols-4'
    : 'grid-cols-1 ml:grid-cols-3 ds:grid-cols-6'

  return (
    <Block wrapperClassName="mb-12">
      <div
        className="group cursor-pointer items-center justify-between space-y-3 text-2xl font-light text-brightGray mm:flex mm:space-y-0"
        onClick={() => setShowContent(!showContent)}
      >
        <div>{t('how_to_participate')}</div>
        <div className="flex items-center space-x-[30px]">
          {activeItem ? activeItem.button : null}
          <ChevronButton
            open={showContent}
            onClick={() => setShowContent(!showContent)}
          />
        </div>
      </div>
      {showContent && (
        <div
          className={`
            mt-4 grid auto-rows-auto justify-between gap-[0.6rem] 
            pb-[1rem] ml:auto-rows-[1fr] dxs:min-h-full
            ${gridClassNames}
            `}
        >
          {items.map((item, index) => (
            <Entry
              key={`how-to-participate-${item.title}`}
              buttonType={
                index === 0 && !poolNetworkIsOk ? 'switch' : 'primary'
              }
              {...item}
            />
          ))}
        </div>
      )}
    </Block>
  )
}

interface EntryProps {
  title: string
  desc: string
  button: React.ReactNode
  isActive: boolean
  completed: boolean
  onClick?(): void
}

function Entry({
  isActive,
  completed,
  title,
  desc,
}: EntryProps & {
  buttonType?: 'switch' | 'primary'
}) {
  const active = 'border border-iron bg-primary/5 rounded-lg'
  const next = 'opacity-50 !text-boulder'

  const icon = React.useMemo(() => {
    const props = { className: 'h-4 w-4' }
    if (isActive) {
      return <LightningIcon {...props} />
    }
    if (completed) {
      return <CheckIcon {...props} />
    }
    return <NextIcon {...props} />
  }, [isActive, completed])

  return (
    <div
      className={classNames('p-[0.875rem] text-brightGray', {
        [active]: isActive,
        [next]: !isActive && !completed,
      })}
    >
      <div className="relative mb-2 flex items-center space-x-2">
        <div className="text-base font-medium leading-[140%]">{title}</div>
        <span>{icon}</span>
      </div>

      <div className="text-[0.875rem] leading-[140%]">{desc}</div>
    </div>
  )
}
