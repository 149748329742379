import { SecondaryMarket } from 'gql'
import React from 'react'
import { openUrl } from 'utils'
import t from 'utils/t'
import Block from './Block'
import DotLeaders from './DotLeaders'
import Dropdown from './Dropdown'

export interface Props {
  athTokenPrice: string
  athRoi: string
  secondaryMarkets: SecondaryMarket[]
  symbol: string
  hide?: boolean
}

export default function CampaignSaleStatistics({
  athTokenPrice,
  athRoi,
  secondaryMarkets,
  symbol,
  hide = false,
}: Readonly<Props>) {
  if (hide) return null

  const markets = secondaryMarkets.map((m) => ({
    text: m.name,
    onClick: () => openUrl(m.url),
  }))

  return (
    <Block containerClassName="flex flex-col h-full">
      <div className="flex h-full flex-col justify-between">
        <div className="">
          <div className="text-lg text-codGray">{t('sale_has_ended')}</div>
          <div className="mb-4 text-sm text-boulder">
            {t('successfully_funded')}
          </div>
          <div className="mb-[1rem]">
            <DotLeaders name={t('total_raised')} value="TBA" />
            <DotLeaders name={t('tokens_sold')} value="TBA" />
            <DotLeaders name={t('ath_token_price')} value={athTokenPrice} />
            <DotLeaders name={t('ath_roi')} value={athRoi} />
          </div>
        </div>
        {secondaryMarkets.length > 0 && (
          <Dropdown items={markets}>
            {t('trade')} <span className="font-bold">{symbol}</span>
          </Dropdown>
        )}
      </div>
    </Block>
  )
}
