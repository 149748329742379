import { ReactComponent as PencilIcon } from 'resources/images/icons/pencil.svg'
import { useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { Tooltip } from 'react-tooltip'

interface AirdropInputProps {
  className?: string
  value: string
  label: string | null
  onEditClick(): void
  onUserInput(value: string): void
  editing: boolean
}

export default function AirdropInput({
  className = '',
  label,
  value,
  onUserInput,
  onEditClick,
  editing,
}: AirdropInputProps) {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus()
    }
  }, [editing])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUserInput(event.target.value)
  }

  const onEditClick_ = () => {
    onEditClick()
  }

  const disabled = !editing

  return (
    <div className={className}>
      <div>
        <div className="w-full grow dxs:w-auto">
          <div className="mb-2 text-sm font-medium text-codGray">{label}</div>
          <div
            className={twMerge(
              'relative flex items-center justify-between rounded-[0.3rem] bg-white px-3 py-[14px] text-base leading-none',
              disabled && 'bg-white/50'
            )}
          >
            <input
              ref={inputRef}
              className={twMerge(
                'peer relative z-10 w-full flex-grow bg-transparent text-left  placeholder-grayTertiary focus:outline-none',
                disabled ? 'pointer-events-none text-boulder' : 'text-codGray'
              )}
              type="text"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              inputMode="decimal"
              onChange={handleChange}
              value={value}
              spellCheck="false"
            />

            <div
              data-tooltip-id="edit"
              data-tooltip-content="Edit address"
              className={twMerge(
                'absolute right-[12px] top-1/2 z-10 h-[24px] w-[24px] -translate-y-1/2 cursor-pointer',
                !disabled && 'hidden'
              )}
              onClick={onEditClick_}
            >
              <PencilIcon className="absolute h-full w-full" />
            </div>
            <Tooltip id="edit" place="right" />
          </div>
        </div>
      </div>
    </div>
  )
}
