import { Menu } from '@headlessui/react'
import classNames from 'classnames'
import { ReactNode } from 'react-markdown/lib/react-markdown'
import { ReactComponent as ChevronIcon } from 'resources/images/icons/chevron-left.svg'
import { noop, tw } from 'utils'

type Props = {
  className?: string
  children: ReactNode
  disabled?: boolean
  baseClassName?: string
  items: {
    text: string
    onClick?(): void
    disabled?: boolean
    icon?: React.ReactNode
  }[]
}

export default function Dropdown({
  children,
  items,
  disabled,
  className = '',
  baseClassName = '',
}: Props) {
  const base = classNames(
    `rounded-[0.25rem] py-[0.4rem] px-[0.8rem] cursor-pointer uppercase relative ${baseClassName}`
  )
  const connect = tw` bg-primary hover:bg-primaryHover active:bg-primaryActive`

  return (
    <div
      className={classNames('relative', className, {
        'pointer-events-none text-boulder': disabled,
        'text-porcelain': !disabled,
      })}
    >
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button
              className={classNames(
                base,
                `flex w-full items-center justify-between space-x-[0.6rem] ${
                  open ? 'relative z-50 bg-primaryHover' : ''
                }`,
                {
                  [connect]: !disabled,
                  'border border-iron bg-porcelain2': disabled,
                }
              )}
            >
              <div className="whitespace-nowrap">{children}</div>
              <div className="flex items-center space-x-[0.6rem]">
                <div className="h-[1.15rem] w-[0.05rem] bg-geyser/40" />
                <ChevronIcon
                  className={`h-[0.6rem] w-[0.6rem] fill-current ${
                    open ? 'rotate-90' : '-rotate-90'
                  } transform cursor-pointer`}
                />
              </div>
            </Menu.Button>
            {items.length > 0 ? (
              <Menu.Items className="absolute z-50 w-full rounded-[0.25rem] border border-iron bg-athensGray2 shadow-dropdown">
                {items.map(
                  ({ text, icon, onClick = noop, disabled = false }) => (
                    <Menu.Item
                      key={text}
                      className={`flex cursor-pointer items-center space-x-2 p-[0.6rem] text-sm hover:bg-primary hover:bg-opacity-20 ${
                        disabled ? 'text-boulder' : 'text-codGray'
                      }`}
                      as="div"
                      onClick={onClick}
                    >
                      {icon ? <span className="shrink-0">{icon}</span> : null}
                      <span>{text}</span>
                    </Menu.Item>
                  )
                )}
              </Menu.Items>
            ) : null}
          </>
        )}
      </Menu>
    </div>
  )
}
