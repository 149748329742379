import { combine, sample, split } from 'effector'
import { persist } from 'effector-storage/local'
import { ethers } from 'ethers'
import { claimCedNew, claimCedOld } from 'models/rewards'
import { getMetamaskProvider, getWalletConnectProvider } from 'utils/providers'
import {
  $address,
  $showAboutKyc,
  $showWallet,
  $targetTokenBalance,
  $wallet,
  Wallet,
  addNetworkFx,
  closeAboutKyc,
  closeWallet,
  deactivateFx,
  connectProvider,
  fetchTargetTokenBalanceFx,
  logout,
  openAboutKyc,
  openWallet,
  setAddress,
  setProvider,
  setSelectedChainId,
  setWallet,
  switchNetworkFx,
  $selectedChainId,
  $userSelectedChainId,
} from '.'
import { ProviderId, metamask, walletConnect, wallets } from './wallets'

persist({ store: $address, key: 'address' })

sample({
  clock: openWallet,
  fn: () => true,
  target: $showWallet,
})

sample({
  clock: closeWallet,
  fn: () => false,
  target: [$showWallet, $showAboutKyc],
})

switchNetworkFx.fail.watch(({ params }) => {
  addNetworkFx(params)
})

sample({
  clock: logout,
  target: [closeWallet, deactivateFx],
})

window.addEventListener('load', async () => {
  if (window.ethereum) {
    const accounts = await window.ethereum.request({ method: 'eth_accounts' })

    if (accounts.length === 0) {
      logout()
    }

    window.ethereum?.on('accountsChanged', (accounts) => {
      if (Array.isArray(accounts) && accounts.length > 0) {
        setAddress(accounts[0])
      }
    })
  }
})

$wallet.watch(async (wallet) => {
  if (wallet?.state !== 'connected') return

  let provider: ethers.providers.Web3Provider | null = null

  if (wallet.id === ProviderId.MetaMask) {
    const metamaskProvider = await getMetamaskProvider()
    provider = metamaskProvider
      ? new ethers.providers.Web3Provider(metamaskProvider, 'any')
      : null
    setProvider(provider)
  }

  if (wallet.id === ProviderId.WalletConnect) {
    const walletConnectProvider = await getWalletConnectProvider()
    provider = new ethers.providers.Web3Provider(walletConnectProvider, 'any')
    setProvider(provider)
  }

  provider?.on('network', (currentChain) => {
    if (currentChain?.chainId) {
      setSelectedChainId(`0x${currentChain?.chainId.toString(16)}`)
    }
  })

  provider?.on('accountsChanged', (accounts) => {
    if (Array.isArray(accounts) && accounts.length > 0) {
      setAddress(accounts[0])
    }
  })
})

$wallet.reset(logout)

split({
  source: connectProvider,
  match: {
    metamask: (id) => id === ProviderId.MetaMask,
    walletConnect: (id) => id === ProviderId.WalletConnect,
  },
  cases: {
    metamask: metamask.authFx,
    walletConnect: walletConnect.authFx,
  },
})

wallets.forEach(({ id, authFx }) => {
  sample({
    clock: authFx,
    fn: (): Wallet => ({ id, state: 'connecting' }),
    target: setWallet,
  })

  sample({
    clock: authFx.doneData,
    fn: (): Wallet => ({ id, state: 'connected' }),
    target: setWallet,
  })

  sample({
    clock: authFx.doneData,
    fn: (auth) => auth.address,
    target: setAddress,
  })

  sample({
    clock: authFx.doneData,
    source: combine($selectedChainId, $userSelectedChainId),
    filter: ([selectedChainId, userSelectedChainId]) =>
      selectedChainId !== userSelectedChainId,
    fn: ([_, userSelectedChainId]) => {
      return userSelectedChainId
    },
    target: switchNetworkFx,
  })

  sample({
    clock: authFx.fail,
    fn: (): Wallet => ({ id, state: 'error' }),
    target: setWallet,
  })
})

sample({
  clock: openAboutKyc,
  fn: () => true,
  target: $showAboutKyc,
})

sample({
  clock: closeAboutKyc,
  fn: () => false,
  target: $showAboutKyc,
})

sample({
  clock: [claimCedOld, claimCedNew],
  target: closeWallet,
})

sample({
  clock: fetchTargetTokenBalanceFx.doneData,
  target: $targetTokenBalance,
})

// При старте приложения пробуем загрузить данные
setTimeout(fetchTargetTokenBalanceFx, 250)

// Отправляем запросы каждые 5 секунд
setInterval(fetchTargetTokenBalanceFx, 5000)
