export const ETHEREUM_MAINNET_CHAIN_ID = '0x1'
export const BSC_MAINNET_CHAIN_ID = '0x38'
export const EVMOS_MAINNET_CHAIN_ID = '0x2329'

export const ETHEREUM_TESTNET_CHAIN_ID = '0xac9fe'
export const BSC_TESTNET_CHAIN_ID = '0x61'
export const EVMOS_TESTNET_CHAIN_ID = '0x2328'

export const ETHEREUM_MAINNET_EXPLORER_URL = 'https://etherscan.io'
export const ETHEREUM_TESTNET_EXPLORER_URL = 'http://10.0.0.16'
export const BSC_MAINNET_EXPLORER_URL = 'https://bscscan.com'
export const BSC_TESTNET_EXPLORER_URL = 'https://testnet.bscscan.com'
export const EVMOS_MAINNET_EXPLORER_URL = 'https://escan.live'
export const EVMOS_TESTNET_EXPLORER_URL = 'https://testnet.escan.live'

export const desiredNetwork =
  process.env.REACT_APP_NETWORK === 'mainnet' ? 'mainnet' : 'testnet'

export interface NetworkInfo {
  readonly explorer: string
  readonly label: string
  readonly nativeCurrency: {
    name: string
    symbol: string
    decimals: number
  }
  readonly logoUrl?: string
  readonly rpcUrls?: string[]
}

export type NetworkInfoMap = Record<string, NetworkInfo>
export const networks: NetworkInfoMap = {
  [ETHEREUM_MAINNET_CHAIN_ID]: {
    explorer: ETHEREUM_MAINNET_EXPLORER_URL,
    label: 'Ethereum',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
  },
  [BSC_MAINNET_CHAIN_ID]: {
    explorer: BSC_MAINNET_EXPLORER_URL,
    label: 'BNB Smart Chain',
    nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
  },
  [EVMOS_MAINNET_CHAIN_ID]: {
    explorer: EVMOS_MAINNET_EXPLORER_URL,
    label: 'EVMOS',
    nativeCurrency: { name: 'EVMOS', symbol: 'EVMOS', decimals: 18 },
    rpcUrls: ['https://evmos-evm.publicnode.com'],
  },
  [ETHEREUM_TESTNET_CHAIN_ID]: {
    explorer: `${ETHEREUM_TESTNET_EXPLORER_URL}/ethereum/explorer`,
    label: 'Ethereum razer',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://10.0.0.16:8545'],
  },
  [BSC_TESTNET_CHAIN_ID]: {
    explorer: BSC_TESTNET_EXPLORER_URL,
    label: 'BSC Testnet',
    nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
  },
  [EVMOS_TESTNET_CHAIN_ID]: {
    explorer: EVMOS_TESTNET_EXPLORER_URL,
    label: 'EVMOS Testnet',
    nativeCurrency: { name: 'EVMOS', symbol: 'EVMOS', decimals: 18 },
    rpcUrls: ['https://evmos-testnet.lava.build'],
  },
}
