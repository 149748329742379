import { sample } from 'effector'
//import { LiquidityMiningListElem } from 'gql'
import { $session } from 'models/sessions'
import {
  $approveLTSent,
  $claimLTSent,
  $lmInfo,
  $stakeLTSent,
  $unstakeLTSent,
  addLMFx,
  approveLMFx,
  fetchLMList,
  fetchLMListFx,
  getRewardLMFx,
  removeLMFx,
} from '.'

sample({
  clock: fetchLMList,
  source: $session,
  target: fetchLMListFx,
})

// Если данные пришли, сохраняем
sample({
  clock: fetchLMListFx.doneData,
  fn: (data) => data?.getLiquidityMiningList?.list as any[],
  target: $lmInfo,
})

sample({
  clock: approveLMFx,
  fn: () => true,
  target: $approveLTSent,
})

sample({
  clock: [approveLMFx.fail, approveLMFx.doneData],
  fn: () => false,
  target: $approveLTSent,
})

sample({
  clock: addLMFx,
  fn: () => true,
  target: $stakeLTSent,
})

sample({
  clock: [addLMFx.doneData, addLMFx.fail],
  fn: () => false,
  target: $stakeLTSent,
})

sample({
  clock: removeLMFx,
  fn: () => true,
  target: $unstakeLTSent,
})

sample({
  clock: [removeLMFx.doneData, removeLMFx.fail],
  fn: () => false,
  target: $unstakeLTSent,
})

sample({
  clock: getRewardLMFx,
  fn: () => true,
  target: $claimLTSent,
})

sample({
  clock: [getRewardLMFx.doneData, getRewardLMFx.fail],
  fn: () => false,
  target: $claimLTSent,
})

// Если сессия пользователя изменилась, грузим для неё актуальные данные
sample({
  clock: $session,
  target: fetchLMList,
})

// При старте приложения пробуем загрузить данные
fetchLMList()
