import classNames from 'classnames'
import { noop } from 'utils'

interface Props {
  className?: string
  wrapperClassName?: string
  bgImage?: string
  nftImageUrl?: string
  children: React.ReactNode
  containerClassName?: string
  onClick?(): void
}

export default function Block({
  children,
  bgImage = '',
  nftImageUrl = '',
  containerClassName = '',
  wrapperClassName = '',
  onClick = noop,
}: Props) {
  const nftImagesTranslate =
    'translate-x-[25%] mm:translate-x-[15%] ml:translate-x-0 dxs:translate-x-[12%] dl:translate-x-[5%]'

  return (
    <div
      className={classNames(
        wrapperClassName,
        'blockShadow relative rounded-block'
      )}
      onClick={onClick}
    >
      <div className="block-gradient absolute z-0 h-[100%] w-[100%]" />
      {bgImage && (
        <div
          className={classNames(
            'absolute z-0 h-full w-full rounded-block bg-no-repeat',
            bgImage
          )}
        />
      )}
      {nftImageUrl && (
        <div className="absolute z-0 flex h-full w-full justify-end rounded-block">
          <div
            className="
              tier-card-gradient absolute left-0 top-0 z-10
              h-[100%]
              w-[60%]
              mm:w-[64%]
              ml:w-[66%]
              t:w-[75%]
              dxs:w-[60%]
            "
          />
          <img
            src={nftImageUrl}
            className={`h-[100%] scale-x-[-1] ${nftImagesTranslate}`}
            alt=""
          />
          <img
            src={nftImageUrl}
            className={`h-[100%] ${nftImagesTranslate}`}
            alt=""
          />
        </div>
      )}
      <div className={classNames('relative px-6 py-5', containerClassName)}>
        {children}
      </div>
    </div>
  )
}
