import Block from 'components/dumb/Block'
import Dropdown from 'components/dumb/Dropdown'
import Field, { FieldProps } from 'components/dumb/Field'
import { useStore } from 'effector-react'
import { $staking } from 'models/staking'
import t from 'utils/t'
import { openUrl } from 'utils'
import { ReactComponent as UniswapIcon } from 'resources/images/icons/uniswap.svg'
import { ReactComponent as GateIcon } from 'resources/images/icons/gate.svg'
import { ReactComponent as PancakeSwapIcon } from 'resources/images/icons/pancake.svg'
import { ReactComponent as MexcIcon } from 'resources/images/icons/mexc.svg'
import { ReactComponent as SwissborgIcon } from 'resources/images/icons/swissborg.svg'
import { ReactComponent as HitbtcIcon } from 'resources/images/icons/hitbtc.svg'

import { printNumberThresholds } from 'utils/numbers'
import TextLink from 'components/dumb/TextLink'
// import { STAKING_GUIDE } from 'utils/links'
import { $session } from 'models/sessions'
import StakingPriceSkelet1 from 'components/dumb/skeleton/StakingPriceSkelet1'
import StakingPriceSkelet2 from 'components/dumb/skeleton/StakingPriceSkelet2'
import { useState } from 'react'
import StakingCalculator from './StakingCalculator'

export default function SmartStakingStats() {
  const [calculatorOpen, setCalculatorOpen] = useState(false)
  const staking = useStore($staking)
  const connected = useStore($session.map(Boolean))
  const yourApyLabel = connected && staking.hasTier ? 'Your APY' : 'Tier V APY'
  const pending = staking.pending

  const yourApy = connected ? staking.yourApy : staking.yourApyNotConnected

  const onCalculatorClose = () => {
    setCalculatorOpen(false)
  }

  const yourApyClickCallback = () => {
    setCalculatorOpen(true)
  }

  const fields: FieldProps[] = [
    {
      title: t('total_staked'),
      value: staking.totalStaked,
      symbol: staking.stakingSymbol,
      sub: `$${staking.totalStakedUSD}`,
      pending,
      skeletType: 'staking',
    },
    {
      title: t('your_stake'),
      value: staking.totalStakingBalance,
      symbol: staking.stakingSymbol,
      className: 'mb-4 ml:mb-0',
      pending,
      forceZero: true,
      skeletType: 'staking',
      tooltip:
        !connected || !staking.hasTier ? (
          <div className="text-sm">
            <div className="mb-[10px]">
              <>
                <div>
                  Stake {staking.tier5MinStake} ${staking.stakingSymbol} to
                  become Tier V <br /> Tier V members can participate in IDOs
                  and get up to 16,5% APY
                </div>
              </>
            </div>
            <div className="opacity-50 ">
              Review{' '}
              <TextLink
                href="/"
                className="text-white hover:text-white/70 hover:decoration-white/70"
              >
                $OCC Staking returns by Tier
              </TextLink>{' '}
              for details
            </div>
          </div>
        ) : null,
    },
    {
      icon: 'unique-stackers',
      title: 'Unique Stakers',
      value: staking.uniqueStakers,
      symbol: '',
      pending,
      skeletType: 'stakingIcon',
    },
    {
      icon: 'mine',
      title: yourApyLabel,
      value: yourApy,
      pending,
      skeletType: 'stakingIcon',
      onClick: yourApyClickCallback,
      /*
      tooltip: (
        <div className="text-sm">
          <div className="mb-[10px]">
            These are the parameters affecting {yourApyLabel}
          </div>
          <div className="flex space-x-2">
            <div className="flex flex-col">
              <div className="whitespace-nowrap text-sm text-white/50">
                {yourApyLabel}
              </div>
              <div className="whitespace-nowrap text-lg text-white">
                {yourApy}
              </div>
            </div>
            <div className="text-sm text-white/50">=</div>
            <div className="flex flex-col">
              <div className="whitespace-nowrap text-sm text-white/50">
                ${staking.rewardSymbol} APY
              </div>
              <div className="self-center text-lg text-white">{chakraApy}</div>
            </div>
            <div className="text-sm text-white/50">+</div>
            <div className="flex flex-col">
              <div className="whitespace-nowrap text-sm text-white/50">
                ${staking.stakingSymbol} APY
              </div>
              <div className="self-center text-lg text-white">
                {staking.apy}
              </div>
            </div>
          </div>
          <div className="mt-2 opacity-50">
            Review{' '}
            <TextLink
              href={STAKING_GUIDE}
              className="text-white hover:text-white/70 hover:decoration-white/70"
            >
              Staking guide
            </TextLink>{' '}
            for more details
          </div>
        </div>
      ),
      */
    },
  ]
  const buyOccSources = [
    {
      text: 'Uniswap',
      icon: <UniswapIcon className="h-5 w-4 fill-current text-brightGray" />,
      onClick: () =>
        openUrl(
          'https://app.uniswap.org/#/swap?inputCurrency=ETH&amp;outputCurrency=0x2f109021afe75b949429fe30523ee7c0d5b27207'
        ),
    },
    {
      text: 'Gate.io',
      icon: <GateIcon className="h-4 w-4 fill-current text-brightGray" />,
      onClick: () => openUrl('https://www.gate.io/trade/OCC_USDT?ref=2886755'),
    },
    {
      text: 'PancakeSwap',
      icon: (
        <PancakeSwapIcon className="h-4 w-4 fill-current text-brightGray grayscale" />
      ),
      onClick: () =>
        openUrl(
          'https://pancakeswap.finance/swap?outputCurrency=0x2a4dffa1fa0f86ce7f0982f88aecc199fb3476bc&chainId=56'
        ),
    },
    {
      text: 'Mexc',
      icon: <MexcIcon className="h-4 w-4 fill-current text-brightGray" />,
      onClick: () =>
        openUrl('https://www.mexc.com/auth/signup?inviteCode=1Cid1'),
    },
    {
      text: 'Swissborg',
      icon: <SwissborgIcon className="h-4 w-4 fill-current text-brightGray" />,
      onClick: () => openUrl('https://join.swissborg.com/en/r/markNO3R'),
    },
    {
      text: 'HitBTC',
      icon: <HitbtcIcon className="h-4 w-4 fill-current text-brightGray" />,
      onClick: () => openUrl('https://hitbtc.com/occ-to-usdt'),
    },
  ]

  return (
    <div className="">
      <div className="gap-x mb-[1.6rem] grid grid-cols-1 gap-y-3 ml:grid-cols-2 ml:gap-y-[1.6rem]">
        {fields.map((fieldProps) => (
          <Field key={fieldProps.title} {...fieldProps} />
        ))}
      </div>
      <Block
        containerClassName="!py-4 !px-5 ml:!py-[20px] ml:!px-[40px]"
        bgImage="ml:bg-occamLogoBgML bg-occamLogoBgXS"
      >
        <div className="relative flex flex-col items-start justify-between space-y-2 ml:flex-row ml:items-center ml:space-x-4 ml:space-y-0">
          {pending ? <StakingPriceSkelet1 /> : null}
          <div className={'flex w-full items-center'}>
            <Field
              title={t('symbol_price', {
                symbol: `$${staking.stakingSymbol}`,
              })}
              value={1}
              symbol={staking.stakingSymbol}
              type="secondary"
              pending={pending}
            />
            <Field
              className="relative -left-9"
              title={null}
              value={` = ${printNumberThresholds(
                staking.priceStakingTokenUSD,
                5
              )}`}
              symbol="USD"
              type="secondary"
              pending={pending}
            />
          </div>

          <div className="relative w-full ml:w-auto">
            {pending ? (
              <StakingPriceSkelet2 />
            ) : (
              <Dropdown
                items={buyOccSources}
                baseClassName="capitalize w-full ml:w-[170px] dl:w-[135px] dxl:w-[170px]"
              >
                {t('buy')}{' '}
                <span className="font-bold">{staking.stakingSymbol}</span>
              </Dropdown>
            )}
          </div>
        </div>
      </Block>
      <StakingCalculator show={calculatorOpen} onClose={onCalculatorClose} />
    </div>
  )
}
