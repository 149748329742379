import classNames from 'classnames'
import Block from 'components/dumb/Block'
import DotLeaders from 'components/dumb/DotLeaders'
import { ChakraTierMapped } from 'models/rewards/chakraInfo'
import numeral from 'numeral'

const dumb: ChakraTierMapped = {
  apy: '0%',
  name: 'No Tier',
  minStake: '< 200',
  tokenEarningsPerYear: '0',
  index: 0,
}

export default function DashboardTiers({
  tiers,
}: {
  tiers: ChakraTierMapped[]
}) {
  const cn =
    'col-span-1 t:col-span-2 dxs:col-span-1 ds:col-span-2 dl:col-span-1'
  return (
    <div
      className=" no-scrollbar
      grid auto-cols-[260px] grid-flow-col gap-5 overflow-x-scroll pb-8 ml:grid-flow-row ml:grid-cols-2
      ml:overflow-x-visible ml:pb-0
      t:grid-cols-6
      dxs:grid-cols-4
      ds:grid-cols-6
      dl:grid-cols-4"
    >
      {[dumb, ...tiers].map((tier, idx, array) => {
        const earningPerYear = numeral(tier.tokenEarningsPerYear).format('0,00')
        const apy = numeral(tier.apy).format('0.[00]%')
        const minStake = numeral(tier.minStake).format('0,00.[00]')

        return (
          <Block
            key={tier.name}
            wrapperClassName={classNames(
              cn,
              (idx === array.length - 1 || idx === array.length - 2) &&
                't:col-span-3 ds:col-span-3'
            )}
            containerClassName="!py-4 !px-5"
          >
            <div className="mb-[14px] text-xl font-medium text-brightGray">
              {tier.name}
            </div>
            <DotLeaders
              name="OCC stake"
              value={idx === 0 ? `< ${minStake}` : minStake}
            />
            <DotLeaders
              name="CHAKRA tokens per year"
              value={earningPerYear}
              valueClassName="text-friarGray"
            />
            <DotLeaders
              name="APY"
              value={apy}
              valueClassName={classNames(
                'font-medium',
                idx === 0 ? 'text-friarGray' : 'text-malachite '
              )}
            />
          </Block>
        )
      })}
    </div>
  )
}
