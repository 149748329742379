import Button from 'components/dumb/Button'
import { ReactComponent as DotsLoader } from 'resources/images/dots-loader.svg'
import CurrencyInputPanel from 'components/dumb/CurrencyInputPanel'
import { useStore } from 'effector-react'
import isEmpty from 'lodash/isEmpty'
import { $approveLTSent, $stakeLTSent, addLMFx, approveLMFx } from 'models/lm'
import { LM } from 'models/lm/lm'
import { $session } from 'models/sessions'
import { openWallet } from 'models/wallet'
import numeral from 'numeral'
import { useState } from 'react'
import { noop } from 'utils'
import t from 'utils/t'

type Props = { pool: LM }
export default function StakeTerminal({ pool }: Props) {
  const session = useStore($session)
  const approveLTSent = useStore($approveLTSent)
  const stakeLTSent = useStore($stakeLTSent)

  const [input, setInput] = useState('')

  const handleInput = (value: string) => {
    setInput(value)
  }

  const onMax = () => {
    setInput(pool.walletBalance)
  }

  let buttonText = 'Stake'
  let buttonDisabled = false
  let handleClick = noop
  let inputDisabled = false
  let showLoader = false

  switch (pool.stakeTerminalState(input)) {
    case 'walletNotConnected':
      buttonText = t('connect_wallet')
      handleClick = openWallet
      inputDisabled = true
      break
    case 'allowanceExceeded':
      if (approveLTSent) {
        buttonText = 'Approving'
        buttonDisabled = true
        showLoader = true
      } else {
        buttonText = t('approve_staking')
        handleClick = () => approveLMFx()
        buttonDisabled = false
        showLoader = false
      }
      break
    case 'readyToStake':
      handleClick = () => addLMFx(input).then(() => setInput(''))
      if (stakeLTSent) {
        buttonText = 'Staking'
        buttonDisabled = true
        showLoader = true
      } else {
        buttonText = 'Stake'
        buttonDisabled = false
        showLoader = false
      }
      break
    case 'waitingForInput':
      buttonText = t('enter_an_amount')
      break
    case 'insufficientBalance':
      buttonText = t('insufficient_balance')
      buttonDisabled = true
      break
    case 'notReadyToStake':
    default:
      buttonDisabled = true
      break
  }

  return (
    <div>
      <CurrencyInputPanel
        label={t('you_stake')}
        leftSub={t('current_stake')}
        leftCount={
          isEmpty(session)
            ? '0'
            : numeral(pool.stakingBalanceDec.toFixed()).format('0.[00000000]')
        }
        rightSub={`${isEmpty(session) ? '' : t('wallet')}`}
        rightCount={`${
          isEmpty(session)
            ? ''
            : numeral(pool.walletBalanceDec).format('0.[00000000]')
        }`}
        symbol={pool.stakingSymbol}
        value={input}
        onUserInput={handleInput}
        onMax={onMax}
        decimals={pool.stakingTokenDecimals}
        showMaxButton={!inputDisabled && !isEmpty(session)}
        disabled={inputDisabled}
      />
      <Button
        className="w-full"
        disabled={buttonDisabled}
        onClick={handleClick}
      >
        <div className="flex items-center justify-center">
          {buttonText}
          {showLoader && (
            <div className="ml-2">
              <DotsLoader />
            </div>
          )}
        </div>
      </Button>
    </div>
  )
}
