import Timer from 'components/dumb/Timer'
import { PoolPipeline, PoolStatus, Vesting } from 'gql'
import { ReactComponent as JoinSaleArrow } from 'resources/images/icons/join-sale-arrow.svg'
import { ReactComponent as IncubatedIcon } from 'resources/images/icons/incubated.svg'
import { ReactComponent as DueDillIcon } from 'resources/images/icons/duedill.svg'
import useCountdownTimeAndTitle from 'utils/pool/useCountdownTimeAndTitle'
import t from 'utils/t'
import Block from './Block'
import Badge from './Badge'
import { getIcon, getName } from 'utils/blockchain'
import ProgressLine, { ProgressLineProps } from './ProgressLine'
import Field, { FieldProps } from './Field'

export interface HomeActivePoolCardProps {
  background: string
  blockchain: string
  dueDiligenceReport: string
  idoTokenSymbol: string
  inProgress: boolean
  incubated: boolean
  label: string
  logo: string
  name: string
  pipeline: PoolPipeline
  poolStatus: PoolStatus
  subtitle: string
  tags: string[]
  id: string
  vesting?: Vesting
  isPrivate?: boolean
  progressLine: ProgressLineProps
  fields: FieldProps[]
}

export default function HomeActivePoolCard({
  background,
  blockchain,
  dueDiligenceReport,
  idoTokenSymbol,
  inProgress,
  incubated,
  label,
  logo,
  name,
  pipeline,
  poolStatus,
  subtitle,
  vesting,
  id,
  onClick,
  isPrivate,
  progressLine,
  fields,
}: HomeActivePoolCardProps & {
  onClick: (id: string, blockchain: string) => void
}) {
  const { countdownTime, countdownTitle } = useCountdownTimeAndTitle({
    vesting,
    pipeline,
    poolStatus,
    isPrivate,
  })
  const _fields = fields.filter((f) =>
    f.name ? f.name !== 'token_price' : true
  )

  return (
    <Block
      containerClassName="!py-0 !px-0 overflow-hidden rounded-[10px]"
      wrapperClassName="group relative z-20"
      onClick={() => onClick(id, blockchain)}
    >
      <div className="relative z-30 cursor-pointer">
        <div className="grid grid-cols-1 grid-rows-[1fr,2fr] ml:auto-rows-fr t:grid-rows-1 dxs:grid-cols-2">
          <div className="relative flex flex-col">
            <div
              className="bg-105 bg-cover-repeat absolute z-0 h-full w-full bg-cover bg-center"
              style={{ backgroundImage: `url(${background})` }}
            ></div>
            <div className="relative z-10 flex grow flex-col justify-between p-5">
              <div className="flex items-center space-x-3">
                <Badge>
                  <div className="flex h-full items-center space-x-2">
                    <div className="relative top-0 h-[8px] w-[8px]">
                      <div className="absolute h-full w-full animate-ping-slow rounded-full bg-red"></div>
                      <div className="relative h-[8px] w-[8px] rounded-full bg-transparent"></div>
                    </div>
                    <span className="text-[14px] font-medium uppercase text-brightGray">
                      {t('live')}
                    </span>
                  </div>
                </Badge>
                <Badge>
                  <div className="flex h-full items-center space-x-2">
                    <div className="h-4 w-4">{getIcon(blockchain)}</div>
                    <span>{getName(blockchain)}</span>
                  </div>
                </Badge>
              </div>
              <div className="flex">
                <div className="flex items-start space-x-3">
                  <Badge>
                    <div>{label}</div>
                  </Badge>
                  {incubated && (
                    <Badge className="px-3">
                      <IncubatedIcon />
                    </Badge>
                  )}
                  {dueDiligenceReport && (
                    <Badge className="px-3">
                      <DueDillIcon />
                    </Badge>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="p-[20px] pb-[20px] dxs:h-[410px]">
            <div className="flex h-full flex-col justify-between">
              <div>
                <div className="relative flex items-center space-x-[16px]">
                  <div className="relative row-span-2 h-[70px] w-[70px] rounded-[6px] border border-geyser/40 bg-porcelain p-1">
                    <div
                      className="absolute left-1/2 top-1/2 h-[60px] w-[60px] -translate-x-1/2 -translate-y-1/2 bg-cover bg-no-repeat"
                      style={{ backgroundImage: `url(${logo})` }}
                    />
                  </div>
                  <div>
                    <div className="self-center text-3xl leading-9 text-brightGray ml:self-start">
                      {name}
                    </div>
                    <div className="text-sm font-medium text-primary">
                      {idoTokenSymbol}
                    </div>
                  </div>
                </div>

                <p className="mb-6 mt-4 truncate font-manrope text-lg leading-[130%] text-boulder">
                  {subtitle}
                </p>
                <div className="">
                  <ProgressLine {...progressLine} appearance="card" />
                  <div className="mt-8 grid grid-cols-1 gap-y-4 mm:grid-cols-2 ml:grid-cols-3">
                    {_fields.map((f) => (
                      <Field
                        key={f.title}
                        {...f}
                        type="card"
                        icon={null}
                        sub={null}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <Timer
                  endTime={countdownTime}
                  text={countdownTitle}
                  containerClassName="bg-geyser/40 !items-center rounded-[5px] p-3"
                  textClassName="!leading-none"
                  countdownClassName="font-neue !text-[22px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {inProgress && (
        <div className="relative z-20 m-auto w-full cursor-pointer border-t border-geyser/60 bg-primary/11">
          <div className="peer relative z-10 flex items-center justify-center py-[0.8rem]">
            <span className="flex items-center text-[0.8rem] uppercase leading-[140%] text-primary">
              {t('join_sale_button')}
              <JoinSaleArrow className="ml-2 h-[0.7rem] w-[0.7rem] fill-current text-primary" />
            </span>
          </div>
        </div>
      )}
    </Block>
  )
}
