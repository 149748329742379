import TxToast from 'components/smart/SmartToasts'
import { createEffect, createEvent, createStore } from 'effector'
import toast from 'react-hot-toast'

export type TxStatus = 'submitted' | 'pending' | 'failed' | 'confirmed'

export type Tx = {
  hash: string
  status: TxStatus
}

export const closeTransaction = createEvent()

export const $showTransaction = createStore(false)
export const $tx = createStore<Tx>({ hash: '', status: 'submitted' })

export const emitTxToastFx = createEffect((tx: Tx) =>
  toast.custom((t) => <TxToast t={t} tx={tx} />, { duration: 60 * 60 * 1000 })
)
