import classNames from 'classnames'

type Props = {
  name: string
  value: string
  valueClassName?: string
}

export default function DotLeaders({
  name,
  value,
  valueClassName = 'text-primary',
}: Props) {
  return (
    <div className="mb-2 flex items-end justify-between font-light last:mb-0">
      <div className="mr-2 whitespace-nowrap text-sm text-codGray">{name}</div>
      <div className="relative bottom-[0.35rem] h-[0.1rem] w-full grow border-b border-dashed border-iron"></div>
      <div
        className={classNames('ml-2 whitespace-nowrap text-sm', valueClassName)}
      >
        {value}
      </div>
    </div>
  )
}
