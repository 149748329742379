import classNames from 'classnames'
import CampaignPageTerminal from 'components/collections/CampaignPageTerminalMaster'
import DetailsInformation from 'components/dumb/DetailsInformation'
import { FieldProps } from 'components/dumb/Field'
import ProjectPageHeading from 'components/dumb/ProjectPageHeading'
import ProjectPageSocialLinks from 'components/dumb/ProjectPageSocialLinks'
import ProjectPageTimeline, {
  Props as TimelineProps,
} from 'components/dumb/ProjectPageTimeline'
import ProjectPageVesting from 'components/dumb/ProjectPageVesting'

//import SaleProgress from 'components/dumb/SaleProgress'
import Spinner from 'components/dumb/Spinner'

import ParticipationGuide from 'components/smart/SmartProjectPageParticipationGuide'
import { useStore } from 'effector-react'
import { $session } from 'models/sessions'
import { $address, $targetTokenBalance } from 'models/wallet'
import { useEffect, useRef, useState } from 'react'
import {
  GetTelegramCampaignQuery,
  GetTelegramCampaignWithSessionQuery,
  PoolStatus,
  PoolType,
} from '../../gql'
import { graphqlSdk } from '../../gql/client'
import { PoolInfo, PrivatePool } from '../../models/pools/pools'
import { $staking } from '../../models/staking'
import { DATE_FORMAT_SHORT, formatUTC } from '../../utils/dayjs'
import t from '../../utils/t'
import ProjectPageTierMaster from '../collections/ProjectPageTierMaster'
import CampaignSaleStatistics from '../dumb/CampaignSaleStatistics'
import { SocialLinkProps } from '../dumb/SocialLink'

type Campaign = (
  | GetTelegramCampaignQuery
  | GetTelegramCampaignWithSessionQuery
)['getTelegramCampaign']

const pipelineDate = {
  whitelistingTime: new Date('2024-10-28T08:00:00Z'),
  prelaunchTime: new Date('2024-10-28T08:00:00Z'),
  startTime: new Date('2024-10-28T14:00:00Z'),
  finishTime: new Date('2024-11-02T14:00:00Z'),
} satisfies Omit<
  Record<keyof PoolInfo['pipeline'], Date>,
  '__typename' | 'registrationTime'
>

export default function CampaignDetails() {
  const now = new Date()

  const targetTokenBalance = useStore($targetTokenBalance)
  const session = useStore($session)
  const address = useStore($address)
  const staking = useStore($staking)
  const terminalRef = useRef(null)

  const [telegram, setTelegram] = useState<string | null>()
  const [campaign, setCampaign] = useState<Campaign>()
  const connected = !!session

  useEffect(() => {
    const fetchCampaign = () => {
      if (session) {
        graphqlSdk
          .GetTelegramCampaignWithSession({ session })
          .then(({ getTelegramCampaign }) => {
            const account = getTelegramCampaign.account ?? null
            setTelegram(account)
            setCampaign(getTelegramCampaign)
          })
      } else {
        graphqlSdk.GetTelegramCampaign().then(({ getTelegramCampaign }) => {
          setCampaign(getTelegramCampaign)
        })
      }
    }

    fetchCampaign()

    const interval = setInterval(fetchCampaign, 5000)
    return () => {
      clearInterval(interval)
    }
  }, [session])

  const base =
    'my-[3.35rem] grid dxs:grid-cols-2 gap-y-[1.4rem] dxs:gap-[1.4rem]'

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const pipeline = Object.fromEntries(
    Object.entries(pipelineDate).map(([k, v]) => [k, v.toISOString()])
  ) as Record<keyof typeof pipelineDate, string>

  const timeline = [
    {
      title: `${t('registration')} end`,
      date: `${formatUTC(pipeline.whitelistingTime, DATE_FORMAT_SHORT)}`,
      isActive: now < pipelineDate.prelaunchTime,
      isLast: false,
    },
    {
      title: t('pre_launch'),
      date: `${formatUTC(
        pipeline.prelaunchTime,
        DATE_FORMAT_SHORT,
        true
      )} - ${formatUTC(pipeline.startTime, DATE_FORMAT_SHORT)}`,
      isActive:
        now >= pipelineDate.prelaunchTime && now < pipelineDate.startTime,
      isLast: false,
    },
    {
      title: t('sale'),
      date: `${formatUTC(
        pipeline.startTime,
        DATE_FORMAT_SHORT,
        true
      )} - ${formatUTC(pipeline.finishTime, DATE_FORMAT_SHORT)}`,
      isActive: now >= pipelineDate.startTime && now < pipelineDate.finishTime,
      isLast: false,
    },
    {
      title: t('distribution'),
      date: 'TBA',
      isActive: now >= pipelineDate.finishTime,
      isLast: true,
    },
  ] satisfies TimelineProps['items']

  const status = poolStatus(now, pipelineDate)

  const isRegistered = !!telegram
  const pool = new PrivatePool({
    account: {
      account: address,
      amountPaid: '0',
      idoTokenBalance: '0',
      refundAmount: '0',
      targetTokenBalance: targetTokenBalance.toString(),
      userToReserve: '0',
      withdrawnAmount: '0',
      isRegistered: isRegistered,
      isWhitelisted: isRegistered,
      wave: isRegistered ? 0 : null,
      waveStartTime: pipeline.startTime,
    },
    pipeline: pipeline,
    progress: {
      totalTarget: '200490',
      softCap: '5000',
      softCapPercent: '0.025',
      tokensSold: '0',
      tokensToSell: '200490',
    },
    address: '',
    blockchain: '',
    currentTime: '',
    id: '',
    whitelistAddress: '',
    poolStatus: status,
    poolType: PoolType.FixedPricePool,
    minOrderSizeTargetToken: '0',
    meta: {
      subtitle: 'Unlocking the era of personalized digital experiences.',
      brandLogo:
        'https://occam-razer.s3.eu-central-1.amazonaws.com/idoDescriptions/BluwhaleLogo.png',
      description:
        'Bluwhale is transforming the digital landscape by harnessing the power of decentralized AI. This innovative protocol enables users to take control of their personal data, turning it into personalized knowledge graphs that enhance every interaction. By facilitating seamless collaboration among decentralized applications (dApps), Bluwhale empowers users to enjoy tailored experiences while actively participating in a thriving ecosystem. Investing in Bluwhale tokens means joining a movement that prioritizes user sovereignty and unlocks unparalleled opportunities in the web3 space. Don’t miss the chance to be part of this groundbreaking journey!',
      isKYCRequired: true,
      name: 'Bluwhale',
      private: true,
      tags: ['AI', 'Web3'],
      visibility: true,
      coolOffExplainedLink: '',
      tiersExplainedLink: '',
      participationExplainedLink: '',
      secondaryMarkets: [],
      team: [],
    },
    idoToken: {
      address: '',
      blockchain: '',
      decimals: 0,
      id: '',
      name: '',
      symbol: '',
      totalSupply: '',
    },
    targetToken: {
      address: '',
      blockchain: '',
      decimals: 0,
      id: '',
      name: '',
      totalSupply: '',
      symbol: 'USD',
    },
    tiers: [],
    statistics: {
      participantsCount: 0,
      registeredCount: campaign?.registeredCount ?? 0,
    },
  })

  const links: SocialLinkProps[] = [
    {
      url: 'https://www.bluwhale.com/',
    },
    {
      icon: 'whitepaper',
      url: 'https://cdn.prod.website-files.com/63eeffaede17fe02b68b432c/664caca3de6c800fefb685b7_Bluwhale%20Whitepaper%20V2pdf.pdf',
    },
    {
      icon: 'telegram',
      url: 'https://t.me/bluwhaleofficial',
    },
  ]

  const fields: FieldProps[] = [
    {
      title: 'Nodes To Sell',
      value: 410,
      icon: 'token-to-sell',
    },
    {
      title: 'Users Registered',
      icon: 'user-registred',
      value: pool.original.statistics.registeredCount,
      symbol: 'USERS',
    },
    {
      title: 'Minimal Node Price',
      value: 489,
      symbol: 'USD',
      icon: 'dollar',
    },
    {
      title: 'Initial Market Cap',
      icon: 'dollar',
      value: '0',
    },
  ]

  if (isRegistered) {
    pool.original.poolStatus = PoolStatus.Registration
  }
  const tierProps = pool.getTierProps(connected, staking)
  pool.original.poolStatus = status

  return (
    <div className="relative min-h-[80vh]">
      {!campaign ? (
        <Spinner className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
      ) : (
        <>
          <ProjectPageHeading {...pool.getHeadingProps()} fields={fields} />
          <div className={classNames(base)}>
            <CampaignSaleStatistics {...pool.getSaleStatisticsProps()} />
            {!pool.finished || pool.failed ? (
              <ProjectPageTierMaster {...tierProps} />
            ) : null}
            <div className="col-span-2 ml:col-span-1" ref={terminalRef}>
              <CampaignPageTerminal
                {...pool.getTerminalProps({
                  address,
                  connected,
                  targetTokenBalance,
                })}
              />
            </div>
          </div>
          <ProjectPageTimeline items={timeline} />
          <ParticipationGuide
            terminalRef={terminalRef}
            {...pool.getParticipationGuideProps(connected)}
          />
          <ProjectPageSocialLinks links={links} />
          {!pool.failed ? (
            <ProjectPageVesting
              terminalRef={terminalRef}
              {...pool.getVesting()}
            />
          ) : null}
          {pool.original.meta.markdown && (
            <DetailsInformation markdown={pool.original.meta.markdown} />
          )}
        </>
      )}
    </div>
  )
}

const poolStatus = (
  now: Date,
  pipeline: {
    finishTime: Date
    startTime: Date
    prelaunchTime: Date
  }
): PoolStatus => {
  if (now >= pipeline.finishTime) {
    return PoolStatus.SuccessfullyFinished
  }

  if (now >= pipeline.startTime) {
    return PoolStatus.InProgress
  }

  if (now >= pipeline.prelaunchTime) {
    return PoolStatus.Prelaunch
  }

  return PoolStatus.Registration
}
