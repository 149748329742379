import classNames from 'classnames'
import { ReactNode } from 'react'
import { createPortal } from 'react-dom'

interface Props {
  children: ReactNode
  onClose(): void
  show: boolean
  className?: string
}

export default function Modal({
  children,
  onClose,
  show,
  className = '',
}: Props) {
  if (!portal || !show) {
    return null
  }
  const cn = classNames('w-[25rem]', className)

  return createPortal(
    <>
      <div
        className={classNames(
          'fixed left-1/2 top-1/2 z-[60] box-border h-auto w-[25rem] max-w-full -translate-x-1/2 -translate-y-1/2 transform rounded-[10px] bg-porcelain2 p-5',
          cn
        )}
      >
        {children}
      </div>
      <div
        className="fixed z-50 h-screen w-screen bg-black bg-opacity-70"
        onClick={onClose}
      />
    </>,
    portal
  )
}

const portal = document.getElementById('modal')
